import cn from 'classnames'
import qs from 'qs'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDisclosure } from '@nextui-org/react'
import MainButton from '../common/util/input/MainButton'
import get from '../helpers/Api'
import {
    getAttributeNames,
    getDefaultAttrNames,
    setQueryStringWithoutPageReloadPush,
} from '../helpers/FormatLinks'
import { ScrollContext } from '../page/Page'
import { SearchResults } from './SearchResults'
import TopFilters from './topfilters/TopFilters'
import DrawerLeft from './DrawerLeft'
import DrawerModal from './DrawerModal'
import { useSharedState } from '../waxplorer/Waxplorer'

const SearchComponent = (props) => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure()

    const searchSettings = props['searchSettings']
        ? props['searchSettings']
        : { minimal: false }

    const hideFilters = props['hideFilters']

    const [authorSchemas, setAuthorSchemas] = useState(null)
    const [pfpAttributes, setPfpAttributes] = useState(null)

    let values = qs.parse(
        window.location.search.substring(1, window.location.search.length),
    )

    const [attrNames, setAttributeNames] = useState(getDefaultAttrNames())

    const tradeSearch = props['trade']
    const page = props['page']

    const { t } = useTranslation('common')

    const [show, setShow] = useState(true)

    const { scrollManager, setScrollManager } = useContext(ScrollContext)
    const [searchResults, setSearchResults] = useState([])

    const [searchDate, setSearchDate] = useState(new Date())
    const [cleared, setCleared] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [offset, setOffset] = useState(0)

    const tab = values['tab']
    const [state, dispatch] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const getValue = (key, def) => {
        if (searchSettings[key] && searchSettings[key]['fixed'])
            return searchSettings[key]['value']
        if (values[key]) return unescape(values[key])
        if (searchSettings[key]) return searchSettings[key]['value']
        return def
    }

    const getOrder = (search, def) => {
        let order = def

        if (
            [
                'assets',
                'duplicates',
                'highest_duplicates',
                'packs',
                'inventory',
            ].includes(search)
        ) {
            order = 'date_desc'
        } else if (search === 'pfps') {
            order = 'rarity_score_desc'
        } else if (search === 'trades') {
            order = 'date_desc'
        } else if (search === 'templates') {
            order = 'template_id_asc'
        } else if (
            ['missing', 'cheapest', 'cheapest_missing'].includes(search)
        ) {
            order = 'offer_asc'
        } else if (
            process.env.NEXT_PUBLIC_DB_ASSETS === 'TRUE'
                ? 'listings' === search
                : 'sales' === search
        ) {
            order = 'date_desc'
        } else if ('auctions' === search) {
            order = 'end_asc'
        } else if (search === 'bundles') {
            order = 'date_desc'
        } else if (search === 'bulk_multi_sell') {
            order = 'owned_desc'
        } else if (
            [
                'lowest_mints',
                'highest_mints',
                'bulk_transfer_lowest_mints',
            ].includes(search)
        ) {
            order = 'mint_asc'
        } else if (
            [
                'bulk_transfer',
                'bulk_swap_atomic',
                'bulk_swap_simple',
                'bulk_distribute',
                'bulk_cancel',
                'bulk_transfer_duplicates',
                'bulk_stake',
                'bulk_unstake',
                'bulk_sell',
                'bulk_burn',
                'bulk_sell_duplicates',
                'bulk_sell_dupes',
                'bulk_bundle',
                'bulk_bundle_lowest_mints',
                'bulk_bundle_highest_mints',
                'bulk_bundle_highest_duplicates',
                'bulk_sell_highest_duplicates',
            ].includes(search)
        ) {
            order = 'date_desc'
        } else if (search === 'bulk_buy') {
            order = 'offer_asc'
        }

        return order
    }

    const getAuthor = () => {
        return getValue('collection', null)
            ? getValue('collection', null)
            : props['collection'] && props['collection'] !== '*'
            ? props['collection']
            : '*'
    }

    const [searchVars, setSearchVars] = useState({
        searchTerm: getValue(
            'term',
            getValue(
                'name',
                getValue(
                    'template_id',
                    getValue('asset_id', getValue('number', null)),
                ),
            ),
        ),
        searchOwner: getValue('owner', null),
        searchCategory: getValue(
            'category',
            searchSettings['category']
                ? searchSettings['category']['value']
                : null,
        ),
        searchSchema: !tradeSearch
            ? getValue(
                  'schema',
                  searchSettings['schema']
                      ? searchSettings['schema']['value']
                      : null,
              )
            : null,
        searchSearch: getValue(
            'search_type',
            props['bulk']
                ? 'bulk_sell'
                : process.env.NEXT_PUBLIC_DB_ASSETS === 'TRUE'
                ? 'listings'
                : 'sales',
        ),

        orderBy: getValue(
            'order_by',
            getOrder(
                getValue(
                    'search_type',
                    props['bulk']
                        ? 'bulk_sell'
                        : process.env.NEXT_PUBLIC_DB_ASSETS === 'TRUE'
                        ? 'listings'
                        : 'sales',
                ),
                'offer_asc',
            ),
        ),

        searchRarity: getValue(
            'rarity',
            searchSettings['rarity'] ? searchSettings['rarity']['value'] : null,
        ),
        searchVariant: getValue(
            'variant',
            searchSettings['variant']
                ? searchSettings['variant']['value']
                : null,
        ),
        searchContract: getValue('contract', getValue('contract', 'all')),
        searchRecentlySold: values['recent'],
        searchColor: values['color'],
        searchAttr7: values['attr7'],
        searchAttr8: values['attr8'],
        searchAttr9: values['attr9'],
        searchAttr10: values['attr10'],
        searchFavorites: values['favorites']
            ? values['favorites'] === 'true'
            : false,
        searchBacked: values['backed'] ? values['backed'] === 'true' : false,
        searchBorder: values['border'],
        searchType: values['type'],
        exactSearch: getValue(
            'exact_search',
            searchSettings['exact_search']
                ? searchSettings['exact_search']['value']
                : false,
        ),
        searchLimit: getValue(
            'limit',
            searchSettings['limit'] ? searchSettings['limit']['value'] : 40,
        ),
        searchOffset: getValue('offset', 0),
        minPrice: values['min_price'] ? values['min_price'] : 0,
        maxPrice: values['max_price'] ? values['max_price'] : 0,
        minAverage: values['min_average'] ? values['min_average'] : 0,
        maxAverage: values['max_average'] ? values['max_average'] : 0,
        minMint: values['min_mint'] ? values['min_mint'] : 0,
        maxMint: values['max_mint'] ? values['max_mint'] : 0,
        searchAuthor: getAuthor(),
        searchAttributes: getValue('attributes', []),
        searchPFPs: getValue('pfps_only', false),
        searchUnlisted: getValue('unlisted_only', false),
        searchVerified: getValue(
            'verified',
            (getAuthor() && getAuthor() !== '*') || getValue('owner', null)
                ? 'all'
                : process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? 'all'
                : 'verified',
        ),
        includeTubes: !(
            values['include_tubes'] && values['include_tubes'] === 'false'
        ),
        searchCount: 0,
    })

    const getTerms = (search) => {
        let ownerTerm = t('search.owner')
        let searchTerm = t('search.assets')
        switch (search) {
            case 'trades':
                searchTerm = t('search.trades')
                ownerTerm = t('search.buyer')
                break
            case 'sales':
                searchTerm = t('search.sales')
                ownerTerm = t('search.seller') + '/' + t('search.market')
                break
            case 'listings':
                searchTerm = t('search.sales')
                ownerTerm = t('search.seller') + '/' + t('search.market')
                break
            case 'auctions':
                searchTerm = t('search.auctions')
                ownerTerm = t('search.seller') + '/' + t('search.market')
                break
            case 'duplicates':
                searchTerm = t('search.duplicates')
                break
            case 'highest_duplicates':
                searchTerm = t('search.highest_duplicates')
                break
            case 'missing':
                searchTerm = t('search.missing_assets')
                break
            case 'cheapest_missing':
                searchTerm = t('search.cheapest_missing_assets')
                break
            case 'bundles':
                searchTerm = t('search.bundles')
                ownerTerm = t('search.seller') + '/' + t('search.market')
                break
        }

        return [searchTerm, ownerTerm]
    }

    const [searchTerm, ownerTerm] = getTerms(searchVars.searchSearch)

    const currentSearchPath = []
    const onAdd = props['onAdd']
    const displayCollectionSelector =
        props['displayCollectionSelector'] !== undefined
            ? props['displayCollectionSelector']
            : !searchSettings ||
              !searchSettings['collection'] ||
              !searchSettings['collection']['fixed']

    const [cache, setCache] = useState(null) //useStorage(`last-search-results`)

    const [hasCache, setHasCahe] = useState(false)

    const lastSearchResult = hasCache && cache && cache.lastSearchResult

    const lastSearchPath = hasCache && cache && cache.lastSearchPath

    const [searchPathCounter, setSearchPathCounter] = useState(false)

    const targetTab = searchSettings ? searchSettings['tab'] : null
    const bulk = props['bulk']

    const initScrollManager = async (
        triggered,
        useManager = true,
        scrollUp = false,
    ) => {
        if (searchVars.searchSearch.includes('bulk_') || tradeSearch) {
            setScrollManager({
                useManager: false,
                offset: 0,
                triggered: triggered,
                scrollUp: scrollUp,
            })
        } else {
            setScrollManager({
                useManager: useManager,
                offset: 0,
                triggered: triggered,
                scrollUp: scrollUp,
            })
        }
    }

    // useEffect(() => {
    //     const asyncFunc = async () => {
    //         initScrollManager(false)
    //     }
    //     asyncFunc()
    // }, [searchVars.searchSearch])

    useEffect(() => {
        const asyncFunc = async () => {
            if (scrollManager.triggered) {
                loadMore()
            }
        }
        asyncFunc()
    }, [scrollManager.triggered])

    useEffect(() => {
        const asyncFunc = async () => {
            if (offset > 0) {
                if (isLoading && (!targetTab || !tab || targetTab === tab)) {
                    if (!bulk || userName) {
                        runSearch(
                            {
                                offset: offset,
                                owner:
                                    bulk &&
                                    userName &&
                                    searchVars.searchSearch !== 'bulk_buy'
                                        ? userName
                                        : searchVars.searchOwner,
                            },
                            false,
                        )
                    }
                }
            }
        }
        asyncFunc()
    }, [offset])

    const asyncFunc = async () => {
        if (
            searchVars.searchLimit ===
                (searchSettings['limit']
                    ? searchSettings['limit']['value']
                    : 40) &&
            searchVars.searchSearch &&
            searchVars.searchSearch.includes('bulk_')
        ) {
            dispatch({ type: 'SET_ASSETS', payload: [] })
        }
        if (!isLoading && (!targetTab || !tab || targetTab === tab)) {
            if (!bulk || userName) {
                if (offset > 0) {
                    try {
                        runSearch(
                            {
                                offset: offset,
                                owner:
                                    bulk &&
                                    userName &&
                                    searchVars.searchSearch !== 'bulk_buy'
                                        ? userName
                                        : searchVars.searchOwner,
                            },
                            false,
                        )
                    } catch (e) {
                        console.error(e)
                    }
                } else {
                    runSearch(
                        {
                            offset: offset,
                            owner:
                                bulk &&
                                userName &&
                                searchVars.searchSearch !== 'bulk_buy'
                                    ? userName
                                    : searchVars.searchOwner,
                        },
                        true,
                        true,
                    )
                }
            }
        }
    }

    useEffect(() => {
        ;(async () => asyncFunc())()
    }, [searchVars.searchCount, tab]) // Add dependencies here

    const isPackSearch =
        searchVars.searchSearch === 'ft_packs' ||
        searchVars.searchSearch === 'my_ft_packs'

    const getAuthorSchemas = async (author) => {
        if (author && author !== '*') {
            const path = isPackSearch
                ? `author-categories-packs?author=${author}`
                : `author-categories?author=${author}`

            const result = await get(path)
            setAuthorSchemas(result)
        }
    }

    const getPFPAttributes = async (author, schema) => {
        if (author && author !== '*') {
            const result = await get(
                `collection-attributes/${author}/${schema}`,
            )
            if (author === 'waxbullsclub') {
                result['broken'] = ['', 'true', 'false']
            }
            setPfpAttributes(result)
        }
    }

    useEffect(() => {
        const asyncFunc = async () => {
            if (!scrollManager || !scrollManager.useManager) {
                initScrollManager(false, true, false)
            }
        }
        asyncFunc()
    }, [isLoading])

    const parseSearchResult = async (res) => {
        setCache({
            lastSearchResult: res['result'],
            lastSearchPath: res['searchPath'],
        })
        setHasCahe(true)
        setSearchResults(res['result'])

        setIsLoading(false)

        initScrollManager(false)
    }

    const checkLastSearch = (searchPath, initial) => {
        if (lastSearchPath === searchPath && initial && tab !== 'inventory') {
            setSearchResults(lastSearchResult)
            setIsLoading(false)
        } else {
            get(searchPath).then((result) =>
                getSearchResult(result, searchPath).then(parseSearchResult),
            )
        }
    }

    const runSearch = async (newVars, initial = false, scrollUp = false) => {
        try {
            let {
                term,
                owner,
                author,
                schema,
                category,
                rarity,
                variant,
                exact,
                order,
                search,
                offer_type,
                min_price,
                max_price,
                min_mint,
                max_mint,
                color,
                border,
                type,
                attr7,
                attr8,
                attr9,
                attr10,
                contract,
                verified,
                include_tubes,
                favorites,
                backed,
                limit,
                recent,
                min_average,
                max_average,
                attributes,
                pfps_only,
                unlisted_only,
            } = getNewVars(newVars)

            if (
                author &&
                author !== '*' &&
                (!authorSchemas ||
                    !Object.keys(authorSchemas).includes('schemas') ||
                    authorSchemas['schemas'].length === 0 ||
                    authorSchemas['schemas'][0].author !== author)
            ) {
                getAuthorSchemas(author)
                getAttributeNames(author).then(setAttributeNames)
            }

            if (
                author &&
                schema &&
                process.env.NEXT_PUBLIC_TESTNET !== 'TRUE'
            ) {
                getPFPAttributes(author, schema)
            }

            if (
                [
                    'bulk_bundle',
                    'bulk_bundle_lowest_mints',
                    'bulk_bundle_highest_mints',
                    'bulk_bundle_highest_duplicates',
                ].includes(search) &&
                !author
            )
                return

            if (!author || author === '*') schema = ''

            setIsLoading(true)

            if (max_price && parseFloat(max_price) < parseFloat(min_price)) {
                max_price = min_price
            }

            let result = []

            if (
                (limit !== searchSettings['limit']['value'] &&
                    search.includes('bulk_')) ||
                offset === 0
            )
                setSearchResults(result)

            const searchPath = `search?term=${
                term
                    ? term.includes('#') ||
                      term.includes('&') ||
                      term.includes('?')
                        ? escape(term)
                        : term
                    : ''
            }&owner=${owner ? owner : ''}&author=${
                author ? author : ''
            }&schema=${schema ? schema : ''}&category=${
                category ? category : ''
            }&rarity=${rarity ? rarity : ''}&variant=${
                variant
                    ? variant.includes('#') ||
                      variant.includes('&') ||
                      variant.includes('?')
                        ? escape(variant)
                        : variant
                    : ''
            }&recent=${recent ? recent : ''}&order_by=${
                order ? order : 'asset_id'
            }&color=${color ? color : ''}&type=${type ? type : ''}&border=${
                border ? border : ''
            }&limit=${limit}&search_type=${search}&exact_search=${
                exact ? exact : false
            }&offer_type=${offer_type}${
                values['mint'] ? `&mint=${values['mint']}` : ''
            }&min_price=${min_price}&max_price=${max_price}&min_mint=${min_mint}&max_mint=${max_mint}&offset=${offset}&attr7=${
                attr7 ? attr7 : ''
            }&attr8=${attr8 ? attr8 : ''}&attr9=${attr9 ? attr9 : ''}&attr10=${
                attr10 ? attr10 : ''
            }&verified=${verified}&favorites=${favorites}&backed=${backed}&user=${
                userName ? userName : ''
            }${
                include_tubes ? '' : '&include_tubes=false'
            }&max_average=${max_average}&min_average=${min_average}&contract=${contract}&attributes=${attributes}&pfps_only=${pfps_only}&unlisted_only=${unlisted_only}`

            currentSearchPath.push(searchPath)

            checkLastSearch(searchPath, initial)

            setSearchDate(new Date())
        } catch (e) {
            throw e
        }
    }

    const selectedAssets = props['selectedAssets']

    const searchOfferType = searchSettings['searchOfferType']
        ? searchSettings['searchOfferType']
        : process.env.NEXT_PUBLIC_DB_ASSETS === 'TRUE'
        ? 'listings'
        : 'sales'

    const getSearchResult = async (result, searchPath) => {
        if (!result || result['error']) {
            return { result: [], searchPath: searchPath }
        }

        if (offset === 0) {
            return { result: result, searchPath: searchPath }
        } else {
            return {
                result: searchResults.concat(result),
                searchPath: searchPath,
            }
        }
    }

    const getClearedVars = () => {
        return {
            term: '',
            owner:
                searchSettings &&
                searchSettings['owner'] &&
                searchSettings['owner']['fixed']
                    ? searchSettings['owner']['value']
                    : '',
            author:
                searchSettings &&
                searchSettings['collection'] &&
                searchSettings['collection']['fixed']
                    ? searchSettings['collection']['value']
                    : '*',
            category: '',
            schema: '',
            rarity: '',
            variant: '',
            exact: false,
            min_price: 0,
            max_price: 0,
            min_mint: 0,
            max_mint: 0,
            color: '',
            border: '',
            type: '',
            attr7: '',
            attr8: '',
            attr9: '',
            attr10: '',
            contract: '',
            verified: true,
            recent: '',
            favorites: false,
            offset: 0,
            min_average: 0,
            max_average: 0,
        }
    }

    const getNewVars = (newVars) => {
        const keys = Object.keys(newVars)

        if (!keys.includes('term')) {
            newVars['term'] = searchVars.searchTerm
        }
        if (!keys.includes('owner')) {
            newVars['owner'] = searchVars.searchOwner
        }
        if (!keys.includes('author')) {
            newVars['author'] = searchVars.searchAuthor
        }
        if (!keys.includes('schema')) {
            newVars['schema'] = searchVars.searchSchema
        }
        if (!keys.includes('category')) {
            newVars['category'] = searchVars.searchCategory
        }
        if (!keys.includes('rarity')) {
            newVars['rarity'] = searchVars.searchRarity
        }
        if (!keys.includes('variant')) {
            newVars['variant'] = searchVars.searchVariant
        }
        if (!keys.includes('exact')) {
            newVars['exact'] = searchVars.exactSearch
        }
        if (!keys.includes('order')) {
            newVars['order'] = searchVars.orderBy
        }
        if (!keys.includes('search')) {
            newVars['search'] = searchVars.searchSearch
        }
        if (!keys.includes('offer_type')) {
            newVars['offer_type'] = searchOfferType
        }
        if (!keys.includes('min_price')) {
            newVars['min_price'] = searchVars.minPrice
        }
        if (!keys.includes('max_price')) {
            newVars['max_price'] = searchVars.maxPrice
        }
        if (!keys.includes('min_mint')) {
            newVars['min_mint'] = searchVars.minMint
        }
        if (!keys.includes('max_mint')) {
            newVars['max_mint'] = searchVars.maxMint
        }
        if (!keys.includes('color')) {
            newVars['color'] = searchVars.searchColor
        }
        if (!keys.includes('border')) {
            newVars['border'] = searchVars.searchBorder
        }
        if (!keys.includes('type')) {
            newVars['type'] = searchVars.searchType
        }
        if (!keys.includes('attr7')) {
            newVars['attr7'] = searchVars.searchAttr7
        }
        if (!keys.includes('attr8')) {
            newVars['attr8'] = searchVars.searchAttr8
        }
        if (!keys.includes('attr9')) {
            newVars['attr9'] = searchVars.searchAttr9
        }
        if (!keys.includes('attr10')) {
            newVars['attr10'] = searchVars.searchAttr10
        }
        if (!keys.includes('verified')) {
            newVars['verified'] = searchVars.searchVerified
        }
        if (!keys.includes('include_tubes')) {
            newVars['include_tubes'] = searchVars.includeTubes
        }
        if (!keys.includes('favorites')) {
            newVars['favorites'] = searchVars.searchFavorites
        }
        if (!keys.includes('backed')) {
            newVars['backed'] = searchVars.searchBacked
        }
        if (!keys.includes('limit')) {
            newVars['limit'] = searchVars.searchLimit
        }
        if (!keys.includes('offset')) {
            newVars['offset'] = offset
        }
        if (!keys.includes('recent')) {
            newVars['recent'] = searchVars.searchRecentlySold
        }
        if (!keys.includes('min_average')) {
            newVars['min_average'] = searchVars.minAverage
        }
        if (!keys.includes('max_average')) {
            newVars['max_average'] = searchVars.maxAverage
        }
        if (!keys.includes('contract')) {
            newVars['contract'] = searchVars.searchContract
        }
        if (!keys.includes('attributes')) {
            newVars['attributes'] = searchVars.searchAttributes
        }
        if (!keys.includes('pfps_only')) {
            newVars['pfps_only'] = searchVars.searchPFPs
        }
        if (!keys.includes('unlisted_only')) {
            newVars['unlisted_only'] = searchVars.searchUnlisted
        }

        return newVars
    }

    const setVars = (newVars) => {
        let {
            term,
            owner,
            author,
            schema,
            category,
            rarity,
            variant,
            exact,
            order,
            search,
            offer_type,
            min_price,
            max_price,
            min_mint,
            max_mint,
            color,
            border,
            type,
            attr7,
            attr8,
            attr9,
            attr10,
            verified,
            include_tubes,
            favorites,
            backed,
            limit,
            offset,
            contract,
            recent,
            min_average,
            max_average,
            attributes,
            pfps_only,
            unlisted_only,
        } = getNewVars(newVars)

        exact = exact === 'true' || exact === true
        if (!searchVars.searchAuthor || searchVars.searchAuthor === '*')
            schema = ''

        if (max_mint && parseInt(max_mint) < parseInt(min_mint)) {
            max_mint = min_mint
        }

        if (
            ![
                'sales',
                'listings',
                'bundles',
                'cheapest_missing',
                'missing',
                'packs',
                'ft_packs',
                'cheapest',
                'bulk_buy',
                'owned_lower_mints',
            ].includes(search)
        ) {
            offer_type = ''
            if (search !== 'trades') {
                min_price = 0
                max_price = 0
            }
        }

        const ownerSearches = [
            'missing',
            'cheapest_missing',
            'duplicates',
            'highest_duplicates',
            'favorites',
            'my_packs',
            'owned_lower_mints',
        ]

        const possibleOwnerSearches = ['staked', 'assets', 'tubed', 'inventory']

        if (ownerSearches.includes(search) && !owner) {
            if (userName) owner = userName
            else search = 'assets'
        } else if (
            ownerSearches.includes(searchVars.searchSearch) &&
            !ownerSearches.includes(search) &&
            owner &&
            !possibleOwnerSearches.includes(search)
        ) {
            owner = ''
        }

        if (order === 'diff') {
            if (search === 'assets') {
                order = 'asset_id_asc'
            }
            if (search === 'inventory') {
                order = 'asset_id_asc'
            }
            if (search === 'templates') {
                order = 'template_id_asc'
            }
            if (search === 'trades') {
                order = 'date_desc'
            }
            if (search === 'bundles') {
                order = 'offer_asc'
            }
        }

        const query = values

        delete query['name']
        delete query['number']
        delete query['asset_id']
        delete query['template_id']

        if (query['refresh']) delete query['refresh']
        if (term) {
            query['term'] = escape(term)
        } else delete query['term']
        if (
            owner &&
            tab !== 'new-trade' &&
            (!searchSettings['owner'] ||
                searchSettings['owner']['value'] !== owner)
        )
            query['owner'] = owner
        else delete query['owner']
        if (
            author &&
            (!searchSettings['collection'] ||
                searchSettings['collection']['value'] !== author)
        )
            query['collection'] = author
        else delete query['collection']
        if (schema) query['schema'] = schema
        else delete query['schema']
        if (category) query['category'] = category
        else delete query['category']
        if (rarity) query['rarity'] = rarity
        else delete query['rarity']
        if (variant) query['variant'] = variant
        else delete query['variant']
        if (
            order &&
            (!searchSettings['order_by'] ||
                searchSettings['order_by']['value'] !== order)
        )
            query['order_by'] = order
        else delete query['order_by']
        if (
            search &&
            (!searchSettings['search_type'] ||
                searchSettings['search_type']['value'] !== search)
        )
            query['search_type'] = search
        else delete query['search_type']
        if (exact) query['exact_search'] = exact
        else delete query['exact_search']
        if (offer_type && offer_type !== 'sales')
            query['offer_type'] = offer_type
        else delete query['offer_type']
        if (min_price) query['min_price'] = min_price
        else delete query['min_price']
        if (pfps_only) query['pfps_only'] = pfps_only
        else delete query['pfps_only']
        if (unlisted_only) query['unlisted_only'] = unlisted_only
        else delete query['unlisted_only']
        if (max_price)
            query['max_price'] =
                min_price && parseFloat(max_price) < parseFloat(min_price)
                    ? min_price
                    : max_price
        else delete query['max_price']
        if (min_average) query['min_average'] = min_average
        else delete query['min_average']
        if (max_average)
            query['max_average'] =
                min_average && parseFloat(max_average) < parseFloat(min_average)
                    ? min_average
                    : max_average
        else delete query['max_average']
        if (min_mint) query['min_mint'] = min_mint
        else delete query['min_mint']
        if (max_mint)
            query['max_mint'] =
                min_mint && parseInt(max_mint) < parseInt(min_mint)
                    ? min_mint
                    : max_mint
        else delete query['max_mint']
        if (tab) query['tab'] = tab
        else delete query['tab']
        if (color) query['color'] = color
        else delete query['color']
        if (border) query['border'] = border
        else delete query['border']
        if (type) query['type'] = type
        else delete query['type']
        if (attr7) query['attr7'] = attr7
        else delete query['attr7']
        if (attr8) query['attr8'] = attr8
        else delete query['attr8']
        if (attr9) query['attr9'] = attr9
        else delete query['attr9']
        if (attr10) query['attr10'] = attr10
        else delete query['attr10']
        if (verified !== 'verified') query['verified'] = verified
        else delete query['verified']
        if (favorites) query['favorites'] = 'true'
        else delete query['favorites']
        if (backed) query['backed'] = 'true'
        else delete query['backed']
        if (recent) query['recent'] = recent
        else delete query['recent']
        if (offset) query['offset'] = offset
        else delete query['offset']
        if (contract && contract !== 'all') query['contract'] = contract
        else delete query['contract']
        if (attributes) query['attributes'] = attributes
        else delete query['attributes']
        if (limit !== searchSettings['limit']['value']) query['limit'] = limit
        else delete query['limit']
        let newOrder = order
        if (search !== searchVars.searchSearch) {
            newOrder = getOrder(search, order)
            query['order_by'] = newOrder
        }
        const searchStr = qs.stringify(query)
        let searchCount = searchVars.searchCount
        if (searchStr !== searchVars.searchString) {
            searchCount = searchCount + 1
        }

        setSearchVars({
            orderBy: newOrder,
            searchOwner: owner,
            searchAuthor: author,
            searchLimit: limit,
            searchSearch: search,
            searchSchema: schema,
            searchCategory: category,
            searchRarity: rarity,
            searchVariant: variant,
            searchRecentlySold: recent,
            exactSearch: exact,
            minMint: min_mint,
            maxMint: max_mint,
            minPrice: min_price,
            maxPrice: max_price,
            minAverage: min_average,
            maxAverage: max_average,
            searchColor: color,
            searchBorder: border,
            searchTerm: term,
            searchType: type,
            searchOffset: offset,
            searchAttr7: attr7,
            searchAttr8: attr8,
            searchAttr9: attr9,
            searchAttr10: attr10,
            searchContract: contract,
            searchFavorites: favorites,
            searchBacked: backed,
            searchVerified: verified,
            includeTubes: include_tubes,
            searchAttributes: attributes,
            searchPFPs: pfps_only,
            searchUnlisted: unlisted_only,
            searchString: searchStr,
            searchCount: searchCount,
        })
        setSearchPathCounter(searchStr)
        setCleared(false)

        return query
    }

    const setVarsAndSearch = (newVars, initial) => {
        setVars(newVars, initial)
        runSearch(newVars, initial)
    }

    const setVarsAndPush = (newVars) => {
        const query = setVars(newVars)
        setQueryStringWithoutPageReloadPush(qs.stringify(query))
    }

    const clearFilters = async () => {
        setVarsAndPush(getClearedVars())

        setOffset(0)
        initScrollManager(false, true, true)

        setCleared(true)
    }

    const changeSearch = async (newVars, initial = false) => {
        if (!newVars['limit'])
            newVars['limit'] = searchSettings['limit']
                ? searchSettings['limit']['value']
                : 40
        if (tradeSearch) {
            setVarsAndSearch(newVars, initial)
        } else {
            setVarsAndPush(newVars, initial)
        }
        setOffset(0)
        initScrollManager(false, true, !tradeSearch)
    }

    const setOrder = async (order) => {
        changeSearch({
            offset: 0,
            order: order,
        })
    }

    const setTerm = async (term) => {
        changeSearch({
            offset: 0,
            term: term,
        })
    }

    const applyTerm = async (term) => {
        changeSearch({
            offset: 0,
            term: term,
        })
    }

    const refreshSearchResults = async () =>
        checkLastSearch(state.last_search_result.path, false)

    const loadMore = async () => {
        if (
            !isLoading &&
            searchResults &&
            searchResults.length > 0 &&
            searchResults.length % searchVars.searchLimit === 0
        ) {
            setIsLoading(true)
            setOffset(offset + searchVars.searchLimit)
        }
    }

    const loadMoreButton = (
        <MainButton className={'mx-auto'} onClick={loadMore}>
            {t('search.load_more')}
        </MainButton>
    )

    return (
        <div
            className={cn(
                { 'flex flex-wrap w-full mt-4': !tradeSearch && !hideFilters },
                { block: tradeSearch },
                { 'flex justify-center w-full': hideFilters },
            )}
            id={'AssetSearch'}
        >
            <div
                className={cn(
                    {
                        'w-full ': !tradeSearch && show && !hideFilters,
                    },
                    {
                        'w-full  h-full':
                            !tradeSearch && (!show || hideFilters),
                    },
                    {
                        'w-full h-auto': tradeSearch,
                    },
                )}
            >
                {!hideFilters ? (
                    <div className={'flex flex-wrap md:h-auto mx-0 mb-auto'}>
                        {searchVars.searchSearch !== 'ft_packs' && (
                            <div
                                className={cn(
                                    'relative flex flex-wrap h-auto',
                                    'w-full my-auto md:mb-auto',
                                    {
                                        'md:mt-0':
                                            searchVars.searchSearch !==
                                            'tradable',
                                    },
                                )}
                            >
                                <TopFilters
                                    disabled={
                                        isPackSearch || isLoading
                                            ? 'disabled'
                                            : ''
                                    }
                                    setOrder={setOrder}
                                    searchTerm={searchVars.searchTerm}
                                    searchSearch={searchVars.searchSearch}
                                    orderBy={searchVars.orderBy}
                                    authorSchemas={authorSchemas}
                                    searchAuthor={searchVars.searchAuthor}
                                    searchSchema={searchVars.searchSchema}
                                    setTerm={setTerm}
                                    applyTerm={applyTerm}
                                    refreshSearchResults={refreshSearchResults}
                                    cleared={cleared}
                                    tab={tab}
                                    isLoading={isLoading}
                                    searchResults={searchResults}
                                    searchOffset={offset}
                                    tradeSearch={tradeSearch}
                                    attrNames={attrNames}
                                    onOpen={onOpen}
                                >
                                    <DrawerLeft
                                        searchSettings={searchSettings}
                                        limit={40}
                                        targetTab={targetTab}
                                        page={page}
                                        searchSchema={searchVars.searchSchema}
                                        searchCategory={
                                            searchVars.searchCategory
                                        }
                                        searchVariant={searchVars.searchVariant}
                                        searchRarity={searchVars.searchRarity}
                                        searchColor={searchVars.searchColor}
                                        searchBorder={searchVars.searchBorder}
                                        searchType={searchVars.searchType}
                                        searchFavorites={
                                            searchVars.searchFavorites
                                        }
                                        searchBacked={searchVars.searchBacked}
                                        searchAttr7={searchVars.searchAttr7}
                                        searchAttr8={searchVars.searchAttr8}
                                        searchAttr9={searchVars.searchAttr9}
                                        searchAttr10={searchVars.searchAttr10}
                                        searchPFPs={searchVars.searchPFPs}
                                        searchUnlisted={
                                            searchVars.searchUnlisted
                                        }
                                        minPrice={searchVars.minPrice}
                                        maxPrice={searchVars.maxPrice}
                                        minAverage={searchVars.minAverage}
                                        maxAverage={searchVars.maxAverage}
                                        minMint={searchVars.minMint}
                                        maxMint={searchVars.maxMint}
                                        setSearchOwner={() => {}}
                                        searchOwner={searchVars.searchOwner}
                                        searchContract={
                                            searchVars.searchContract
                                        }
                                        searchTerm={searchVars.searchTerm}
                                        searchAuthor={searchVars.searchAuthor}
                                        searchSearch={searchVars.searchSearch}
                                        attributes={searchVars.searchAttributes}
                                        setVars={changeSearch}
                                        exactSearch={searchVars.exactSearch}
                                        orderBy={searchVars.orderBy}
                                        searchOfferType={searchOfferType}
                                        isLoading={isLoading}
                                        tradeSearch={tradeSearch}
                                        searchOffset={offset}
                                        currentSearchPath={currentSearchPath}
                                        searchVerified={
                                            searchVars.searchVerified
                                        }
                                        searchLimit={searchVars.searchLimit}
                                        ownerTerm={ownerTerm}
                                        setMaxPrice={() => {}}
                                        setMinPrice={() => {}}
                                        setMaxAverage={() => {}}
                                        setMinAverage={() => {}}
                                        setMinMint={() => {}}
                                        setMaxMint={() => {}}
                                        authorSchemas={authorSchemas}
                                        pfpAttributes={pfpAttributes}
                                        clearFilters={clearFilters}
                                        searchRecentlySold={
                                            searchVars.searchRecentlySold
                                        }
                                        bulk={props['bulk']}
                                        cleared={cleared}
                                        displayCollectionSelector={
                                            displayCollectionSelector
                                        }
                                        show={show}
                                        setShow={setShow}
                                        attrNames={attrNames}
                                    />

                                    <DrawerModal
                                        isOpen={isOpen}
                                        onOpenChange={onOpenChange}
                                        searchSettings={searchSettings}
                                        limit={40}
                                        tab={'sales'}
                                        page={page}
                                        searchSchema={searchVars.searchSchema}
                                        searchCategory={
                                            searchVars.searchCategory
                                        }
                                        searchVariant={searchVars.searchVariant}
                                        searchRarity={searchVars.searchRarity}
                                        searchColor={searchVars.searchColor}
                                        searchBorder={searchVars.searchBorder}
                                        searchType={searchVars.searchType}
                                        searchFavorites={
                                            searchVars.searchFavorites
                                        }
                                        searchBacked={searchVars.searchBacked}
                                        searchAttr7={searchVars.searchAttr7}
                                        searchAttr8={searchVars.searchAttr8}
                                        searchAttr9={searchVars.searchAttr9}
                                        searchAttr10={searchVars.searchAttr10}
                                        searchPFPs={searchVars.searchPFPs}
                                        searchUnlisted={
                                            searchVars.searchUnlisted
                                        }
                                        minPrice={searchVars.minPrice}
                                        maxPrice={searchVars.maxPrice}
                                        minAverage={searchVars.minAverage}
                                        maxAverage={searchVars.maxAverage}
                                        minMint={searchVars.minMint}
                                        maxMint={searchVars.maxMint}
                                        setSearchOwner={() => {}}
                                        searchOwner={searchVars.searchOwner}
                                        searchContract={
                                            searchVars.searchContract
                                        }
                                        searchTerm={searchVars.searchTerm}
                                        searchAuthor={searchVars.searchAuthor}
                                        searchSearch={searchVars.searchSearch}
                                        attributes={searchVars.searchAttributes}
                                        setVars={changeSearch}
                                        exactSearch={searchVars.exactSearch}
                                        orderBy={searchVars.orderBy}
                                        searchOfferType={searchOfferType}
                                        isLoading={isLoading}
                                        tradeSearch={tradeSearch}
                                        searchOffset={offset}
                                        currentSearchPath={currentSearchPath}
                                        searchVerified={
                                            searchVars.searchVerified
                                        }
                                        searchLimit={searchVars.searchLimit}
                                        ownerTerm={ownerTerm}
                                        setMaxPrice={() => {}}
                                        setMinPrice={() => {}}
                                        setMaxAverage={() => {}}
                                        setMinAverage={() => {}}
                                        setMinMint={() => {}}
                                        setMaxMint={() => {}}
                                        authorSchemas={authorSchemas}
                                        pfpAttributes={pfpAttributes}
                                        clearFilters={clearFilters}
                                        searchRecentlySold={
                                            searchVars.searchRecentlySold
                                        }
                                        bulk={props['bulk']}
                                        cleared={cleared}
                                        displayCollectionSelector={
                                            displayCollectionSelector
                                        }
                                        show={show}
                                        setShow={setShow}
                                        attrNames={attrNames}
                                    />
                                </TopFilters>
                            </div>
                        )}
                    </div>
                ) : (
                    ''
                )}
                <SearchResults
                    {...props}
                    onAdd={onAdd}
                    selectedAssets={selectedAssets}
                    searchDate={searchDate}
                    search={searchVars.searchSearch}
                    name="SearchResults"
                    assets={searchResults.length > 0 ? searchResults : []}
                    onSale={true}
                    page={page}
                    isLoading={isLoading}
                    type={getValue('preview_type', null)}
                    loadMoreButton={tradeSearch ? loadMoreButton : null}
                    limit={searchVars.searchLimit}
                    searchAuthor={searchVars.searchAuthor}
                    searchOwner={searchVars.searchOwner}
                    searchSearch={searchVars.searchSearch}
                    offset={offset}
                    showOwners={props['showOwners']}
                    selectable={(x) =>
                        [
                            'missing',
                            'cheapest',
                            'cheapest_missing',
                            'sales',
                            //'packs',
                        ].includes(searchVars.searchSearch)
                            ? x.offer
                            : tab === 'inventory' &&
                              searchVars.searchOwner === userName &&
                              !x.offer &&
                              !x.forSale
                    }
                />
            </div>
        </div>
    )
}

export default SearchComponent
