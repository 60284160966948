import React from 'react'

import { AssetList } from '../assetlist/AssetList'
import { BuyOfferList } from '../assetlist/BuyOfferList'
import { MarketList } from '../assetlist/MarketList'
import { BulkList } from '../bulklist/BulkList'

export const SearchResults = (props) => {
    const search = props['search']
    const tab = props['tab']

    return search.includes('bulk') ? (
        <BulkList {...props} />
    ) : tab === 'buyoffers' ? (
        <BuyOfferList {...props} />
    ) : tab === 'sales' || search === 'sales' || tab === 'listings' ? (
        <MarketList {...props} />
    ) : (
        <AssetList {...props} />
    )
}
