import cn from 'classnames'
import React from 'react'

export const Input = ({ className, disabled, ...props }) => (
    <input
        className={cn(
            'rounded-3xl px-3',
            'focus:outline-none focus-visible:ring-inset',
            'focus-visible:ring-secondary',
            'text-neutral bg-bluet',
            { 'cursor-not-allowed': disabled },
            className,
        )}
        {...props}
    />
)

export default Input
