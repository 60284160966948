import { Tooltip } from '@mui/material'
import cn from 'classnames'
import React from 'react'
import Link from '../common/util/input/Link'

export const CollectionHeader = ({
    author,
    collectionImage,
    collectionThumbnail = collectionImage,
    verified = false,
    blacklisted = false,
    displayName = author,
    href = '/collection/' + author,
    baseStyle = 'justify-start',
}) => {
    return (
        <Link href={href} inactive={blacklisted}>
            <Tooltip
                title={
                    verified && !blacklisted
                        ? 'This collection is verified'
                        : blacklisted
                        ? 'This collection is blacklisted and potentially a Scam'
                        : ''
                }
            >
                <div
                    className={cn(
                        'flex  text-black dark:!text-white cursor-pointer items-center mt-2 px-4',
                        baseStyle,
                    )}
                >
                    <p
                        className={cn(
                            'truncate text-left text-black dark:text-white',
                            {
                                'text-sm font-semibold':
                                    displayName && displayName.length > 15,
                            },
                            {
                                'text-base font-semibold':
                                    displayName && displayName.length <= 15,
                            },
                        )}
                    >
                        {displayName}
                    </p>
                    {verified && !blacklisted && (
                        <img
                            src={'/verified.svg'}
                            alt="verified"
                            width={24}
                            height={24}
                            className="w-[20px] h-[20px] ml-2"
                        />
                    )}
                    {blacklisted && (
                        <img
                            src={'/blacklisted.svg'}
                            alt="blacklisted"
                            className="w-[20px] h-[20px] ml-2"
                            width={150}
                            height={150}
                        />
                    )}
                </div>
            </Tooltip>
        </Link>
    )
}

export default CollectionHeader
