import cn from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import Link from '../common/util/input/Link'
import { MintInfo } from '../mintinfo/MintInfo'
import CollectionHeader from './CollectionHeader'

function MiniAssetPreview(props) {
    const asset =
        props['asset'] && props['asset']['asset']
            ? props['asset']['asset']
            : props['asset']

    const { t } = useTranslation('common')

    const {
        aAssetId,
        author,
        name,
        image,
        backimg,
        mint,
        standard,
        collectionThumbnail,
        verified,
        mdata,
        thumbnail,
        amountToSell,
    } = asset

    let { assetId, collectionImage } = asset

    useEffect(() => {}, [])

    if (!collectionImage) {
        collectionImage =
            config.ipfs + 'QmVVE5aLtq4EtMe4Khev5CSic3AKqX2RuNTAB79thvpqQW'
    }

    if (standard && standard === 'atomicassets') assetId = aAssetId

    let mintInfo = mint ? (
        <MintInfo mint={mint} small={true} />
    ) : amountToSell ? (
        <div>{amountToSell}x</div>
    ) : (
        ''
    )

    const assetData = mdata ? JSON.parse(mdata) : null

    let video = ''
    if (
        assetData &&
        Object.keys(assetData).includes('video') &&
        assetData['video']
    ) {
        video = assetData['video'].includes('http')
            ? assetData['video']
            : `${config.ipfs}${assetData['video']}`
    }

    if (image && image.includes('video:')) {
        video = `${config.ipfs}${image.replace('video:', '')}`
    }

    if (thumbnail && video) {
        const hashes = thumbnail.split('hash=')
        if (hashes.length > 1) {
            const hash = hashes[1]
            if (video.includes(hash)) {
                video = thumbnail
            } else {
                video = null
            }
        }
    }

    return (
        <div
            className={cn(
                'w-48 relative',
                'text-center text-xs break-words h-fit',
                'mx-0 ',
            )}
        >
            <div
                className={cn(
                    'relative w-full flex flex-wrap flex-col justify-center',
                )}
            >
                <div
                    className={cn(
                        'flex w-48 h-48 aspect-1 bg-gradient-to-b from-page over-page to-transparent  rounded-t-lg',
                    )}
                >
                    {video ? (
                        <video
                            width="80"
                            height="80"
                            className={'m-auto h-full object-contain'}
                            loop
                            autoPlay={true}
                            muted={true}
                            playsInline={true}
                            poster={
                                image && !image.includes('video:')
                                    ? image
                                    : backimg
                            }
                        >
                            <source src={video} />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <img
                            className={cn('m-auto h-full object-contain')}
                            src={image}
                        />
                    )}
                    <div className="absolute top-2 left-2 w-full">
                        {mintInfo}
                    </div>
                </div>
                <div className="mx-2 mt-2">
                    <CollectionHeader
                        author={author}
                        collectionImage={collectionImage}
                        collectionThumbnail={collectionThumbnail}
                        verified={verified}
                    />
                    <div
                        className={cn(
                            'w-full font-bold',
                            'my-2 pb-2 dark:text-white text-black line-clamp-3',
                            { 'text-xs': name && name.length >= 20 },
                            { 'text-sm': name && name.length < 20 },
                        )}
                    >
                        <Link href={'/asset/' + assetId}>
                            <p className="cursor-pointer mt-2">
                                {name ? name : assetId}
                            </p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MiniAssetPreview
