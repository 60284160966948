import { Select } from '@mui/material'
import * as React from 'react'

export const StyledSelect = ({
    onChange,
    value,
    disabled,
    label,
    children,
}) => {
    return (
        <Select
            onChange={onChange}
            value={value}
            label={label}
            disabled={disabled}
            className={'h-10 md:h-8 xl:h-10 text-base md:text-sm xl:text-base'}
        >
            {children}
        </Select>
    )
}
