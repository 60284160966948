import SearchIcon from '@mui/icons-material/Search'
import { Alert, Collapse } from '@mui/material'
import { Button, Input } from '@nextui-org/react'
import cn from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { buttonSearchCommonStyles, inputStyles } from '../../common/styles'

const RangeField = ({
    minVal,
    maxVal,
    setMin,
    setMax,
    applyRange,
    disabled,
    useFloat,
    name,
}) => {
    const { t } = useTranslation('common')

    const handleMinChange = (e) => {
        const val = e.target.value
        if (
            (useFloat && /^\d*\.?\d*$/.test(val)) ||
            (!useFloat && /^\d*$/.test(val))
        ) {
            setMin(val)
        }
    }

    const handleMaxChange = (e) => {
        const val = e.target.value
        if (
            (useFloat && /^\d*\.?\d*$/.test(val)) ||
            (!useFloat && /^\d*$/.test(val))
        ) {
            setMax(val)
        }
    }

    const isInvalidRange = () => {
        return maxVal && minVal && parseInt(maxVal) < parseInt(minVal)
    }

    return (
        <div className="mx-1 my-2">
            <div className="pb-2 pl-2 text-sm dark:text-white text-black font-semibold">
                {name}
            </div>
            <div className="flex">
                <Input
                    size="sm"
                    classNames={{ ...inputStyles }}
                    variant="faded"
                    radius="lg"
                    label={t('search.min')}
                    value={minVal ? minVal : ''}
                    onChange={handleMinChange}
                    disabled={disabled}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && !isInvalidRange()) {
                            applyRange(minVal, maxVal)
                        }
                    }}
                    error={isInvalidRange()}
                    className={'text-base md:text-sm xl:text-base'}
                />
                <span className={cn('my-auto mx-3')}>-</span>

                <Input
                    size="sm"
                    classNames={{ ...inputStyles }}
                    variant="faded"
                    label={t('search.max')}
                    radius="lg"
                    value={maxVal ? maxVal : ''}
                    onChange={handleMaxChange}
                    disabled={disabled}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && !isInvalidRange()) {
                            applyRange(minVal, maxVal)
                        }
                    }}
                    error={
                        maxVal && minVal && parseInt(maxVal) < parseInt(minVal)
                    }
                    className={'text-base md:text-sm xl:text-base'}
                />
                <div className="ml-2">
                    <Button
                        className={cn(
                            'h-[48px] w-[48px] border-2',
                            buttonSearchCommonStyles,
                        )}
                        isIconOnly
                        size="lg"
                        aria-label="Search"
                        onClick={() => applyRange(minVal, maxVal)}
                        disabled={disabled || isInvalidRange()}
                    >
                        <SearchIcon />
                    </Button>
                </div>
            </div>
            <Collapse in={isInvalidRange()}>
                <Alert severity="error">
                    Max Value cannot be smaller than Min Value!
                </Alert>
            </Collapse>
        </div>
    )
}

export default RangeField
