import { InputAdornment, Slider, TextField, Tooltip } from '@mui/material'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import BurnIndicator from '../burnindicator/BurnIndicator'
import CheckIndicator from '../checkindicator/CheckIndicator'
import { floatRegEx } from '../editor/EditorComponents'
import { formatNumber, formatUSD } from '../helpers/FormatLinks'
import BulkItemBox from './BulkItemBox'

const BulkSummaryItem = ({
    asset,
    index,
    errorAssets,
    transactions,
    searchSearch,
    changePrice,
    saveAmountToSell,
    soldItems,
    sellPrices,
}) => {
    const {
        summaryId,
        offer,
        author,
        rarity,
        average,
        usd_average,
        backimg,
        lowest,
        usd_lowest,
        image,
        last_sold,
        usd_last_sold,
        num_sales,
        verified,
        mdata,
        assets,
        collectionImage,
        collectionThumbnail,
        thumbnail,
        numOwned,
    } = asset

    const localId = summaryId

    const [localAmountToSell, setLocalAmountToSell] = useState(numOwned)

    const price = sellPrices ? sellPrices.price : ''

    const [localSellPrice, setLocalSellPrice] = useState(
        sellPrices ? sellPrices.price : '',
    )

    useEffect(() => {
        setLocalSellPrice(price)
    }, [price])

    const changeLocalPrice = (e) => {
        setLocalSellPrice(e.target.value)
        changePrice(e, localAmountToSell)
    }

    const mints = assets
        .filter(
            (asset) =>
                !soldItems || !soldItems.includes(asset.assetId.toString()),
        )
        .map((asset) => asset.mint)
        .sort((a, b) => a - b)
        .slice(
            numOwned - (soldItems ? soldItems.length : 0) - localAmountToSell,
            numOwned - (soldItems ? soldItems.length : 0),
        )

    const { t } = useTranslation('common')

    let video = ''
    const assetData = mdata ? JSON.parse(mdata) : null
    if (
        assetData &&
        Object.keys(assetData).includes('video') &&
        assetData['video']
    ) {
        video = assetData['video'].includes('http')
            ? assetData['video']
            : `${config.ipfs}${assetData['video']}`
    }

    if (!video && image.includes('video:')) {
        video = (
            image.includes('http') ? image : `${config.ipfs}${image}`
        ).replace('video:', '')
    }

    if (thumbnail && video) {
        const hashes = thumbnail.split('hash=')
        if (hashes.length > 1) {
            const hash = hashes[1]
            if (video.includes(hash)) {
                video = thumbnail
            } else {
                video = null
            }
        }
    }

    const transactionId = transactions[localId]

    const showInput = !transactionId

    const showNumSales = !transactionId

    const showAverage = !transactionId

    const showFloor = showAverage

    const showLastSold = showAverage

    const showOffer = ['bulk_buy', 'bulk_cancel'].includes(searchSearch)

    let numColums = 7

    const changeAmountToSell = (e) => {
        setLocalAmountToSell(e.target.value)
    }

    if (showNumSales) numColums += 1
    if (showAverage) numColums += 1
    if (showFloor) numColums += 1
    if (showLastSold) numColums += 1
    if (showOffer) numColums += 1

    return (
        <div
            className={
                'relative flex flex-wrap py-3 rounded w-full bg-zinc-300 dark:!bg-paper mb-1'
            }
        >
            <div
                className={cn(
                    'w-full',
                    { grid: !transactionId },
                    { flex: transactionId },
                    {
                        'grid-cols-3 md:grid-cols-6 lg:grid-cols-12':
                            numColums === 12,
                    },
                    {
                        'grid-cols-3 md:grid-cols-6 lg:grid-cols-11':
                            numColums === 11,
                    },
                    {
                        'grid-cols-3 md:grid-cols-5 lg:grid-cols-10':
                            numColums === 10,
                    },
                    {
                        'grid-cols-3 md:grid-cols-5 lg:grid-cols-9':
                            numColums === 9,
                    },
                    {
                        'grid-cols-3 md:grid-cols-4 lg:grid-cols-8':
                            numColums === 8,
                    },
                    {
                        'grid-cols-3 md:grid-cols-4 lg:grid-cols-7':
                            numColums === 7,
                    },
                    { 'grid-cols-3 lg:grid-cols-6': numColums === 6 },
                    { 'grid-cols-3 lg:grid-cols-5': numColums === 5 },
                )}
                key={localId}
            >
                <BulkItemBox contentHeight={'h-28'} titleHeight={'h-0'}>
                    <div className={'flex h-28 justify-start'}>
                        {video ? (
                            <video
                                className={cn('flex p-1')}
                                width="70"
                                height="70"
                                loop
                                autoPlay={true}
                                muted={true}
                                playsInline={true}
                                poster={
                                    image && !image.includes('video:')
                                        ? image
                                        : backimg
                                }
                            >
                                <source src={video} />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <div className={cn('flex p-1')}>
                                <img
                                    className={'m-auto'}
                                    src={thumbnail ? thumbnail : image}
                                />
                            </div>
                        )}
                    </div>
                </BulkItemBox>
                <BulkItemBox title={t('asset.name')}>
                    <div
                        className={cn(
                            {
                                'text-2xs md:text-1xs': asset.name.length > 50,
                            },
                            {
                                'text-1xs md:text-xs':
                                    asset.name.length > 30 &&
                                    asset.name.length < 49,
                            },
                            {
                                'text-sm md:text-base': asset.name.length < 29,
                            },
                        )}
                    >
                        {asset.name}
                    </div>
                </BulkItemBox>
                {!transactionId && (
                    <BulkItemBox
                        title={'Collection'}
                        titleHeight={'h-8'}
                        contentHeight={'h-20'}
                        contentWidth={transactionId ? 'w-auto' : 'w-full'}
                    >
                        <div>
                            <a
                                target="_blank"
                                href={
                                    '/market?searchType=sales&order_by=offer_asc&collection=' +
                                    author
                                }
                                rel="noreferrer"
                            >
                                <div className={cn('flex flex-wrap')}>
                                    {collectionImage ? (
                                        <div
                                            className={cn(
                                                'flex w-10 h-10 m-auto p-1',
                                            )}
                                        >
                                            <img
                                                className={'m-auto'}
                                                src={
                                                    collectionThumbnail
                                                        ? collectionThumbnail
                                                        : collectionImage
                                                }
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <div
                                        className={cn(
                                            'w-full flex justify-center',
                                        )}
                                    >
                                        <div className={cn('text-sm')}>
                                            {author}
                                        </div>
                                        {verified ? (
                                            <div className={cn('h-5 w-5')}>
                                                <img
                                                    className={'m-auto'}
                                                    src="/verified.svg"
                                                    alt="verified"
                                                    width={24}
                                                    height={24}
                                                />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </BulkItemBox>
                )}
                <BulkItemBox title={t('asset.mints')} breakWhat={'break-word'}>
                    {mints && mints.length > 1 && (
                        <Tooltip title={mints.sort((a, b) => a - b).join(', ')}>
                            <div>
                                #{Math.min(...mints)}-#{Math.max(...mints)}
                            </div>
                        </Tooltip>
                    )}
                    {mints && mints.length === 1 && <div>#{mints[0]}</div>}
                </BulkItemBox>
                <BulkItemBox title={'Amount to Sell'}>
                    <Slider
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        min={0}
                        max={
                            asset['numOwned'] -
                            (soldItems ? soldItems.length : 0)
                        }
                        defaultValue={
                            asset['numOwned'] -
                            (soldItems ? soldItems.length : 0)
                        }
                        onChange={changeAmountToSell}
                        onMouseUp={() =>
                            saveAmountToSell(localId, localAmountToSell)
                        }
                        onKeyUp={() =>
                            saveAmountToSell(localId, localAmountToSell)
                        }
                        onTouchEnd={saveAmountToSell}
                    />
                </BulkItemBox>
                {!transactionId && (
                    <BulkItemBox title={t('asset.rarity')}>
                        <a
                            target="_blank"
                            href={`/market?searchType=sales&order_by=offer_asc&collection=${author}&rarity=${rarity}`}
                            rel="noreferrer"
                        >
                            {rarity}
                        </a>
                    </BulkItemBox>
                )}
                {!transactionId && showNumSales ? (
                    <BulkItemBox title={t('asset.num_sales')}>
                        {num_sales ? num_sales : ''}
                    </BulkItemBox>
                ) : (
                    ''
                )}
                {!transactionId && showAverage ? (
                    <BulkItemBox title={t('asset.average_sold')}>
                        {average ? (
                            <div className={'text-sm p-1'}>
                                {formatNumber(average)} WAX /{' '}
                                <span className={'text-xs'}>
                                    {formatUSD(usd_average)}
                                </span>
                            </div>
                        ) : (
                            ''
                        )}
                    </BulkItemBox>
                ) : (
                    ''
                )}
                {!transactionId && showFloor ? (
                    <BulkItemBox title={'Floor'}>
                        {lowest ? (
                            <div className={'text-sm p-1'}>
                                {formatNumber(lowest)} WAX /{' '}
                                <span className={'text-xs'}>
                                    {formatUSD(usd_lowest)}
                                </span>
                            </div>
                        ) : (
                            ''
                        )}
                    </BulkItemBox>
                ) : (
                    ''
                )}
                {!transactionId && showLastSold ? (
                    <BulkItemBox title={t('asset.last_sold')}>
                        {last_sold && (
                            <div className={'text-sm p-1'}>
                                {formatNumber(last_sold)} WAX /{' '}
                                <span className={'text-xs'}>
                                    {formatUSD(usd_last_sold)}
                                </span>
                            </div>
                        )}
                    </BulkItemBox>
                ) : (
                    ''
                )}
                {!transactionId && showOffer ? (
                    <BulkItemBox title={t('asset.price_for_each')}>
                        {offer ? `${formatNumber(offer)} WAX` : ''}
                    </BulkItemBox>
                ) : (
                    ''
                )}
                {!transactionId && showInput ? (
                    <BulkItemBox title={t('search.price')}>
                        <TextField
                            size="small"
                            value={localSellPrice}
                            placeholder={asset.offer ? asset.offer : ''}
                            inputProps={{
                                style: {
                                    height: '1.2rem',
                                    fontSize:
                                        String(localSellPrice).length > 8
                                            ? '0.6rem'
                                            : String(localSellPrice).length > 5
                                            ? '0.7rem'
                                            : '1rem',
                                },
                            }}
                            id={'sell-' + localId}
                            onChange={changeLocalPrice}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        disableTypography
                                        sx={{
                                            fontSize: '0.7rem',
                                            marginLeft: 0.2,
                                        }}
                                    >
                                        WAX
                                    </InputAdornment>
                                ),
                                tabIndex: index + 1,
                            }}
                            error={!floatRegEx.test(localSellPrice)}
                        />
                    </BulkItemBox>
                ) : (
                    ''
                )}
                {transactionId ? (
                    <div className={'min-w-60 my-auto'}>
                        {searchSearch === 'bulk_burn' ? (
                            <BurnIndicator />
                        ) : (
                            <CheckIndicator />
                        )}
                    </div>
                ) : (
                    ''
                )}
                {transactionId ? (
                    <BulkItemBox title={'Transaction'}>
                        <div className={cn('m-auto text-center break-words')}>
                            <a
                                className={cn(
                                    'text-primary ml-auto no-underline text-xs',
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={
                                    'https://waxblock.io/transaction/' +
                                    transactionId
                                }
                            >
                                {transactionId}
                            </a>
                        </div>
                    </BulkItemBox>
                ) : (
                    ''
                )}
            </div>
            {soldItems && soldItems.length > 0 && (
                <div
                    className={cn(
                        'w-full',
                        { grid: !transactionId },
                        { flex: transactionId },
                    )}
                >
                    Listed: {soldItems.length}
                </div>
            )}
            {errorAssets && errorAssets.length > 0 && (
                <div
                    className={cn(
                        'w-full',
                        { grid: !transactionId },
                        { flex: transactionId },
                    )}
                >
                    Errors: {errorAssets.length}
                </div>
            )}
        </div>
    )
}

export default BulkSummaryItem
