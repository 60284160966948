import SearchIcon from '@mui/icons-material/Search'
import React from 'react'
import { Input } from '@nextui-org/react'

export const SearchBar = ({
    disabled,
    onChange,
    onSubmit,
    onClickSearch,
    value,
    size,
}) => {
    return (
        <Input
            type="text"
            label="Name, ID"
            size={size}
            value={value}
            fullWidth
            className="w-full max-w-[270px] min-w-[150px]"
            classNames={{
                label: 'text-zinc-400 font-semibold text-black dark:text-white',
            }}
            variant="faded"
            onChange={onChange}
            disabled={disabled}
            onSubmit={onSubmit}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    onSubmit(e)
                }
            }}
            endContent={
                <SearchIcon
                    className="cursor-pointer text-zinc-400 mb-1 md:mb-2"
                    onClick={onClickSearch}
                />
            }
        />
    )
}
