import cn from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazy-load'
import MiniAssetPreview from '../assetpreview/MiniAssetPreview'
import MainButton from '../common/util/input/MainButton'
import {
    formatNumber,
    formatNumberS,
    getSellingElement,
} from '../helpers/FormatLinks'
import LoadingIndicator from '../loadingindicator'
import { Context } from '../waxplorer/Waxplorer'
import BulkFooter from './BulkFooter'
import BulkFooterItem from './BulkFooterItem'
import BulkSummaryItem from './BulkSummaryItem'
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    Divider,
    Input,
    Select,
    SelectItem,
} from '@nextui-org/react'
import BulkItem from './BulkItem'

export const BulkList = (props) => {
    const [changeTimer, setChangeTimer] = useState(null)
    const searchSearch = props['searchSearch']

    const formatAssets = (assets) => {
        const newAssets = []

        for (const asset of assets) {
            if (asset.assets && searchSearch !== 'bulk_multi_sell') {
                newAssets.push(Object.assign({}, asset, asset.assets[0]))
            } else {
                newAssets.push(asset)
            }
        }

        return newAssets
    }

    const assets = formatAssets(props['assets'])

    const searchAuthor = props['searchAuthor']

    const mappedAssets = {}

    const mappedSummaryAssets = {}

    for (const a of assets) {
        const asset = a.assets ? a.assets[0] : a
        asset.orderId = a.orderId
        asset.price = a.price
        asset.currency = a.currency
        asset.offer = a.offer
        asset.usd_offer = a.usd_offer
        asset.market = a.market
        asset.summaryId = a.summaryId
        if (asset.standard) {
            if (asset.standard === 'atomicassets') {
                mappedAssets['' + asset['aAssetId']] = asset
                mappedAssets['' + asset['aAssetId']]['collectionThumbnail'] =
                    a['collectionThumbnail']
                mappedAssets['' + asset['aAssetId']]['collectionImage'] =
                    a['collectionImage']
            } else {
                mappedAssets['' + asset['assetId']] = asset
                mappedAssets['' + asset['assetId']]['collectionThumbnail'] =
                    a['collectionThumbnail']
                mappedAssets['' + asset['assetId']]['collectionImage'] =
                    a['collectionImage']
            }
        }
    }

    const initSellPrices = (assets) => {
        const sellPrices = {}
        assets
            .filter((asset) =>
                asset.standard === 'atomicassets'
                    ? asset['aAssetId']
                    : asset['assetId'],
            )
            .map((asset, index) => {
                const { aAssetId, standard, assetId, newPrice } = asset

                const localId = standard === 'atomicassets' ? aAssetId : assetId

                if (newPrice && (!soldItems || !soldItems.includes(localId))) {
                    sellPrices[localId] = {
                        price: newPrice,
                        asset: mappedAssets[localId],
                    }
                }
            })

        return sellPrices
    }

    const initBuyPrices = (assets) => {
        const buyPrices = {}

        assets
            .filter((asset) =>
                asset.standard === 'atomicassets'
                    ? asset['aAssetId']
                    : asset['assetId'],
            )
            .map((asset, index) => {
                const { aAssetId, standard, assetId, offer } = asset

                const localId = standard === 'atomicassets' ? aAssetId : assetId

                if (offer) {
                    buyPrices[localId] = {
                        price: offer,
                        asset: mappedAssets[localId],
                    }
                }
            })

        return buyPrices
    }

    const { t } = useTranslation('common')
    const [changed, setChanged] = useState(1)
    const [sellPrices, setSellPrices] = useState({})

    const [amountToSell, setAmountToSell] = useState({})
    const [sellSummaryPrices, setSellSummaryPrices] = useState({})
    const [buyPrices, setBuyPrices] = useState({})
    const [soldItems, setSoldItems] = useState([])
    const [soldSummaryItems, setSoldSummaryItems] = useState({})
    const [boughtItems, setBoughtItems] = useState([])
    const [errorAssets, setErrorAssets] = useState({})
    const [numErrors, setNumErrors] = useState(0)
    const [transferredItems, setTransferredItems] = useState([])
    const [receiver, setReceiver] = useState('')
    const [memo, setMemo] = useState('')
    const [transactions, setTransactions] = useState([])
    const searchDate = props['searchDate']
    const [lastSearch, setLastSearch] = useState('')
    const [savedDate, setSavedDate] = useState(null)
    const [bundlePrice, setBundlePrice] = useState(0)
    const isLoading = props['isLoading']
    const loadMoreButton = props['loadMoreButton']
    const limit = props['limit']
    const [distributeItems, setDistributeItems] = useState({})
    const [distributeItemsLength, setDistributeItemsLength] = useState(
        Object.keys(distributeItems).length,
    )

    const [state, dispatch] = useContext(Context)
    const transferItems = state.assets ? state.assets : {}
    const transferSummaryItems = state.assets ? state.assets : {}

    const transferItemsLength = Object.keys(transferItems).length
    const sellPricesLength = Object.keys(sellPrices).length
    const sellSummaryPricesLength = Object.keys(sellSummaryPrices).length
    const buyPricesLength = Object.keys(buyPrices).length

    const showAddedAssets =
        sellPricesLength > 0 ||
        sellSummaryPricesLength > 0 ||
        buyPricesLength > 0 ||
        distributeItemsLength > 0 ||
        transferItemsLength > 0

    const sellActions = [
        'bulk_auction',
        'bulk_edit',
        'bulk_sell',
        'bulk_multi_sell',
        'bulk_sell_dupes',
        'bulk_sell_highest_duplicates',
    ]
    const bundleActions = [
        'bulk_bundle',
        'bulk_bundle_lowest_mints',
        'bulk_bundle_highest_mints',
        'bulk_bundle_highest_duplicates',
    ]

    const dismissError = (assedId) => {
        delete errorAssets['' + assedId]
        setNumErrors(Object.keys(errorAssets).length)
        setErrorAssets(errorAssets)
    }

    const handleSold = (sold) => {
        const soldAssets = sold?.['sold']
        const errors = sold?.['errors']
        const transactionIds = sold?.['transactionIds']
        if (soldAssets) {
            for (let asset of soldAssets) {
                const id =
                    asset.asset.standard === 'atomicassets'
                        ? asset.asset.aAssetId
                        : asset.asset.assetId
                soldItems.push(id)
                delete sellPrices[id.toString()]
            }
            setSoldItems(soldItems)
            setSellPrices(sellPrices)
            setChanged(changed + 1)
        }

        if (transactionIds)
            Object.keys(transactionIds).map(
                (id) => (transactions[id] = transactionIds[id]),
            )
        setTransactions(transactions)
        setErrorAssets(errors)
        setNumErrors(errors ? Object.keys(errors).length : 0)
    }

    const handleMultiSold = (sold) => {
        const soldAssets = sold['sold']
        const errors = sold['errors']
        if (soldAssets) {
            Object.keys(soldAssets).map((assetId) => {
                const asset = soldAssets[assetId]

                if (!Object.keys(soldSummaryItems).includes(asset.summaryId)) {
                    soldSummaryItems[asset.summaryId] = []
                }

                soldSummaryItems[asset.summaryId].push({
                    assetId: assetId,
                    sold: true,
                    transactionId: asset.transactionId,
                })
            })
        }

        if (errors) {
            Object.keys(errors).map((assetId) => {
                const error = errors[assetId]

                if (!errorAssets[error.summaryId]) {
                    errorAssets[error.summaryId] = []
                }

                errorAssets[error.summaryId].push({
                    assetId: assetId,
                    error: error.message,
                })
            })
        }

        setSoldSummaryItems(soldSummaryItems)
        setSellSummaryPrices({})
        setErrorAssets(errorAssets)
        setAmountToSell({})

        dispatch({ type: 'SET_ASSETS', payload: {} })
    }

    const handleTransferred = (transferred) => {
        const errors = transferred['errors']
        const transactionIds = transferred['transactionIds']

        if (transferred['transferred']) {
            Object.keys(transferItems).map((item) => {
                if (!errors || !Object.keys(errors).includes(item)) {
                    transferredItems.push(item)
                    delete transferItems[item]
                }
            })
            setTransferredItems(transferredItems)
        }

        if (transactionIds)
            Object.keys(transactionIds).map(
                (id) => (transactions[id] = transactionIds[id]),
            )
        setTransactions(transactions)
        setErrorAssets(errors)
        setNumErrors(Object.keys(errors).length)
    }

    const handleDistributed = (transferred) => {
        const errors = transferred['errors']
        const transactionIds = transferred['transactionIds']
        if (transferred['distributed']) {
            Object.keys(transferItems).map((item) => {
                if (!errors || !Object.keys(errors).includes(item)) {
                    transferredItems.push(item)
                    delete transferItems[item]
                    delete distributeItems[item]
                }
            })
            setTransferredItems(transferredItems)
        }

        if (transactionIds)
            Object.keys(transactionIds).map(
                (id) => (transactions[id] = transactionIds[id]),
            )
        setTransactions(transactions)
        setErrorAssets(errors)
        setNumErrors(Object.keys(errors).length)
        setDistributeItems(distributeItems)
    }

    const handleCanceled = (transferred) => {
        const errors = transferred?.['errors']
        const transactionIds = transferred['transactionIds']
        if (transferred['transferred']) {
            Object.keys(transferItems).map((item) => {
                if (!errors || !Object.keys(errors).includes(item)) {
                    transferredItems.push(item)
                    delete transferItems[item]
                }
            })
            setTransferredItems(transferredItems)
        }

        if (transactionIds)
            Object.keys(transactionIds).map(
                (id) => (transactions[id] = transactionIds[id]),
            )
        setTransactions(transactions)
        setErrorAssets(errors)
        setNumErrors(Object.keys(errors).length)
    }

    const handleBought = (sold) => {
        const boughtAssets = sold?.['bought']
        const errors = sold?.['errors']
        const transactionIds = sold?.['transactionIds']
        if (boughtAssets) {
            boughtAssets.map((asset) => {
                const id =
                    asset.standard === 'atomicassets'
                        ? asset.aAssetId
                        : asset.assetId
                boughtItems.push(id)
                delete buyPrices[id]
                transferredItems.push(id)
                delete transferItems[id]
            })
            dispatch({ type: 'SET_BALANCE_CHANGED', payload: true })
            setBoughtItems(boughtItems)
            setBuyPrices(buyPrices)
            setTransferredItems(transferredItems)
        }

        if (transactionIds)
            Object.keys(transactionIds).map(
                (id) => (transactions[id] = transactionIds[id]),
            )
        setTransactions(transactions)
        setErrorAssets(errors)
        setNumErrors?.(Object.keys?.(errors).length)
    }

    const handleBurned = (transferred) => {
        const errors = transferred['errors']
        const transactionIds = transferred['transactionIds']
        if (transferred['transferred']) {
            Object.keys(transferItems).map((item) => {
                if (!errors || !Object.keys(errors).includes(item)) {
                    transferredItems.push(item)
                    delete transferItems[item]
                }
            })
            setTransferredItems(transferredItems)
        }

        if (transactionIds)
            Object.keys(transactionIds).map(
                (id) => (transactions[id] = transactionIds[id]),
            )
        setTransactions(transactions)
        setErrorAssets(errors)
        setNumErrors(Object.keys(errors).length)
    }

    const handleStaked = (transferred) => {
        if (!transferred) return

        const errors = transferred['errors']
        const transactionIds = transferred['transactionIds']
        if (transferred['transferred']) {
            Object.keys(transferItems).map((item) => {
                if (!errors || !Object.keys(errors).includes(item)) {
                    transferredItems.push(item)
                    delete transferItems[item]
                }
            })
            setTransferredItems(transferredItems)
        }

        if (transactionIds)
            Object.keys(transactionIds).map(
                (id) => (transactions[id] = transactionIds[id]),
            )
        setTransactions(transactions)
        setErrorAssets(errors)
        setNumErrors(Object.keys(errors).length)
    }

    const handleBundled = (bundled) => {
        const errors = bundled['errors']
        const transactionIds = bundled['transactionIds']
        if (bundled['bundled'] && bundled['bundled'].length > 0) {
            Object.keys(transferItems).map((item) => {
                if (!errors || !Object.keys(errors).includes(item)) {
                    transferredItems.push(item)
                    delete transferItems[item]
                }
            })
            setTransferredItems(transferredItems)
            setBundlePrice(0)
        }

        if (transactionIds)
            Object.keys(transactionIds).map(
                (id) => (transactions[id] = transactionIds[id]),
            )
        setTransactions(transactions)
        setErrorAssets(errors)
        setNumErrors(Object.keys(errors).length)
    }

    useEffect(() => {
        if (lastSearch !== searchSearch) {
            setTransactions([])
            setNumErrors(0)
            setTransferredItems([])
            setLastSearch(searchSearch)
            dispatch({ type: 'SET_ASSETS', payload: {} })
        }

        if (savedDate !== searchDate) {
            setReceiver('')
            setMemo('')
            setSellPrices(initSellPrices(assets))
            setBuyPrices(initBuyPrices(assets))
        }
    }, [changed, searchDate, numErrors])

    const sell = () => {
        if (Object.keys(sellPrices).length > 0) {
            dispatch({ type: 'SET_ASSETS', payload: sellPrices })
            dispatch({ type: 'SET_ACTION', payload: 'bulk_sell' })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (sold) => handleSold(sold),
            })
        }
    }

    const auction = () => {
        if (Object.keys(sellPrices).length > 0) {
            dispatch({ type: 'SET_ASSETS', payload: sellPrices })
            dispatch({ type: 'SET_ACTION', payload: 'bulk_auction' })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (sold) => handleSold(sold),
            })
        }
    }

    const multisell = () => {
        if (Object.keys(sellSummaryPrices).length > 0) {
            const assets = {}

            for (const summaryId of Object.keys(sellSummaryPrices)) {
                assets[summaryId] = sellSummaryPrices[summaryId]
                assets[summaryId].amount = amountToSell[summaryId]
            }

            dispatch({ type: 'SET_ASSETS', payload: assets })
            dispatch({ type: 'SET_SOLD_ASSETS', payload: soldSummaryItems })
            dispatch({ type: 'SET_ACTION', payload: 'bulk_multi_sell' })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (sold) => handleMultiSold(sold),
            })
        }
    }

    const edit = () => {
        if (Object.keys(sellPrices).length > 0) {
            dispatch({ type: 'SET_ASSETS', payload: sellPrices })
            dispatch({ type: 'SET_ACTION', payload: 'bulk_edit' })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (sold) => handleSold(sold),
            })
        }
    }

    const transfer = () => {
        if (Object.keys(transferItems).length > 0) {
            dispatch({ type: 'SET_ASSETS', payload: transferItems })
            dispatch({ type: 'SET_RECEIVER', payload: receiver })
            dispatch({ type: 'SET_MEMO', payload: memo })
            dispatch({ type: 'SET_ACTION', payload: 'bulk_transfer' })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (transferred) => handleTransferred(transferred),
            })
        }
    }

    const swap = () => {
        if (Object.keys(transferItems).length > 0) {
            dispatch({ type: 'SET_ASSETS', payload: transferItems })
            dispatch({ type: 'SET_ACTION', payload: 'bulk_swap' })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (transferred) => handleTransferred(transferred),
            })
        }
    }

    const distribute = () => {
        if (Object.keys(transferItems).length > 0) {
            dispatch({ type: 'SET_ASSETS', payload: distributeItems })
            dispatch({ type: 'SET_ACTION', payload: 'bulk_distribute' })
            dispatch({ type: 'SET_MEMO', payload: memo })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (transferred) => handleDistributed(transferred),
            })
        }
    }

    const cancel = () => {
        if (Object.keys(transferItems).length > 0) {
            dispatch({ type: 'SET_ASSETS', payload: transferItems })
            dispatch({ type: 'SET_ACTION', payload: 'bulk_cancel' })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (transferred) => handleCanceled(transferred),
            })
        }
    }

    const buy = () => {
        if (Object.keys(transferItems).length > 0) {
            dispatch({ type: 'SET_ASSETS', payload: transferItems })
            dispatch({ type: 'SET_ACTION', payload: 'bulk_buy' })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (transferred) => handleBought(transferred),
            })
        }
    }

    const burn = () => {
        if (Object.keys(transferItems).length > 0) {
            dispatch({ type: 'SET_ASSETS', payload: transferItems })
            dispatch({ type: 'SET_ACTION', payload: 'bulk_burn' })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (transferred) => handleBurned(transferred),
            })
        }
    }

    const stake = () => {
        if (Object.keys(transferItems).length > 0) {
            dispatch({ type: 'SET_ASSETS', payload: transferItems })
            dispatch({ type: 'SET_ACTION', payload: 'bulk_stake' })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (transferred) => handleStaked(transferred),
            })
        }
    }

    const unstake = () => {
        if (Object.keys(transferItems).length > 0) {
            dispatch({ type: 'SET_ASSETS', payload: transferItems })
            dispatch({ type: 'SET_ACTION', payload: 'bulk_unstake' })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (transferred) => handleStaked(transferred),
            })
        }
    }

    const sellBundle = () => {
        if (bundlePrice && Object.keys(transferItems).length > 0) {
            dispatch({ type: 'SET_SELLPRICE', payload: bundlePrice })
            dispatch({ type: 'SET_ASSETS', payload: transferItems })
            dispatch({ type: 'SET_ACTION', payload: 'bundle' })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (bundled) => handleBundled(bundled),
            })
        }
    }

    const changePrice = (e) => {
        const id = e.target.id.replace('sell-', '')
        const val = e.target.value

        if (/^\d*\.?\d*$/.test(val)) {
            if (!val) {
                delete transferItems[id]
                delete sellPrices[id]
            } else {
                transferItems[id] = mappedAssets[id]
                transferItems[id] = mappedAssets[id]
                transferItems[id].newPrice = val
                sellPrices[id] = { price: val, asset: mappedAssets[id] }
            }

            setSellPrices(sellPrices)
            dispatch({ type: 'SET_ASSETS', payload: transferItems })
        }
    }

    const changeSummaryPrice = (e, amount) => {
        const id = e.target.id.replace('sell-', '')
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) {
            if (!val) {
                delete transferSummaryItems[id]
                delete sellSummaryPrices[id]
                delete amountToSell[id]
            } else {
                transferSummaryItems[id] = mappedSummaryAssets[id]
                transferSummaryItems[id].newPrice = val
                amountToSell[id] = amount
                sellSummaryPrices[id] = {
                    price: val,
                    asset: mappedSummaryAssets[id],
                }
            }

            setEnteringDecimal(e.target.value.endsWith('.'))
            setSellSummaryPrices(sellSummaryPrices)

            dispatch({ type: 'SET_ASSETS', payload: transferSummaryItems })

            setChanged(changed + 1)
        }
    }

    const changeReceivers = (e) => {
        const id = e.target.id.replace('sell-', '')
        const val = e.target.value
        if (!val) {
            delete transferItems[id]
            delete distributeItems[id]
        } else {
            transferItems[id] = mappedAssets[id]
            distributeItems[id] = { receiver: val, asset: mappedAssets[id] }
        }

        setDistributeItemsLength(Object.keys(distributeItems).length)

        if (changeTimer) clearTimeout(changeTimer)
        setChangeTimer(
            setTimeout(() => {
                setDistributeItems(distributeItems)

                dispatch({ type: 'SET_ASSETS', payload: transferItems })

                setChanged(changed + 1)
            }, 1000),
        )
    }

    const changeBundlePrice = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) {
            if (val) setBundlePrice(val)
            else setBundlePrice(0)
        }
    }

    const addAll = () => {
        Object.keys(mappedAssets).map((assetId) => {
            if (!transactions || !Object.keys(transactions).includes(assetId))
                transferItems[assetId] = mappedAssets[assetId]
        })

        if (searchSearch === 'bulk_buy') {
            const prices = {}
            Object.keys(transferItems).map((id) => {
                prices[id] = {
                    price: mappedAssets[id].offer,
                    asset: mappedAssets[id],
                }
            })

            setBuyPrices(prices)
        }

        dispatch({ type: 'SET_ASSETS', payload: transferItems })

        setChanged(changed + 1)
    }

    const addLowMints = () => {
        Object.keys(mappedAssets).map((assetId) => {
            if (
                !transactions ||
                (!Object.keys(transactions).includes(assetId) &&
                    mappedAssets[assetId].mint <= 100)
            )
                transferItems[assetId] = mappedAssets[assetId]
        })
        if (searchSearch === 'bulk_buy') {
            const prices = {}
            Object.keys(transferItems).map((id) => {
                if (mappedAssets[id].mint <= 100) {
                    prices[id] = {
                        price: mappedAssets[id].offer,
                        asset: mappedAssets[id],
                    }
                }
            })

            setBuyPrices(prices)
        }

        dispatch({ type: 'SET_ASSETS', payload: transferItems })

        setChanged(changed + 1)
    }

    const checkItem = (id) => {
        transferItems[id] = mappedAssets[id]

        if (searchSearch === 'bulk_buy') {
            const prices = {}
            Object.keys(transferItems).map((id) => {
                prices[id] = {
                    price: mappedAssets[id].offer,
                    asset: mappedAssets[id],
                }
            })

            setBuyPrices(prices)
        }

        dispatch({ type: 'SET_ASSETS', payload: transferItems })

        setChanged(changed + 1)
    }

    const checkBundleItem = (id) => {
        const standard = mappedAssets[id].standard
        const author = mappedAssets[id].author

        let error = false
        let authorError = false

        Object.keys(transferItems).map((assetId) => {
            if (mappedAssets[assetId].standard !== standard) error = true
        })

        Object.keys(transferItems).map((assetId) => {
            if (mappedAssets[assetId].author !== author) authorError = true
        })

        if (!error && !authorError) transferItems[id] = mappedAssets[id]
        else if (error) {
            errorAssets[id] =
                'Cannot mix Atomicassets and Simpleassets in one Bundle'
            setErrorAssets(errorAssets)
            setNumErrors(Object.keys(errorAssets).length)
        } else {
            errorAssets[id] = 'Cannot mix different Collections in one Bundle'
            setErrorAssets(errorAssets)
            setNumErrors(Object.keys(errorAssets).length)
        }

        dispatch({ type: 'SET_ASSETS', payload: transferItems })

        setChanged(changed + 1)
    }

    const changeReceiver = (e) => {
        const val = e.target.value
        setReceiver(val.trim().toLowerCase())
    }

    const changeMemo = (e) => {
        const val = e.target.value
        setMemo(val)
    }

    const removeAsset = (transferItems, assetId) => {
        delete transferItems[assetId]
        if (searchSearch === 'bulk_buy') {
            delete buyPrices[assetId.toString()]

            setBuyPrices(buyPrices)
        }
        if (searchSearch === 'bulk_multi_sell') {
            delete sellSummaryPrices[assetId.toString()]
            delete amountToSell[assetId.toString()]
            for (const asset of assets) {
                if (asset.summaryId.toString() === assetId.toString())
                    delete asset['newPrice']
            }

            setSellSummaryPrices(sellSummaryPrices)
            setAmountToSell(amountToSell)
        } else if (sellActions.includes(searchSearch)) {
            delete sellPrices[assetId.toString()]
            for (const asset of assets) {
                if (asset.assetId.toString() === assetId.toString())
                    delete asset['newPrice']
            }

            setSellPrices(sellPrices)
        }

        if (distributeItems && searchSearch === 'bulk_distribute') {
            delete distributeItems[assetId.toString()]

            setDistributeItems(distributeItems)
        }

        dispatch({ type: 'SET_ASSETS', payload: transferItems })

        if (changeTimer) clearTimeout(changeTimer)

        setChanged(changed + 1)
    }

    const removeLowMints = () => {
        const prices = {}
        Object.keys(transferItems).map((assetId) => {
            if (
                !transactions ||
                (!Object.keys(transactions).includes(assetId) &&
                    mappedAssets[assetId].mint <= 100)
            ) {
                delete transferItems[assetId]
                delete sellPrices[assetId]
            }

            if (distributeItems && searchSearch === 'bulk_distribute') {
                delete distributeItems[assetId]

                setDistributeItems(distributeItems)
            }
            if (searchSearch === 'bulk_buy') {
                prices[assetId] = {
                    price: mappedAssets[assetId].offer,
                    asset: mappedAssets[assetId],
                }
            }
        })
        if (searchSearch === 'bulk_buy') {
            setBuyPrices(prices)
        }
        if (sellActions.includes(searchSearch)) {
            setSellPrices(sellPrices)
        }

        dispatch({ type: 'SET_ASSETS', payload: transferItems })

        setChanged(changed + 1)
    }

    const removeAll = () => {
        if (searchSearch === 'bulk_buy') {
            setBuyPrices({})
        }

        if (searchSearch === 'bulk_multi_sell') {
            setSellSummaryPrices({})
            setAmountToSell({})
        }

        if (sellActions.includes(searchSearch)) {
            setSellPrices({})
            for (const asset of assets) {
                delete asset['newPrice']
            }
        }
        setDistributeItems({})

        dispatch({ type: 'SET_ASSETS', payload: {} })

        setSavedDate(searchDate)
        setChanged(changed + 1)

        if (changeTimer) clearTimeout(changeTimer)
    }

    const addedAssets =
        showAddedAssets &&
        transferItems &&
        Object.keys(transferItems).length > 0 ? (
            <div
                className={cn(
                    'relative flex overflow-x-auto gap-4 w-full h-auto mb-5 p-4 rounded-xl bg-zinc-100 dark:bg-[#121212] dark:border-zinc-700 border-zinc-300 border-2',
                )}
            >
                {Object.keys(transferItems).map((assetId) => (
                    <div
                        className={cn(
                            'relative h-fit bg-zinc-50 hover:bg-zinc-100 dark:bg-zinc-900 rounded-lg hover:shadow-xl shadow-lg dark:shadow dark:hover:shadow-bluet  dark:hover:bg-zinc-800 duration-300',
                        )}
                    >
                        <div
                            className={cn(
                                'absolute top-2 right-2 w-6 h-6 z-20 cursor-pointer',
                            )}
                        >
                            <img
                                src="/mini_asset_card/minus.svg"
                                alt="X"
                                onClick={() =>
                                    removeAsset(transferItems, assetId)
                                }
                            />
                        </div>
                        <MiniAssetPreview
                            asset={transferItems[assetId]}
                            minimal={true}
                            amountToSell={amountToSell[assetId]}
                        />
                    </div>
                ))}
            </div>
        ) : (
            ''
        )

    const btnClass = cn('max-w-32  px-12 m-auto')
    const btnClass2 = cn('max-w-32 bg-red-500  px-12 m-auto')

    const btnContainerClass = cn('flex justify-end h-8 w-32')

    const sellSummaryValue = Object.keys(sellSummaryPrices)
        .map(
            (summaryId) =>
                parseFloat(sellSummaryPrices[summaryId].price) *
                parseFloat(amountToSell[summaryId]),
        )
        .reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0)
    const buyValue = Object.keys(transferItems)
        .map((asset) => parseFloat(transferItems[asset].offer))
        .reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0)
    const sellValue = Object.keys(sellPrices)
        .map((asset) => parseFloat(sellPrices[asset].price))
        .reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0)

    let footer = ''

    if (
        [
            'bulk_transfer',
            'bulk_transfer_duplicates',
            'bulk_transfer_lowest_mints',
        ].includes(searchSearch)
    )
        footer = (
            <BulkFooter addedAssets={addedAssets}>
                <BulkFooterItem>
                    {t('search.transferring_x_items', {
                        x: Object.keys(transferItems).length,
                    })}
                </BulkFooterItem>

                <div className="flex flex-wrap justify-center">
                    <BulkFooterItem>
                        <Input
                            placeholder={t('asset.to')}
                            type="text"
                            onChange={changeReceiver}
                            value={receiver}
                            variant="faded"
                        />
                    </BulkFooterItem>
                    <BulkFooterItem>
                        <Input
                            placeholder={'Memo'}
                            type="text"
                            onChange={changeMemo}
                            value={memo}
                            variant="faded"
                        />
                    </BulkFooterItem>
                    <BulkFooterItem>
                        <MainButton
                            height="h-[54px]"
                            fullWidth={true}
                            disabled={
                                Object.keys(transferItems).length === 0 ||
                                !receiver
                            }
                            className={btnClass}
                            onClick={transfer}
                        >
                            {t('asset.transfer')}
                        </MainButton>
                    </BulkFooterItem>
                </div>
            </BulkFooter>
        )
    else if ('bulk_swap_atomic' === searchSearch)
        footer = (
            <BulkFooter addedAssets={addedAssets}>
                <BulkFooterItem>
                    {t('search.swapping_x_items_atomic', {
                        x: Object.keys(transferItems).length,
                    })}
                </BulkFooterItem>
                <BulkFooterItem>
                    <MainButton
                        fullWidth={true}
                        disabled={Object.keys(transferItems).length === 0}
                        className={btnClass}
                        onClick={swap}
                    >
                        {t('profile.swap')}
                    </MainButton>
                </BulkFooterItem>
            </BulkFooter>
        )
    else if ('bulk_swap_simple' === searchSearch)
        footer = (
            <BulkFooter addedAssets={addedAssets}>
                <BulkFooterItem>
                    {t('search.swapping_x_items_simple', {
                        x: Object.keys(transferItems).length,
                    })}
                </BulkFooterItem>
                <BulkFooterItem>
                    <MainButton
                        fullWidth={true}
                        disabled={Object.keys(transferItems).length === 0}
                        className={btnClass}
                        onClick={swap}
                    >
                        {t('profile.swap')}
                    </MainButton>
                </BulkFooterItem>
            </BulkFooter>
        )
    else if ('bulk_distribute' === searchSearch)
        footer = (
            <BulkFooter addedAssets={addedAssets}>
                <BulkFooterItem>
                    {t('search.transferring_x_items', {
                        x: Object.keys(transferItems).length,
                    })}
                </BulkFooterItem>
                <BulkFooterItem>
                    <Input
                        variant="faded"
                        placeholder={'Memo'}
                        type="text"
                        onChange={changeMemo}
                        value={memo}
                    />
                </BulkFooterItem>
                <BulkFooterItem>
                    <MainButton
                        fullWidth={true}
                        disabled={Object.keys(transferItems).length === 0}
                        className={btnClass}
                        onClick={distribute}
                    >
                        {t('search.bulk_distribute')}
                    </MainButton>
                </BulkFooterItem>
            </BulkFooter>
        )
    else if ('bulk_burn' === searchSearch)
        footer = (
            <BulkFooter addedAssets={addedAssets}>
                <BulkFooterItem>
                    {t('search.burning_x_items', {
                        x: Object.keys(transferItems).length,
                    })}
                </BulkFooterItem>
                <BulkFooterItem>
                    <MainButton
                        fullWidth={true}
                        disabled={Object.keys(transferItems).length === 0}
                        className={btnClass}
                        onClick={burn}
                    >
                        {t('asset.burn')}
                    </MainButton>
                </BulkFooterItem>
            </BulkFooter>
        )
    else if ('bulk_cancel' === searchSearch)
        footer = (
            <BulkFooter addedAssets={addedAssets}>
                <BulkFooterItem>
                    {t('search.canceling_x_sales', {
                        x: Object.keys(transferItems).length,
                    })}
                </BulkFooterItem>
                <BulkFooterItem>
                    <MainButton
                        fullWidth={true}
                        disabled={Object.keys(transferItems).length === 0}
                        className={btnClass2}
                        onClick={cancel}
                    >
                        {t('asset.cancel')}
                    </MainButton>
                </BulkFooterItem>
            </BulkFooter>
        )
    else if ('bulk_buy' === searchSearch)
        footer = (
            <BulkFooter addedAssets={addedAssets}>
                <BulkFooterItem>
                    {t('search.buying_x_items_for_y', {
                        x: Object.keys(transferItems).length,
                        y: `${
                            buyValue > 100
                                ? formatNumber(buyValue)
                                : formatNumberS(buyValue)
                        } WAX`,
                    })}
                </BulkFooterItem>
                <BulkFooterItem>
                    <MainButton
                        fullWidth={true}
                        disabled={Object.keys(transferItems).length === 0}
                        className={btnClass}
                        onClick={buy}
                    >
                        {t('asset.buy')}
                    </MainButton>
                </BulkFooterItem>
            </BulkFooter>
        )
    else if (sellActions.includes(searchSearch))
        footer = (
            <BulkFooter addedAssets={addedAssets}>
                <BulkFooterItem>
                    {getSellingElement(
                        searchSearch === 'bulk_auction'
                            ? t('search.auctioning_x_items_for_y', {
                                  x: Object.keys(sellPrices).length,
                                  y: `${
                                      sellValue > 100
                                          ? formatNumber(sellValue)
                                          : formatNumberS(sellValue)
                                  } WAX`,
                              })
                            : searchSearch !== 'bulk_multi_sell'
                            ? t('search.selling_x_items_for_y', {
                                  x: Object.keys(sellPrices).length,
                                  y: `${
                                      sellValue > 100
                                          ? formatNumber(sellValue)
                                          : formatNumberS(sellValue)
                                  } WAX`,
                              })
                            : t('search.selling_x_items_for_y', {
                                  x: Object.keys(sellSummaryPrices)
                                      .map(
                                          (summaryId) =>
                                              amountToSell[summaryId],
                                      )
                                      .reduce((a, b) => a + b, 0),
                                  y: `${
                                      sellSummaryValue > 100
                                          ? formatNumber(sellSummaryValue)
                                          : formatNumberS(sellSummaryValue)
                                  } WAX`,
                              }),
                    )}
                </BulkFooterItem>
                <BulkFooterItem>
                    <div className="flex space-x-4">
                        <MainButton
                            fullWidth={true}
                            disabled={Object.keys(transferItems).length === 0}
                            className={btnClass}
                            color="primary"
                            onClick={
                                searchSearch === 'bulk_edit'
                                    ? edit
                                    : searchSearch === 'bulk_multi_sell'
                                    ? multisell
                                    : searchSearch === 'bulk_auction'
                                    ? auction
                                    : sell
                            }
                        >
                            {searchSearch === 'bulk_auction'
                                ? t('popups.auction')
                                : t('asset.sell')}
                        </MainButton>
                        <MainButton
                            fullWidth={true}
                            className={btnClass}
                            color="danger"
                            onClick={removeAll}
                        >
                            {t('search.remove_all')}
                        </MainButton>
                    </div>
                </BulkFooterItem>
            </BulkFooter>
        )
    else if (bundleActions.includes(searchSearch))
        footer = (
            <BulkFooter addedAssets={addedAssets}>
                <BulkFooterItem>
                    {t('search.selling_x_items', {
                        x: Object.keys(transferItems).length,
                    })}
                </BulkFooterItem>
                <div
                    className={
                        'flex md:justify-end justify-center flex-wrap flex-1 my-2 space-x-2'
                    }
                >
                    <Input
                        classNames={{
                            label: 'text-zinc-400 font-semibold text-black dark:text-white',
                        }}
                        label={t('asset.price')}
                        value={bundlePrice ? bundlePrice : ''}
                        type="text"
                        size="md"
                        variant="faded"
                        isInvalid={!bundlePrice}
                        className="w-[250px]"
                        fullWidth
                        onChange={changeBundlePrice}
                    />

                    <MainButton
                        height="h-[50px]"
                        fullWidth={true}
                        disabled={
                            Object.keys(transferItems).length === 0 ||
                            !bundlePrice
                        }
                        className={btnClass}
                        onClick={sellBundle}
                    >
                        {t('asset.sell')}
                    </MainButton>
                </div>
            </BulkFooter>
        )
    else if ('bulk_stake' === searchSearch)
        footer = (
            <BulkFooter addedAssets={addedAssets}>
                <BulkFooterItem>
                    {t('search.staking_x_items', {
                        x: Object.keys(transferItems).length,
                    })}
                </BulkFooterItem>
                <BulkFooterItem>
                    <MainButton
                        fullWidth={true}
                        disabled={Object.keys(transferItems).length === 0}
                        className={btnClass}
                        onClick={stake}
                    >
                        {t('asset.stake')}
                    </MainButton>
                </BulkFooterItem>
            </BulkFooter>
        )
    else if ('bulk_unstake' === searchSearch)
        footer = (
            <BulkFooter addedAssets={addedAssets}>
                <BulkFooterItem>
                    {t('search.unstaking_x_items', {
                        x: Object.keys(transferItems).length,
                    })}
                </BulkFooterItem>
                <BulkFooterItem>
                    <MainButton
                        fullWidth={true}
                        disabled={Object.keys(transferItems).length === 0}
                        className={btnClass}
                        onClick={unstake}
                    >
                        {t('asset.unstake')}
                    </MainButton>
                </BulkFooterItem>
            </BulkFooter>
        )

    const applyPrice = () => {
        assets
            .filter((asset) =>
                asset.standard === 'atomicassets'
                    ? asset['aAssetId']
                    : asset['assetId'],
            )
            .map((asset, index) => {
                const { aAssetId, lowest, attributesFloor, assetId } = asset

                const localId =
                    asset.standard === 'atomicassets' ? aAssetId : assetId

                if (lowest || attributesFloor) {
                    sellPrices[localId] = {
                        price:
                            (attributesFloor ? attributesFloor : lowest) * 0.99,
                        asset: mappedAssets[localId],
                    }
                    transferItems[localId] = mappedAssets[localId]
                }
            })

        setSellPrices(sellPrices)

        dispatch({ type: 'SET_ASSETS', payload: transferItems })

        setChanged(changed + 1)
    }

    const setNewPrice = (
        transferItems,
        localId,
        prices,
        summaryPrices,
        newPrice,
    ) => {
        if (searchSearch !== 'bulk_multi_sell') {
            transferItems[localId] = mappedAssets[localId]
            transferItems[localId].newPrice = newPrice
            prices[localId] = {
                price: newPrice,
                asset: mappedAssets[localId],
            }
        } else {
            transferSummaryItems[localId] = mappedSummaryAssets[localId]
            transferSummaryItems[localId].newPrice = newPrice
            amountToSell[localId] = amountToSell[localId]
                ? amountToSell[localId]
                : mappedSummaryAssets[localId].assets.length
            summaryPrices[localId] = {
                price: newPrice,
                asset: mappedSummaryAssets[localId],
            }
        }
    }

    const reducePrice = (by) => {
        assets
            .filter((asset) =>
                searchSearch === 'bulk_multi_sell'
                    ? (asset.assets ? asset.assets[0] : asset).summaryId
                    : (asset.assets ? asset.assets[0] : asset).standard ===
                      'atomicassets'
                    ? (asset.assets ? asset.assets[0] : asset)['aAssetId']
                    : (asset.assets ? asset.assets[0] : asset)['assetId'],
            )
            .map((asset) => {
                const {
                    aAssetId,
                    lowest,
                    attributesFloor,
                    assetId,
                    average,
                    summaryId,
                    standard,
                    name,
                    author,
                    rarity,
                    image,
                } = asset.assets ? asset.assets[0] : asset

                const floor = attributesFloor ? attributesFloor : lowest

                const localId =
                    searchSearch === 'bulk_multi_sell'
                        ? summaryId
                        : standard === 'atomicassets'
                        ? aAssetId
                        : assetId

                if (by === 'reduceone' && asset.offer && asset.offer > 1) {
                    setNewPrice(
                        transferItems,
                        localId,
                        sellPrices,
                        sellSummaryPrices,
                        asset.offer - 1,
                    )
                }
                if (by === 'equalizename' && sellPrices[localId]) {
                    for (let a of assets) {
                        const ass = a.assets ? a.assets[0] : a
                        if (
                            ass.assetId !== assetId &&
                            ass.name === name &&
                            ass.image === image &&
                            ass.author === author
                        ) {
                            const localId2 =
                                ass.standard === 'atomicassets'
                                    ? ass.aAssetId
                                    : ass.assetId
                            if (!sellPrices[localId2]) {
                                const newPrice = sellPrices[localId].price
                                sellPrices[localId2] = {
                                    price: sellPrices[localId].price,
                                    asset: mappedAssets[localId2],
                                }
                                transferItems[localId2] = mappedAssets[localId2]
                                transferItems[localId2].newPrice = newPrice
                            }
                        }
                    }
                }
                if (by === 'equalizerarity' && sellPrices[localId] && rarity) {
                    for (let a of assets) {
                        const ass = a.assets ? a.assets[0] : a
                        if (
                            ass.assetId !== assetId &&
                            ass.rarity &&
                            ass.rarity === rarity &&
                            ass.author === author
                        ) {
                            const localId2 =
                                ass.standard === 'atomicassets'
                                    ? ass.aAssetId
                                    : ass.assetId
                            if (!sellPrices[localId2]) {
                                const newPrice = sellPrices[localId].price
                                sellPrices[localId2] = {
                                    price: newPrice,
                                    asset: mappedAssets[localId2],
                                }
                                transferItems[localId2] = mappedAssets[localId2]
                                transferItems[localId2].newPrice = newPrice
                            }
                        }
                    }
                }
                if (by === '1waxfloor' && floor && floor > 1) {
                    setNewPrice(
                        transferItems,
                        localId,
                        sellPrices,
                        sellSummaryPrices,
                        floor - 1,
                    )
                }
                if (by === '1percentfloor' && floor) {
                    setNewPrice(
                        transferItems,
                        localId,
                        sellPrices,
                        sellSummaryPrices,
                        floor * 0.99,
                    )
                }
                if (by === 'floor' && floor) {
                    setNewPrice(
                        transferItems,
                        localId,
                        sellPrices,
                        sellSummaryPrices,
                        floor,
                    )
                }
                if (by === 'median' && average) {
                    setNewPrice(
                        transferItems,
                        localId,
                        sellPrices,
                        sellSummaryPrices,
                        average,
                    )
                }
            })

        if (searchSearch === 'bulk_multi_sell') {
            dispatch({ type: 'SET_ASSETS', payload: transferSummaryItems })
            setSellSummaryPrices(sellSummaryPrices)
            setAmountToSell(amountToSell)
        } else {
            dispatch({ type: 'SET_ASSETS', payload: transferItems })
            setSellPrices(sellPrices)
        }

        setChanged(changed + 1)
    }

    let dropDownOptions = []

    const onSelect = (e) => {
        switch (e.target.value) {
            case 'add':
                addAll()
                return
            case 'addlow':
                addLowMints()
                return
            case 'remove':
                removeAll()
                return
            case 'removelow':
                removeLowMints()
                return
            case 'equalizeImageName':
                reducePrice('equalizename')
                return
            case 'equalizeRarity':
                reducePrice('equalizerarity')
                return
            case 'reduceOne':
                reducePrice('reduceone')
                return
            case 'reduce':
                reducePrice('1percentfloor')
                return
            case 'floor':
                reducePrice('floor')
                return
            case 'reduce1Wax':
                reducePrice('1waxfloor')
                return
            case 'reduceMedian':
                reducePrice('median')
                return
            case 'applysame':
                applyPrice()
                return
        }
    }

    if (
        !isLoading &&
        ([
            'bulk_swap_atomic',
            'bulk_swap_simple',
            'bulk_transfer',
            'bulk_transfer_duplicates',
            'bulk_transfer_lowest_mints',
            'bulk_buy',
            'bulk_cancel',
            'bulk_burn',
            'bulk_stake',
            'bulk_unstake',
        ].includes(searchSearch) ||
            (searchSearch === 'bulk_bundle' &&
                searchAuthor &&
                searchAuthor !== '*')) &&
        assets.length > 0
    ) {
        if (Object.keys(transferItems).length < assets.length) {
            dropDownOptions.push({ value: 'add', label: 'Add All' })
        }
        if (Object.keys(transferItems).length < assets.length) {
            dropDownOptions.push({ value: 'addlow', label: 'Add Low Mints' })
        }
        if (Object.keys(transferItems).length) {
            dropDownOptions.push({ value: 'remove', label: 'Remove All' })
        }
        if (Object.keys(transferItems).length) {
            dropDownOptions.push({
                value: 'removelow',
                label: 'Remove Low Mints',
            })
        }
    }
    if (sellActions.includes(searchSearch) && assets && assets.length > 0) {
        dropDownOptions.push({ value: 'reduce', label: '1% Below Floor Price' })
        dropDownOptions.push({
            value: 'reduce1Wax',
            label: '1 WAX Below Floor Price',
        })
        dropDownOptions.push({
            value: 'floor',
            label: 'Floor Price',
        })
        dropDownOptions.push({
            value: 'reduceMedian',
            label: 'Set to Median',
        })
        if (Object.keys(transferItems).length || transferSummaryItems.length) {
            dropDownOptions.push({ value: 'remove', label: 'Remove All' })
        }
        if (
            searchSearch !== 'bulk_multi_sell' &&
            Object.keys(transferItems).length
        ) {
            dropDownOptions.push({
                value: 'removelow',
                label: 'Remove Low Mints',
            })
        }
        if (
            searchSearch !== 'bulk_multi_sell' &&
            Object.keys(transferItems).length
        ) {
            dropDownOptions.push({
                value: 'equalizeImageName',
                label: 'Same Price for Same Image/Name',
            })
        }
        if (
            searchSearch !== 'bulk_multi_sell' &&
            Object.keys(transferItems).length
        ) {
            dropDownOptions.push({
                value: 'equalizeRarity',
                label: 'Same Price for Same Rarity',
            })
        }
    }
    if (searchSearch === 'bulk_edit') {
        dropDownOptions.push({
            value: 'reduceOne',
            label: 'Reduce by 1 WAX',
        })
    }

    const saveAmountToSell = (localId, localAmountToSell) => {
        amountToSell[localId] = localAmountToSell
        setAmountToSell(amountToSell)
        setChanged(changed + 1)
    }

    return (
        <div className={cn('w-full dark:text-white text-black')}>
            <div className={cn('relative px-4 w-full')}>
                {assets.length > 0 && (
                    <Card className="my-4">
                        <CardBody>{assets.length > 0 ? footer : ''}</CardBody>
                    </Card>
                )}

                <div
                    className={cn(
                        'relative flex md:justify-end justify-center my-auto w-full',
                        'md:w-auto',
                    )}
                >
                    <div className="mb-4">
                        <Select
                            scrollShadowProps={{ hideScrollBar: false }}
                            id="DropdownField13"
                            variant="faded"
                            size="md"
                            classNames={{
                                listbox: 'text-black dark:text-white',
                                label: 'text-black dark:text-white',
                            }}
                            className="w-full xs1:min-w-[270px] min-w-[200px] font-semibold"
                            label={'AutoFill Options'}
                            onChange={onSelect}
                            disabled={isLoading}
                        >
                            {dropDownOptions.map((option) => (
                                <SelectItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </SelectItem>
                            ))}
                        </Select>
                    </div>
                </div>

                {assets && assets.length > 0 && (
                    <div
                        className={cn(
                            'w-auto grid grid-cols-1 xs1:grid-cols-2 lg:grid-cols-3 lg1:grid-cols-4 1xl:grid-cols-5 5xl:grid-cols-6 gap-4 md:gap-4',
                        )}
                    >
                        {searchSearch !== 'bulk_multi_sell' &&
                            assets
                                .filter((asset) =>
                                    asset.standard === 'atomicassets'
                                        ? asset['aAssetId']
                                        : asset['assetId'],
                                )
                                .map((asset, index) => (
                                    <LazyLoad offset={100} offsetBottom={100}>
                                        <BulkItem
                                            asset={asset}
                                            index={index}
                                            sellPrices={sellPrices}
                                            sellPrice={
                                                sellPrices
                                                    ? sellPrices[
                                                          (asset.standard ===
                                                          'atomicassets'
                                                              ? asset[
                                                                    'aAssetId'
                                                                ]
                                                              : asset['assetId']
                                                          ).toString()
                                                      ]
                                                    : ''
                                            }
                                            errorAssets={errorAssets}
                                            transactions={transactions}
                                            transaction={
                                                transactions
                                                    ? transactions[
                                                          (asset.standard ===
                                                          'atomicassets'
                                                              ? asset[
                                                                    'aAssetId'
                                                                ]
                                                              : asset['assetId']
                                                          ).toString()
                                                      ]
                                                    : ''
                                            }
                                            dismissError={dismissError}
                                            searchSearch={searchSearch}
                                            transferItems={transferItems}
                                            changed={changed}
                                            transferItem={
                                                transferItems
                                                    ? transferItems[
                                                          (asset.standard ===
                                                          'atomicassets'
                                                              ? asset[
                                                                    'aAssetId'
                                                                ]
                                                              : asset['assetId']
                                                          ).toString()
                                                      ]
                                                    : ''
                                            }
                                            removeAsset={() =>
                                                removeAsset(
                                                    transferItems,
                                                    asset.assetId,
                                                )
                                            }
                                            distributeItem={
                                                distributeItems
                                                    ? distributeItems[
                                                          (asset.standard ===
                                                          'atomicassets'
                                                              ? asset[
                                                                    'aAssetId'
                                                                ]
                                                              : asset['assetId']
                                                          ).toString()
                                                      ]
                                                    : ''
                                            }
                                            distributeItems={distributeItems}
                                            changeReceivers={changeReceivers}
                                            changePrice={changePrice}
                                            sellActions={sellActions}
                                            checkItem={checkItem}
                                            checkBundleItem={checkBundleItem}
                                            setErrorAssets={setErrorAssets}
                                        />
                                    </LazyLoad>
                                ))}
                        {searchSearch === 'bulk_multi_sell' &&
                            assets
                                .filter((asset) => asset.summaryId)
                                .map((asset, index) => (
                                    <LazyLoad offset={100} offsetBottom={100}>
                                        <BulkSummaryItem
                                            asset={asset}
                                            index={index}
                                            transactions={transactions}
                                            dismissError={dismissError}
                                            searchSearch={searchSearch}
                                            changePrice={changeSummaryPrice}
                                            saveAmountToSell={saveAmountToSell}
                                            soldItems={
                                                soldSummaryItems &&
                                                soldSummaryItems[
                                                    asset.summaryId
                                                ]
                                            }
                                            sellPrices={
                                                sellSummaryPrices &&
                                                sellSummaryPrices[
                                                    asset.summaryId
                                                ]
                                            }
                                            errorAssets={
                                                errorAssets &&
                                                errorAssets[asset.summaryId]
                                            }
                                        />
                                    </LazyLoad>
                                ))}
                    </div>
                )}
                {isLoading && <LoadingIndicator />}

                {!isLoading &&
                assets &&
                assets.length % limit === 0 &&
                loadMoreButton ? (
                    <div className={'w-full flex mt-4'}>{loadMoreButton}</div>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}
