import cn from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazy-load'
import config from '../../config.json'
import BurnIndicator from '../burnindicator/BurnIndicator'
import CheckIndicator from '../checkindicator/CheckIndicator'
import MainButton from '../common/util/input/MainButton'
import { floatRegEx } from '../editor/EditorComponents'
import { formatNumber, formatUSD, formatWAX } from '../helpers/FormatLinks'
import VideoElement from '../videoelement/VideoElement'
import BulkItemBox from './BulkItemBox'
import { Card, CardBody, Input } from '@nextui-org/react'
import ErrorNote from '../common/util/ErrorNote'

const BulkItem = ({
    asset,
    index,
    sellPrices,
    errorAssets,
    transactions,
    dismissError,
    searchSearch,
    transferItems,
    distributeItems,
    removeAsset,
    changeReceivers,
    changePrice,
    checkItem,
    checkBundleItem,
}) => {
    const {
        mint,
        rank,
        offer,
        author,
        rarity,
        aAssetId,
        average,
        usd_average,
        owner,
        backimg,
        lowest,
        attributesFloor,
        image,
        standard,
        assetId,
        last_sold,
        usd_last_sold,
        num_sales,
        verified,
        mdata,
        collectionImage,
        collectionThumbnail,
        thumbnail,
        universalPreview,
    } = asset

    const { t } = useTranslation('common')

    let video = ''
    const assetData = mdata ? JSON.parse(mdata) : null
    if (
        assetData &&
        Object.keys(assetData).includes('video') &&
        assetData['video']
    ) {
        video = assetData['video'].includes('http')
            ? assetData['video']
            : `${config.ipfs}${assetData['video']}`
    }

    if (thumbnail && video) {
        const hashes = thumbnail.split('hash=')
        if (hashes.length > 1) {
            const hash = hashes[1]
            if (video.includes(hash)) {
                video = thumbnail
            } else {
                video = null
            }
        }
    }

    const localId = asset.standard === 'atomicassets' ? aAssetId : assetId

    const transactionId = transactions[localId]

    const showInput =
        ![
            'bulk_transfer',
            'bulk_swap_atomic',
            'bulk_swap_simple',
            'bulk_buy',
            'bulk_cancel',
            'bulk_transfer_duplicates',
            'bulk_transfer_lowest_mints',
            'bulk_bundle',
            'bulk_bundle_lowest_mints',
            'bulk_bundle_highest_mints',
            'bulk_bundle_highest_duplicates',
            'bulk_burn',
            'bulk_stake',
            'bulk_unstake',
        ].includes(searchSearch) && !transactionId

    const showNumSales =
        ![
            'bulk_transfer_duplicates',
            'bulk_transfer_lowest_mints',
            'bulk_unstake',
            'bulk_stake',
        ].includes(searchSearch) && !transactionId

    const showAverage =
        ![
            'bulk_burn',
            'bulk_distribute',
            'bulk_transfer',
            'bulk_swap_atomic',
            'bulk_swap_simple',
            'bulk_transfer_duplicates',
            'bulk_transfer_lowest_mints',
            'bulk_unstake',
            'bulk_stake',
        ].includes(searchSearch) && !transactionId

    const showOwner = ['bulk_unstake'].includes(searchSearch) && !transactionId

    const showFloor = showAverage

    const showLastSold = showAverage

    const showOffer = ['bulk_buy', 'bulk_cancel'].includes(searchSearch)

    return (
        <LazyLoad offset={200} className="h-full">
            <Card className="h-full">
                <CardBody className="p-2 sm:p-4">
                    <div
                        className={cn(
                            'w-full relative flex gap-2 flex-wrap',
                            { grid: !transactionId },
                            { flex: transactionId },

                            { '': sellPrices[localId] },
                        )}
                        key={localId}
                    >
                        <div className={'w-full'}>
                            <div className="flex items-center justify-center gap-2 border-2 rounded-lg border-zinc-800">
                                <div className="max-w-[30%]">
                                    <div
                                        className={
                                            'flex h-16 w-full justify-center'
                                        }
                                    >
                                        {video && !universalPreview ? (
                                            <VideoElement
                                                className={cn('flex p-1')}
                                                image={image}
                                                backimg={backimg}
                                                video={video}
                                                autoPlay={true}
                                                timeout={1}
                                            >
                                                <source src={video} />
                                                Your browser does not support
                                                the video tag.
                                            </VideoElement>
                                        ) : (
                                            <div className={cn('flex p-1')}>
                                                <img
                                                    className={'m-auto '}
                                                    src={
                                                        universalPreview
                                                            ? universalPreview
                                                            : thumbnail
                                                            ? thumbnail
                                                            : image
                                                    }
                                                    alt={
                                                        asset.name
                                                            ? asset.name
                                                            : 'asset'
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div>
                                    <div className="w-full ">
                                        <p
                                            className={cn(
                                                'text-left text-primary font-bold truncate',
                                                {
                                                    'text-2xs md:text-1xs':
                                                        asset.name &&
                                                        asset.name.length > 50,
                                                },
                                                {
                                                    'text-1xs md:text-xs':
                                                        asset.name &&
                                                        asset.name.length >
                                                            30 &&
                                                        asset.name.length < 49,
                                                },
                                                {
                                                    'text-sm md:text-sm':
                                                        asset.name &&
                                                        asset.name.length < 29,
                                                },
                                            )}
                                        >
                                            {asset.name}
                                        </p>
                                    </div>

                                    <div className="flex justify-start w-full">
                                        <a
                                            target="_blank"
                                            href={
                                                '/market?searchType=sales&order_by=offer_asc&collection=' +
                                                author
                                            }
                                            rel="noreferrer"
                                            tabIndex="-1"
                                            className="flex"
                                        >
                                            <div
                                                className={cn(
                                                    'flex flex-row justify-center items-center space-x-2 w-fit rounded-md text-xs md:text-xs',
                                                )}
                                            >
                                                {collectionImage ? (
                                                    <div
                                                        className={cn(
                                                            'flex w-8 h-8 p-1',
                                                        )}
                                                    >
                                                        <img
                                                            className={
                                                                'm-auto  rounded-md'
                                                            }
                                                            src={
                                                                collectionThumbnail
                                                                    ? collectionThumbnail
                                                                    : collectionImage
                                                            }
                                                            alt={
                                                                author
                                                                    ? author
                                                                    : 'author'
                                                            }
                                                        />
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                <div
                                                    className={cn(
                                                        'flex items-center space-x-2',
                                                    )}
                                                >
                                                    <div
                                                        className={cn(
                                                            'text-xs',
                                                        )}
                                                    >
                                                        {author}
                                                    </div>
                                                    {verified ? (
                                                        <div
                                                            className={cn(
                                                                'h-4 w-4',
                                                            )}
                                                        >
                                                            <img
                                                                className={
                                                                    'm-auto'
                                                                }
                                                                src="/verified.svg"
                                                                width={24}
                                                                height={24}
                                                                alt="verified"
                                                            />
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="grid items-start gap-1 mt-2"
                                style={{
                                    gridTemplateColumns: `repeat(auto-fill, minmax(80px, 1fr))`,
                                }}
                            >
                                {!transactionId && mint && !rank ? (
                                    <BulkItemBox title={t('asset.mint')}>
                                        #{mint}
                                    </BulkItemBox>
                                ) : !transactionId && rank ? (
                                    <BulkItemBox title={t('ranking.rank')}>
                                        #{rank}
                                    </BulkItemBox>
                                ) : (
                                    ''
                                )}
                                {!transactionId && showOwner && (
                                    <BulkItemBox title={t('asset.contract')}>
                                        {owner}
                                    </BulkItemBox>
                                )}

                                {!transactionId && (
                                    <BulkItemBox title={t('asset.rarity')}>
                                        <a
                                            target="_blank"
                                            href={`/market?searchType=sales&order_by=offer_asc&collection=${author}&rarity=${rarity}`}
                                            rel="noreferrer"
                                            tabIndex="-1"
                                        >
                                            {rarity ? rarity : '-'}
                                        </a>
                                    </BulkItemBox>
                                )}
                                {!transactionId && showNumSales ? (
                                    <BulkItemBox title={t('asset.num_sales')}>
                                        {num_sales ? num_sales : ''}
                                    </BulkItemBox>
                                ) : (
                                    ''
                                )}
                                {!transactionId && showAverage ? (
                                    <BulkItemBox
                                        title={t('asset.average_sold')}
                                    >
                                        {average ? (
                                            <div
                                                className={
                                                    'text-xs md:text-xs p-1'
                                                }
                                            >
                                                {formatNumber(average)} WAX{' '}
                                                <br />
                                                <span className={'text-xs'}>
                                                    {'('}{' '}
                                                    {formatUSD(usd_average)}
                                                    {' )'}
                                                </span>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </BulkItemBox>
                                ) : (
                                    ''
                                )}
                                {!transactionId && showFloor ? (
                                    <BulkItemBox title={'Floor'}>
                                        {lowest || attributesFloor ? (
                                            <div
                                                className={
                                                    'text-xs md:text-xs p-1'
                                                }
                                            >
                                                {formatNumber(
                                                    attributesFloor
                                                        ? attributesFloor
                                                        : lowest,
                                                )}{' '}
                                                WAX
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </BulkItemBox>
                                ) : (
                                    ''
                                )}
                                {!transactionId && showLastSold ? (
                                    <BulkItemBox title={t('asset.last_sold')}>
                                        {last_sold && (
                                            <div
                                                className={
                                                    'text-xs md:text-xs p-1'
                                                }
                                            >
                                                {formatNumber(last_sold)} WAX{' '}
                                                <br />
                                                <span className={'text-xs'}>
                                                    {'('}{' '}
                                                    {formatUSD(usd_last_sold)}
                                                    {' )'}
                                                </span>
                                            </div>
                                        )}
                                    </BulkItemBox>
                                ) : (
                                    ''
                                )}
                            </div>
                            {!transactionId && showOffer ? (
                                <div
                                    className={
                                        'flex w-full justify-center mx-auto'
                                    }
                                >
                                    <BulkItemBox
                                        contentWidth={'w-full'}
                                        title={t('asset.price')}
                                        breakWhat={'text-xl'}
                                        textSize={
                                            'text-base md:text-base font-bold'
                                        }
                                    >
                                        {offer ? `${formatWAX(offer)}` : ''}
                                    </BulkItemBox>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>

                        <div className="flex items-end mx-auto">
                            {!transactionId && showInput ? (
                                <div className="w-full">
                                    <Input
                                        id={'sell-' + localId}
                                        type="text"
                                        classNames={{
                                            label: 'text-zinc-400 font-semibold ',
                                        }}
                                        size="sm"
                                        variant="solid"
                                        label={
                                            searchSearch === 'bulk_distribute'
                                                ? t('asset.receiver')
                                                : searchSearch ===
                                                  'bulk_auction'
                                                ? t('search.start_bid')
                                                : t('search.price')
                                        }
                                        placeholder={
                                            asset.offer ? asset.offer : ''
                                        }
                                        value={
                                            searchSearch === 'bulk_distribute'
                                                ? distributeItems[localId]
                                                    ? distributeItems[localId]
                                                          .receiver
                                                    : ''
                                                : sellPrices[localId]
                                                ? sellPrices[localId].price
                                                : ''
                                        }
                                        onChange={
                                            searchSearch === 'bulk_distribute'
                                                ? changeReceivers
                                                : changePrice
                                        }
                                        isInvalid={
                                            searchSearch === 'bulk_distribute'
                                                ? !(
                                                      distributeItems[
                                                          localId
                                                      ] &&
                                                      distributeItems[localId]
                                                          .receiver
                                                  )
                                                : !floatRegEx.test(
                                                      sellPrices[localId]
                                                          ? sellPrices[localId]
                                                                .price
                                                          : '',
                                                  )
                                        }
                                        endContent={
                                            <p className="w-[50px] mb-[10px] text-primary font-semibold text-xs md:text-xs">
                                                WAX
                                            </p>
                                        }
                                    />

                                    {searchSearch !== 'bulk_distribute' ? (
                                        <div
                                            className={cn(
                                                'relative text-xs opacity-60 top-2 mx-auto text-center',
                                            )}
                                        >
                                            {asset.price ? (
                                                `Bought for: ${formatNumber(
                                                    asset.price,
                                                )} WAX / $${formatNumber(
                                                    asset.usdPrice,
                                                )}`
                                            ) : (
                                                <div className="h-[16px]"></div>
                                            )}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ) : transactionId ? (
                                <div
                                    className={cn(
                                        'flex justify-center mx-auto flex-1 mt-2 w-full',
                                    )}
                                >
                                    <div className={'my-auto'}>
                                        {searchSearch === 'bulk_burn' ? (
                                            <BurnIndicator />
                                        ) : (
                                            <CheckIndicator />
                                        )}
                                    </div>
                                    <BulkItemBox title={'Transaction'}>
                                        <div
                                            className={cn(
                                                'm-auto text-center break-words',
                                            )}
                                        >
                                            <a
                                                className={cn(
                                                    'text-primary ml-auto no-underline text-xs',
                                                )}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={
                                                    'https://waxblock.io/transaction/' +
                                                    transactionId
                                                }
                                                tabIndex="-1"
                                            >
                                                {transactionId}
                                            </a>
                                        </div>
                                    </BulkItemBox>
                                </div>
                            ) : (
                                <div
                                    className={cn(
                                        'flex justify-center flex-1 mt-2 w-full',
                                    )}
                                >
                                    {Object.keys(transferItems).includes(
                                        standard === 'atomicassets'
                                            ? '' + aAssetId
                                            : '' + assetId,
                                    ) ? (
                                        <MainButton
                                            tabIndex={index + 1}
                                            onClick={removeAsset}
                                            fullWidth={false}
                                            className={'w-20 max-w-20 min-w-20'}
                                            padding={''}
                                        >
                                            {t('trade.remove')}
                                        </MainButton>
                                    ) : (
                                        <MainButton
                                            fullWidth={true}
                                            tabIndex={index + 1}
                                            padding={''}
                                            onClick={
                                                [
                                                    'bulk_transfer',
                                                    'bulk_swap_atomic',
                                                    'bulk_swap_simple',
                                                    'bulk_distribute',
                                                    'bulk_transfer_duplicates',
                                                    'bulk_transfer_lowest_mints',
                                                    'bulk_buy',
                                                    'bulk_cancel',
                                                    'bulk_stake',
                                                    'bulk_unstake',
                                                    'bulk_burn',
                                                ].includes(searchSearch)
                                                    ? () => checkItem(localId)
                                                    : () =>
                                                          checkBundleItem(
                                                              localId,
                                                          )
                                            }
                                            className={''}
                                        >
                                            {t('trade.add')}
                                        </MainButton>
                                    )}
                                </div>
                            )}
                        </div>
                        {errorAssets ? (
                            <>
                                {Object.keys(errorAssets).includes(
                                    '' + localId,
                                ) ? (
                                    <div
                                        onClick={() => dismissError(localId)}
                                        className="absolute flex w-full top-1/2"
                                    >
                                        <div className={'my-auto'}>
                                            <ErrorNote
                                                error={
                                                    errorAssets['' + localId]
                                                }
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : null}
                    </div>
                </CardBody>
            </Card>
        </LazyLoad>
    )
}

function compareFunction(prevAsset, nextAsset) {
    const localId1 = (
        prevAsset.asset.standard === 'atomicassets'
            ? prevAsset.asset.aAssetId
            : prevAsset.asset.assetId
    ).toString()
    const localId2 = (
        nextAsset.standard === 'atomicassets'
            ? nextAsset.asset.aAssetId
            : nextAsset.asset.assetId
    ).toString()

    let item1 = 'a'
    let item2 = 'a'

    let price1 = ''
    let price1a = ''
    let price2 = ''
    let price2a = ''

    let receiver1 = ''
    let receiver2 = ''

    let tx1 = ''
    let tx2 = ''

    if (prevAsset.sellPrice) {
        price1 = prevAsset.sellPrice
        price1a =
            prevAsset.sellPrices && prevAsset.sellPrices[localId1]
                ? prevAsset.sellPrices[localId1].price
                : ''
    }

    if (prevAsset.sellPrices) {
        price1a =
            prevAsset.sellPrices && prevAsset.sellPrices[localId1]
                ? prevAsset.sellPrices[localId1].price
                : ''
    }

    if (nextAsset.sellPrice) {
        price2 = nextAsset.sellPrice
        price2a =
            nextAsset.sellPrices && nextAsset.sellPrices[localId2]
                ? nextAsset.sellPrices[localId2].price
                : ''
    }

    if (nextAsset.sellPrices) {
        price2a =
            nextAsset.sellPrices && nextAsset.sellPrices[localId2]
                ? nextAsset.sellPrices[localId2].price
                : ''
    }

    if (prevAsset.distributeItem) {
        receiver1 = prevAsset.distributeItem.receiver
    }

    if (nextAsset.distributeItem) {
        receiver2 = nextAsset.distributeItem.receiver
    }

    if (prevAsset.transaction) {
        tx1 = prevAsset.transaction
    }

    if (nextAsset.transaction) {
        tx2 = nextAsset.transaction
    }

    if (prevAsset.transferItem) {
        item1 =
            prevAsset.transferItem.standard === 'atomicassets'
                ? prevAsset.transferItem.aAssetId
                : prevAsset.transferItem.assetId
    }

    if (nextAsset.transferItem) {
        item2 =
            nextAsset.transferItem.standard === 'atomicassets'
                ? nextAsset.transferItem.aAssetId
                : nextAsset.transferItem.assetId
    }

    if (prevAsset.transferItem) {
        item1 =
            prevAsset.transferItem.standard === 'atomicassets'
                ? prevAsset.transferItem.aAssetId
                : prevAsset.transferItem.assetId
    }

    if (nextAsset.transferItem) {
        item2 =
            nextAsset.transferItem.standard === 'atomicassets'
                ? nextAsset.transferItem.aAssetId
                : nextAsset.transferItem.assetId
    }

    return (
        ((!prevAsset.errorAssets && !nextAsset.errorAssets) ||
            (prevAsset.errorAssets &&
                nextAsset.errorAssets &&
                Object.keys(prevAsset.errorAssets).length === 0 &&
                Object.keys(nextAsset.errorAssets).length === 0)) &&
        localId1 === localId2 &&
        prevAsset.changed === nextAsset.changed &&
        prevAsset.index === nextAsset.index &&
        item1 === item2 &&
        price1 === price2 &&
        price2a === price1a &&
        receiver1 === receiver2 &&
        tx1 === tx2
    )
}

export default React.memo(BulkItem, compareFunction)
