import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { Button, Tooltip } from '@nextui-org/react'
import { Divider, FormControl, FormControlLabel, List } from '@mui/material'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CollectionSelector from '../collectionselector/CollectionSelector'

import { Accordion, AccordionItem } from '@nextui-org/react'
import Input2 from '../common/util/input/Input'
import {
    createAttributes,
    createBorders,
    createCategories,
    createColors,
    createRarities,
    createSchemas,
    createTypes,
    createVariants,
    getDefaultAttrNames,
} from '../helpers/FormatLinks'
import RangeField from './searchfilters/RangeField'
import { Input } from '@nextui-org/react'
import InputSearch from './searchfilters/InputSearch'

import { Select, SelectItem, Switch } from '@nextui-org/react'
import { buttonSearchCommonStyles, selectStyles } from '../common/styles'
import { useSharedState } from '../waxplorer/Waxplorer'

function DrawerLeft(props) {
    const [isOpen, setIsOpen] = useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    const { t } = useTranslation('common')
    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null
    const show = props['show']
    const [reviveCount, setReviveCount] = useState(
        props && props['searchAttr10'] ? props['searchAttr10'] : '',
    )

    const [timer, setTimer] = useState(null)

    const {
        searchSchema,
        searchCategory,
        searchVariant,
        searchRarity,
        searchColor,
        searchBorder,
        searchType,
        searchFavorites,
        searchPFPs,
        searchUnlisted,
        searchBacked,
        searchAttr7,
        searchAttr8,
        searchAttr9,
        searchAttr10,
        searchLimit,
        maxPrice,
        minPrice,
        minMint,
        maxMint,
        searchOwner,
        searchTerm,
        attributes,
        searchAuthor,
        searchContract,
        searchSearch,
        searchSettings,
        setVars,
        exactSearch,
        orderBy,
        isLoading,
        searchVerified,
        ownerTerm,
        bulk,
        pfpAttributes,
        authorSchemas,
        clearFilters,
        searchRecentlySold,
        displayCollectionSelector,
        minAverage,
        maxAverage,
        page,
        tradeSearch,
        attrNames = getDefaultAttrNames(),
        targetTab,
    } = props

    const [localMinPrice, setLocalMinPrice] = useState(minPrice)
    const [localMaxPrice, setLocalMaxPrice] = useState(maxPrice)

    const [localMinAverage, setLocalMinAverage] = useState(minAverage)
    const [localMaxAverage, setLocalMaxAverage] = useState(maxAverage)

    const [localMinMint, setLocalMinMint] = useState(minMint)
    const [localMaxMint, setLocalMaxMint] = useState(maxMint)

    const [localSearchOwner, setLocalSearchOwner] = useState(searchOwner)

    const clearLocal = () => {
        setLocalSearchOwner('')
        setLocalMinPrice('')
        setLocalMaxPrice('')
        setLocalMinAverage('')
        setLocalMaxAverage('')
        setLocalMinMint('')
        setLocalMaxMint('')
    }

    const ownerFixed =
        searchSettings &&
        searchSettings['owner'] &&
        searchSettings['owner']['fixed']

    const rarityFixed =
        searchSettings &&
        searchSettings['rarity'] &&
        searchSettings['rarity']['fixed']

    const variantFixed =
        searchSettings &&
        searchSettings['variant'] &&
        searchSettings['variant']['fixed']

    const schemaFixed =
        searchSettings &&
        searchSettings['schema'] &&
        searchSettings['schema']['fixed']

    const schemaOptions =
        searchSettings &&
        searchSettings['schema'] &&
        searchSettings['schema']['options'] &&
        searchSettings['schema']['options'][searchAuthor]
            ? searchSettings['schema']['options'][searchAuthor]
            : null

    const typeFixed =
        searchSettings &&
        searchSettings['type'] &&
        searchSettings['type']['fixed']

    const colorFixed =
        searchSettings &&
        searchSettings['color'] &&
        searchSettings['color']['fixed']

    const borderFixed =
        searchSettings &&
        searchSettings['border'] &&
        searchSettings['border']['fixed']

    const attr7Fixed =
        searchSettings &&
        searchSettings['attr7'] &&
        searchSettings['attr7']['fixed']

    const attr8Fixed =
        searchSettings &&
        searchSettings['attr8'] &&
        searchSettings['attr8']['fixed']

    const attr9Fixed =
        searchSettings &&
        searchSettings['attr9'] &&
        searchSettings['attr9']['fixed']

    const attr10Fixed =
        searchSettings &&
        searchSettings['attr10'] &&
        searchSettings['attr10']['fixed']

    const changeReviveCount = (e) => {
        setReviveCount(e.target.value)
        if (timer) {
            clearTimeout(timer)
        }
        setTimer(
            setTimeout(() => {
                onSelectAttr10({ target: { value: e.target.value } })
                setTimer(null)
            }, 400),
        )
    }

    const initFilters = () => {
        const startFilters = []

        if (searchSchema || searchAuthor) {
            startFilters.push('schema')
        }
        if (searchRarity || searchAuthor) {
            startFilters.push('rarity')
        }
        if (searchVariant) {
            startFilters.push('variant')
        }
        if (searchColor) {
            startFilters.push('color')
        }
        if (searchBorder) {
            startFilters.push('border')
        }
        if (searchType) {
            startFilters.push('type')
        }
        if (searchFavorites) {
            startFilters.push('favorites')
        }
        if (searchAttr7) {
            startFilters.push('attr7')
        }
        if (searchAttr8) {
            startFilters.push('attr8')
        }
        if (searchAttr9) {
            startFilters.push('attr9')
        }
        if (searchAttr10) {
            startFilters.push('attr10')
        }
        if (maxPrice || minPrice) {
            startFilters.push('price')
        }
        if (maxMint || minMint) {
            startFilters.push('mint')
        }
        if (searchOwner) {
            startFilters.push('owner')
        }
        if (searchTerm) {
            startFilters.push('name')
        }

        return startFilters
    }

    const filters = initFilters()

    useEffect(() => {}, [filters.length])

    const isCollectionSales = searchSearch === 'collection_sales'

    const isPackSearch =
        searchSearch === 'ft_packs' || searchSearch === 'my_ft_packs'

    let searchDropDownOptions = [
        { value: 'sales', label: 'Standard' },
        { value: 'my_sales', label: 'My Listings' },
        { value: 'cheapest', label: t('search.cheapest') },
        { value: 'below_average', label: t('search.below_average') },
        { value: 'below_last_sold', label: t('search.below_last_sold') },
        { value: 'trades', label: t('search.trades') },
        { value: 'assets', label: t('search.assets') },
        { value: 'burned', label: t('search.burned') },
        { value: 'staked', label: t('asset.staked') },
        { value: 'bundles', label: t('search.bundles') },
    ]

    if (searchAuthor && searchAuthor === 'kogsofficial')
        searchDropDownOptions.push({ value: 'tubed', label: t('asset.tubed') })

    let recentDropDownOptions = [
        { value: '', label: 'None' },
        { value: 'hour', label: 'The Last hour' },
        { value: 'day', label: 'The Last Day' },
        { value: 'week', label: 'The Last Week' },
        { value: 'month', label: 'The Last Month' },
    ]

    if (isCollectionSales) {
        searchDropDownOptions = null
    }

    if (bulk)
        searchDropDownOptions = [
            { value: 'bulk_sell', label: t('search.bulk_sell') },
            //{ value: 'bulk_multi_sell', label: t('search.bulk_multi_sell') },
            { value: 'bulk_auction', label: t('popups.auction') },
            { value: 'bulk_burn', label: t('search.bulk_burn') },
            { value: 'bulk_unstake', label: t('asset.unstake') },
            { value: 'bulk_edit', label: t('search.bulk_edit') },
            { value: 'bulk_sell_dupes', label: t('search.bulk_sell_dupes') },
            {
                value: 'bulk_sell_highest_duplicates',
                label: t('search.bulk_sell_highest_duplicates'),
            },
            { value: 'bulk_cancel', label: t('asset.cancel') },
            { value: 'bulk_buy', label: t('asset.buy') },
            { value: 'bulk_transfer', label: t('search.bulk_transfer') },
            { value: 'bulk_swap_atomic', label: t('search.bulk_swap_atomic') },
            { value: 'bulk_swap_simple', label: t('search.bulk_swap_simple') },
            {
                value: 'bulk_transfer_duplicates',
                label: t('search.bulk_transfer_duplicates'),
            },
            {
                value: 'bulk_transfer_lowest_mints',
                label: t('search.bulk_transfer_lowest_mints'),
            },
            { value: 'bulk_distribute', label: t('search.bulk_distribute') },
            { value: 'bulk_bundle', label: t('search.bundle') },
            {
                value: 'bulk_bundle_highest_mints',
                label: t('search.bundle_highest_mints'),
            },
            {
                value: 'bulk_bundle_lowest_mints',
                label: t('search.bundle_lowest_mints'),
            },
            {
                value: 'bulk_bundle_highest_duplicates',
                label: t('search.bundle_highest_duplicates'),
            },
        ]

    if (targetTab === 'pfp') {
        searchDropDownOptions = [
            {
                value: 'pfps',
                label: t('search.pfp_ranking'),
            },
            { value: 'sales', label: t('search.sales') },
        ]
    }

    if (targetTab === 'inventory' && userName) {
        searchDropDownOptions = [
            {
                value: 'assets',
                label:
                    userName === searchOwner
                        ? t('search.my_assets')
                        : t('search.assets'),
            },
            { value: 'duplicates', label: t('search.duplicates') },
            {
                value: 'highest_duplicates',
                label: t('search.highest_duplicates'),
            },
            { value: 'my_packs', label: t('search.my_packs') },
            { value: 'lowest_mints', label: t('search.lowest_mints') },
            { value: 'highest_mints', label: t('search.highest_mints') },
            { value: 'staked', label: t('asset.staked') },
            { value: 'favorites', label: t('search.favorites') },
        ]
        if (searchAuthor && searchAuthor === 'kogsofficial')
            searchDropDownOptions.push({
                value: 'tubed',
                label: t('asset.tubed'),
            })
    }

    const contractDropdownOptions = [
        { value: 'all', label: 'All' },
        { value: 's.rplanet', label: 'RPlanet' },
        { value: 'waxdaofarmer', label: 'WAXDao' },
    ]

    const getContractOption = (contract) => {
        const option = Math.max(
            contractDropdownOptions.map((item) => item.value).indexOf(contract),
            0,
        )

        return contractDropdownOptions[option]
    }

    const defaultContractOption = getContractOption(searchContract)

    const limitDropdownOptions = [
        { value: 50, label: 50 },
        { value: 30, label: 30 },
        { value: 45, label: 45 },
        { value: 120, label: 120 },
        { value: 180, label: 180 },
        { value: 240, label: 240 },
        { value: 300, label: 300 },
        { value: 360, label: 360 },
    ]

    const getLimitOption = (limit) => {
        const option = Math.max(
            limitDropdownOptions
                .map((item) => item.value)
                .indexOf(parseInt(limit)),
            0,
        )

        return limitDropdownOptions[option]
    }

    const defaultLimitOption = getLimitOption(searchLimit)

    if (targetTab === 'summaries' && userName) searchDropDownOptions = null
    if (targetTab === 'transfers') searchDropDownOptions = null
    else if (targetTab === 'buyoffers') {
        if (page === 'inventory') searchDropDownOptions = null
        else
            searchDropDownOptions = [
                { value: 'buyoffers', label: 'All' },
                { value: 'buyoffers_owned', label: 'Owned' },
                { value: 'best_offers', label: 'Best Offers' },
                {
                    value: 'my_buyoffers',
                    label: 'My Offers',
                },
            ]
    } else if (targetTab === 'assets') {
        searchDropDownOptions = [
            { value: 'assets', label: t('search.all_assets') },
            { value: 'burned', label: t('search.burned') },
            { value: 'staked', label: t('asset.staked') },
        ]
        if (searchAuthor && searchAuthor === 'kogsofficial')
            searchDropDownOptions.push({
                value: 'tubed',
                label: t('asset.tubed'),
            })
    } else if (targetTab === 'templates') {
        searchDropDownOptions = null
    } else if (tradeSearch) searchDropDownOptions = null
    else if (targetTab === 'bundles') searchDropDownOptions = null
    else if (targetTab === 'sales' || targetTab === 'open_market') {
        searchDropDownOptions = [
            { value: 'sales', label: 'Standard' },
            { value: 'cheapest', label: t('search.cheapest_sales') },
            {
                value: 'owned_lower_mints',
                label: t('search.owned_lower_mints'),
            },
        ]
        if (userName) {
            searchDropDownOptions.push({
                value: 'cheapest_missing',
                label: t('search.cheapest_missing'),
            })
            searchDropDownOptions.push({
                value: 'missing',
                label: 'Missing Listings',
            })
        }
        searchDropDownOptions.push({
            value: 'below_average',
            label: t('search.below_average'),
        })
        searchDropDownOptions.push({
            value: 'below_last_sold',
            label: t('search.below_last_sold'),
        })
        if (
            ['*', 'farmersworld', 'spacecraftx', 'bcbrawlers'].includes(
                searchAuthor,
            )
        ) {
            searchDropDownOptions.push({
                value: 'below_crafting_cost',
                label: t('search.below_crafting_cost'),
            })
        }
    } else if (targetTab === 'auctions')
        if (
            searchSettings &&
            searchSettings['owner'] &&
            searchSettings['owner']['fixed']
        ) {
            searchDropDownOptions = []
        } else {
            searchDropDownOptions = [
                { value: 'auctions', label: 'Standard' },
                { value: 'my_auctions', label: t('search.my_auctions') },
                { value: 'below_average', label: t('search.below_average') },
                {
                    value: 'below_last_sold',
                    label: t('search.below_last_sold'),
                },
            ]
        }
    else if (targetTab === 'packs')
        searchDropDownOptions = [{ value: 'packs', label: t('overview.packs') }]
    else if (searchSearch === 'trades') searchDropDownOptions = null

    if (
        searchSettings &&
        searchSettings['search_type'] &&
        searchSettings['search_type']['fixed']
    ) {
        searchDropDownOptions = null
    }

    const selectAuthor = searchAuthor

    const schemaDropDownOptions =
        authorSchemas && !schemaFixed
            ? createSchemas(
                  authorSchemas['schemas'].filter(
                      (item) =>
                          item['schema'] !== null &&
                          (searchAuthor === item['author'] ||
                              item['artist'] === selectAuthor),
                  ),
                  searchSearch !== 'collection_sales' && !schemaOptions,
                  schemaOptions,
              )
            : null

    const categoryDropDownOptions = createCategories(t)

    const rarityDropDownOptions =
        authorSchemas && !rarityFixed && targetTab !== 'templates'
            ? createRarities(
                  authorSchemas['schemas'].filter(
                      (item) =>
                          searchAuthor &&
                          searchAuthor !== '*' &&
                          searchAuthor === item['author'] &&
                          Object.keys(item).includes('rarity') &&
                          item['rarity'] !== null &&
                          (!searchSchema || item['schema'] === searchSchema) &&
                          (!searchVariant ||
                              item['variant'] === searchVariant) &&
                          (!searchColor || item['color'] === searchColor) &&
                          (!searchBorder ||
                              !item['border'] ||
                              item['border'].toString() ===
                                  searchBorder.toString()) &&
                          (!searchType || item['type'] === searchType) &&
                          (!searchAttr7 || item['attr7'] === searchAttr7) &&
                          (!searchAttr8 || item['attr8'] === searchAttr8) &&
                          (!searchAttr9 || item['attr9'] === searchAttr9) &&
                          (!searchAttr10 ||
                              item['attr10'] === searchAttr10 ||
                              reviveCount ||
                              reviveCount === 0) &&
                          (!searchSchema || item['schema'] === searchSchema),
                  ),
                  searchSearch !== 'collection_sales',
              )
            : null

    const variantDropDownOptions =
        authorSchemas && !variantFixed && targetTab !== 'templates'
            ? createVariants(
                  authorSchemas['schemas'].filter(
                      (item) =>
                          searchAuthor &&
                          searchAuthor !== '*' &&
                          searchAuthor === item['author'] &&
                          Object.keys(item).includes('variant') &&
                          item['variant'] !== null &&
                          (!searchSchema || item['schema'] === searchSchema) &&
                          (!searchRarity || item['rarity'] === searchRarity) &&
                          (!searchBorder ||
                              !item['border'] ||
                              item['border'].toString() ===
                                  searchBorder.toString()) &&
                          (!searchType || item['type'] === searchType) &&
                          (!searchColor || item['color'] === searchColor) &&
                          (!searchAttr7 || item['attr7'] === searchAttr7) &&
                          (!searchAttr8 || item['attr8'] === searchAttr8) &&
                          (!searchAttr9 || item['attr9'] === searchAttr9) &&
                          (!searchAttr10 || item['attr10'] === searchAttr10) &&
                          (!searchSchema || item['schema'] === searchSchema),
                  ),
                  'All',
              )
            : null

    const colorDropDownOptions =
        authorSchemas && !colorFixed && targetTab !== 'templates'
            ? createColors(
                  authorSchemas['schemas'].filter(
                      (item) =>
                          searchAuthor &&
                          searchAuthor !== '*' &&
                          searchAuthor === item['author'] &&
                          Object.keys(item).includes('color') &&
                          item['color'] !== null &&
                          (!searchSchema || item['schema'] === searchSchema) &&
                          (!searchVariant ||
                              item['variant'] === searchVariant) &&
                          (!searchRarity || item['rarity'] === searchRarity) &&
                          (!searchAttr7 || item['attr7'] === searchAttr7) &&
                          (!searchAttr8 || item['attr8'] === searchAttr8) &&
                          (!searchAttr9 || item['attr9'] === searchAttr9) &&
                          (!searchAttr10 || item['attr10'] === searchAttr10) &&
                          (!searchBorder ||
                              !item['border'] ||
                              item['border'].toString() ===
                                  searchBorder.toString()),
                  ),
                  'All',
              )
            : null

    const attr7Options =
        authorSchemas && !attr7Fixed && targetTab !== 'templates'
            ? createAttributes(
                  authorSchemas['schemas'].filter(
                      (item) =>
                          searchAuthor &&
                          searchAuthor !== '*' &&
                          searchAuthor === item['author'] &&
                          Object.keys(item).includes('attr7') &&
                          item['attr7'] !== null &&
                          (!searchSchema || item['schema'] === searchSchema) &&
                          (!searchVariant ||
                              item['variant'] === searchVariant) &&
                          (!searchRarity || item['rarity'] === searchRarity) &&
                          (!searchColor || item['color'] === searchColor) &&
                          (!searchAttr8 || item['attr8'] === searchAttr8) &&
                          (!searchAttr9 || item['attr9'] === searchAttr9) &&
                          (!searchAttr10 || item['attr10'] === searchAttr10) &&
                          (!searchBorder ||
                              !item['border'] ||
                              item['border'].toString() ===
                                  searchBorder.toString()),
                  ),
                  'attr7',
                  'All',
              )
            : null

    const attr8Options =
        authorSchemas && !attr8Fixed && targetTab !== 'templates'
            ? createAttributes(
                  authorSchemas['schemas'].filter(
                      (item) =>
                          searchAuthor &&
                          searchAuthor !== '*' &&
                          searchAuthor === item['author'] &&
                          Object.keys(item).includes('attr8') &&
                          item['attr8'] !== null &&
                          (!searchSchema || item['schema'] === searchSchema) &&
                          (!searchVariant ||
                              item['variant'] === searchVariant) &&
                          (!searchRarity || item['rarity'] === searchRarity) &&
                          (!searchColor || item['color'] === searchColor) &&
                          (!searchAttr7 || item['attr7'] === searchAttr7) &&
                          (!searchAttr9 || item['attr9'] === searchAttr9) &&
                          (!searchAttr10 || item['attr10'] === searchAttr10) &&
                          (!searchBorder ||
                              !item['border'] ||
                              item['border'].toString() ===
                                  searchBorder.toString()),
                  ),
                  'attr8',
                  'All',
              )
            : null

    const attr9Options =
        authorSchemas && !attr9Fixed && targetTab !== 'templates'
            ? createAttributes(
                  authorSchemas['schemas'].filter(
                      (item) =>
                          searchAuthor &&
                          searchAuthor !== '*' &&
                          searchAuthor === item['author'] &&
                          Object.keys(item).includes('attr9') &&
                          item['attr9'] !== null &&
                          (!searchSchema || item['schema'] === searchSchema) &&
                          (!searchVariant ||
                              item['variant'] === searchVariant) &&
                          (!searchRarity || item['rarity'] === searchRarity) &&
                          (!searchColor || item['color'] === searchColor) &&
                          (!searchAttr7 || item['attr7'] === searchAttr7) &&
                          (!searchAttr8 || item['attr8'] === searchAttr8) &&
                          (!searchAttr10 || item['attr10'] === searchAttr10) &&
                          (!searchBorder ||
                              !item['border'] ||
                              item['border'].toString() ===
                                  searchBorder.toString()),
                  ),
                  'attr9',
                  'All',
              )
            : null

    const attr10Options =
        authorSchemas && !attr10Fixed && targetTab !== 'templates'
            ? createAttributes(
                  authorSchemas['schemas'].filter(
                      (item) =>
                          searchAuthor &&
                          searchAuthor !== '*' &&
                          searchAuthor === item['author'] &&
                          Object.keys(item).includes('attr10') &&
                          item['attr10'] !== null &&
                          (!searchSchema || item['schema'] === searchSchema) &&
                          (!searchVariant ||
                              item['variant'] === searchVariant) &&
                          (!searchRarity || item['rarity'] === searchRarity) &&
                          (!searchColor || item['color'] === searchColor) &&
                          (!searchAttr7 || item['attr7'] === searchAttr7) &&
                          (!searchAttr8 || item['attr8'] === searchAttr8) &&
                          (!searchAttr9 || item['attr9'] === searchAttr9) &&
                          (!searchBorder ||
                              !item['border'] ||
                              item['border'].toString() ===
                                  searchBorder.toString()),
                  ),
                  'attr10',
                  'All',
              )
            : null

    const borderDropDownOptions =
        authorSchemas && !borderFixed && targetTab !== 'templates'
            ? createBorders(
                  authorSchemas['schemas'].filter(
                      (item) =>
                          searchAuthor &&
                          searchAuthor !== '*' &&
                          searchAuthor === item['author'] &&
                          Object.keys(item).includes('border') &&
                          item['border'] !== null &&
                          (!searchSchema || item['schema'] === searchSchema) &&
                          (!searchVariant ||
                              item['variant'] === searchVariant) &&
                          (!searchRarity || item['rarity'] === searchRarity) &&
                          (!searchType || item['type'] === searchType) &&
                          (!searchAttr7 || item['attr7'] === searchAttr7) &&
                          (!searchAttr8 || item['attr8'] === searchAttr8) &&
                          (!searchAttr9 || item['attr9'] === searchAttr9) &&
                          (!searchAttr10 || item['attr10'] === searchAttr10) &&
                          (!searchColor || item['color'] === searchColor),
                  ),
                  '-',
              )
            : null

    const typeDropDownOptions =
        authorSchemas && !typeFixed && targetTab !== 'templates'
            ? createTypes(
                  authorSchemas['schemas'].filter(
                      (item) =>
                          searchAuthor &&
                          searchAuthor !== '*' &&
                          searchAuthor === item['author'] &&
                          Object.keys(item).includes('type') &&
                          item['type'] !== null &&
                          (!searchSchema || item['schema'] === searchSchema) &&
                          (!searchVariant ||
                              item['variant'] === searchVariant) &&
                          (!searchRarity || item['rarity'] === searchRarity) &&
                          (!searchColor || item['color'] === searchColor) &&
                          (!searchAttr7 || item['attr7'] === searchAttr7) &&
                          (!searchAttr8 || item['attr8'] === searchAttr8) &&
                          (!searchAttr9 || item['attr9'] === searchAttr9) &&
                          (!searchAttr10 || item['attr10'] === searchAttr10) &&
                          (!searchBorder ||
                              !item['border'] ||
                              item['border'].toString() ===
                                  searchBorder.toString()),
                  ),
                  'All',
              )
            : null

    const filterDropDownOptions = []

    filterDropDownOptions.push({ value: '', label: 'Add Filter' })

    if (schemaDropDownOptions && !filters.includes('schema')) {
        filterDropDownOptions.push({
            value: 'schema',
            label: t('asset.schema'),
        })
    }

    if (variantDropDownOptions && !filters.includes('variant')) {
        filterDropDownOptions.push({
            value: 'variant',
            label: attrNames['variant'],
        })
    }

    if (rarityDropDownOptions && !filters.includes('rarity')) {
        filterDropDownOptions.push({
            value: 'rarity',
            label: attrNames['rarity'],
        })
    }

    if (colorDropDownOptions && !filters.includes('color')) {
        filterDropDownOptions.push({
            value: 'color',
            label: attrNames['color'],
        })
    }

    if (borderDropDownOptions && !filters.includes('border')) {
        filterDropDownOptions.push({
            value: 'border',
            label: attrNames['border'],
        })
    }

    if (typeDropDownOptions && !filters.includes('type')) {
        filterDropDownOptions.push({ value: 'type', label: attrNames['type'] })
    }

    if (attr7Options && !filters.includes('attr9')) {
        filterDropDownOptions.push({
            value: 'attr7',
            label: attrNames['attr7'],
        })
    }

    if (attr8Options && !filters.includes('attr8')) {
        filterDropDownOptions.push({
            value: 'attr8',
            label: attrNames['attr8'],
        })
    }

    if (attr9Options && !filters.includes('attr9')) {
        filterDropDownOptions.push({
            value: 'attr9',
            label: attrNames['attr9'],
        })
    }

    if (
        attr10Options &&
        !filters.includes('attr10') &&
        attrNames['attr10'] !== 'Video'
    ) {
        filterDropDownOptions.push({
            value: 'attr10',
            label: attrNames['attr10'],
        })
    }

    if (!filters.includes('owner')) {
        filterDropDownOptions.push({ value: 'owner', label: ownerTerm })
    }

    if (!filters.includes('name')) {
        filterDropDownOptions.push({ value: 'name', label: t('search.name') })
    }

    if (!filters.includes('number')) {
        filterDropDownOptions.push({
            value: 'number',
            label: attrNames['number'],
        })
    }

    if (!filters.includes('asset_id')) {
        filterDropDownOptions.push({ value: 'asset_id', label: 'Asset ID' })
    }

    if (!filters.includes('template_id')) {
        filterDropDownOptions.push({
            value: 'template_id',
            label: 'Template ID',
        })
    }

    if (!filters.includes('mint')) {
        filterDropDownOptions.push({ value: 'mint', label: t('asset.mint') })
    }

    if (!filters.includes('price')) {
        filterDropDownOptions.push({ value: 'price', label: t('asset.price') })
    }

    if (!filters.includes('favorites')) {
        filterDropDownOptions.push({
            value: 'favorites',
            label: t('search.favorites'),
        })
    }

    const applyPriceRange = (min, max) => {
        setVars({
            min_price: min,
            max_price: max,
            min_average: localMinAverage,
            max_average: localMaxAverage,
            min_mint: localMinMint,
            max_mint: localMaxMint,
            owner: localSearchOwner,
            offset: 0,
        })
    }

    const applyAverageRange = (min, max) => {
        setVars({
            min_price: localMinPrice,
            max_price: localMaxPrice,
            min_average: min,
            max_average: max,
            min_mint: localMinMint,
            max_mint: localMaxMint,
            owner: localSearchOwner,
            offset: 0,
        })
    }

    const applyMintRange = (min, max) => {
        setVars({
            min_price: localMinPrice,
            max_price: localMaxPrice,
            min_mint: min,
            max_mint: max,
            min_average: localMinAverage,
            max_average: localMaxAverage,
            owner: localSearchOwner,
            offset: 0,
        })
    }

    const applyOwnerChange = (val) => {
        setVars({
            min_price: localMinPrice,
            max_price: localMaxPrice,
            min_mint: localMinMint,
            max_mint: localMaxMint,
            min_average: localMinAverage,
            max_average: localMaxAverage,
            owner: val,
            offset: 0,
        })
    }

    const handleOwnerChange = (e) => {
        setLocalSearchOwner(e.target.value)
    }

    const handleUserChange = () => {
        if (userName) {
            setVars({
                min_price: localMinPrice,
                max_price: localMaxPrice,
                min_mint: localMinMint,
                max_mint: localMaxMint,
                min_average: localMinAverage,
                max_average: localMaxAverage,
                owner: userName,
                offset: 0,
            })
            setLocalSearchOwner(userName)
        }
    }

    const handleOwnerClear = () => {
        setVars({
            min_price: localMinPrice,
            max_price: localMaxPrice,
            min_mint: localMinMint,
            max_mint: localMaxMint,
            min_average: localMinAverage,
            max_average: localMaxAverage,
            owner: '',
            offset: 0,
        })
        setLocalSearchOwner('')
    }

    const onSelectCategory = (e) => {
        const value = parseInt(e.target.value) === 0 ? '' : e.target.value
        setVars({
            category: value,
            author: '',
            schema: '',
            rarity: '',
            variant: '',
            color: '',
            border: '',
            type: '',
            attr7: '',
            attr8: '',
            attr9: '',
            attr10: '',
            offset: 0,
        })
    }

    const onSelectSchema = (e) => {
        let variant = true
        let rarity = true
        let color = true
        let border = true
        let type = true
        let attr7 = true
        let attr8 = true
        let attr9 = true
        let attr10 = true

        if (authorSchemas) {
            rarity = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['schema'] === e.target.value,
                )
                .map((item) => item.rarity)
                .includes(searchRarity)
            variant = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['schema'] === e.target.value,
                )
                .map((item) => item.variant)
                .includes(searchVariant)
            color = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['schema'] === e.target.value,
                )
                .map((item) => item.color)
                .includes(searchColor)
            border = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['schema'] === e.target.value,
                )
                .map((item) => item.border && item.border.toString())
                .includes(searchBorder)
            type = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['schema'] === e.target.value,
                )
                .map((item) => item.type)
                .includes(searchType)
            attr7 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['schema'] === e.target.value,
                )
                .map((item) => item.attr7)
                .includes(searchAttr7)
            attr8 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['schema'] === e.target.value,
                )
                .map((item) => item.attr8)
                .includes(searchAttr8)
            attr9 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['schema'] === e.target.value,
                )
                .map((item) => item.attr9)
                .includes(searchAttr9)
            attr10 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['schema'] === e.target.value,
                )
                .map((item) => item.attr10)
                .includes(searchAttr10)
        }
        setVars({
            schema: e.target.value,
            rarity: rarity ? searchRarity : '',
            variant: variant ? searchVariant : '',
            color: color ? searchColor : '',
            border: border ? searchBorder : '',
            type: type ? searchType : '',
            attr7: attr7 ? searchAttr7 : '',
            attr8: attr8 ? searchAttr8 : '',
            attr9: attr9 ? searchAttr9 : '',
            attr10: attr10 ? searchAttr10 : '',
            offset: 0,
        })
    }

    const getOrder = (search, def) => {
        let order = def

        if (
            ['assets', 'duplicates', 'highest_duplicates', 'packs'].includes(
                search,
            )
        ) {
            order = 'date_desc'
        } else if (search === 'trades') {
            order = 'date_desc'
        } else if (search === 'templates') {
            order = 'template_id_desc'
        } else if (
            ['missing', 'cheapest', 'cheapest_missing'].includes(search)
        ) {
            order = 'offer_asc'
        } else if ('sales' === search) {
            order = 'date_desc'
        } else if (search === 'bundles') {
            order = 'date_desc'
        } else if (
            [
                'lowest_mints',
                'highest_mints',
                'bulk_transfer_lowest_mints',
            ].includes(search)
        ) {
            order = 'mint_asc'
        } else if (
            [
                'bulk_transfer',
                'bulk_swap_atomic',
                'bulk_swap_simple',
                'bulk_distribute',
                'bulk_cancel',
                'bulk_transfer_duplicates',
                'bulk_stake',
                'bulk_unstake',
                'bulk_sell',
                'bulk_auction',
                'bulk_multi_sell',
                'bulk_burn',
                'bulk_sell_duplicates',
                'bulk_sell_dupes',
                'bulk_bundle',
                'bulk_bundle_lowest_mints',
                'bulk_bundle_highest_mints',
                'bulk_bundle_highest_duplicates',
                'bulk_sell_highest_duplicates',
            ].includes(search)
        ) {
            order = 'date_desc'
        } else if (search === 'bulk_buy') {
            order = 'offer_asc'
        }

        return order
    }

    const onSelectSearch = (e) => {
        let order = getOrder(e.target.value, orderBy)
        setVars({
            search: e.target.value,
            order: order,
            owner: e.target.value !== 'bulk_buy' ? searchOwner : '',
            offset: 0,
            backed:
                searchBacked &&
                !['collection_sales', 'summaries', 'trades'].includes(
                    e.target.value,
                ),
        })
    }

    const onSelectContract = (e) => {
        setVars({
            contract: e.target.value,
            offset: 0,
        })
    }

    const onSelectAuthor = (e) => {
        let schema = true
        let variant = true
        let rarity = true
        let color = true
        let border = true
        let type = true
        let attr7 = true
        let attr8 = true
        let attr9 = true
        let attr10 = true

        let author = e
        if (e === searchAuthor) author = '*'

        if (authorSchemas) {
            schema = authorSchemas['schemas']
                .filter((item) => item['author'] === e)
                .map((item) => item.schema)
                .includes(searchSchema)
            rarity = authorSchemas['schemas']
                .filter((item) => item['author'] === e)
                .map((item) => item.rarity)
                .includes(searchRarity)
            variant = authorSchemas['schemas']
                .filter((item) => item['author'] === e)
                .map((item) => item.variant)
                .includes(searchVariant)
            color = authorSchemas['schemas']
                .filter((item) => item['author'] === e)
                .map((item) => item.color)
                .includes(searchColor)
            border = authorSchemas['schemas']
                .filter((item) => item['author'] === e)
                .map((item) => item.border && item.border.toString())
                .includes(searchBorder)
            type = authorSchemas['schemas']
                .filter((item) => item['author'] === e)
                .map((item) => item.type)
                .includes(searchType)
            attr7 = authorSchemas['schemas']
                .filter((item) => item['author'] === e)
                .map((item) => item.attr7)
                .includes(searchAttr7)
            attr8 = authorSchemas['schemas']
                .filter((item) => item['author'] === e)
                .map((item) => item.attr8)
                .includes(searchAttr8)
            attr9 = authorSchemas['schemas']
                .filter((item) => item['author'] === e)
                .map((item) => item.attr9)
                .includes(searchAttr9)
            attr10 = authorSchemas['schemas']
                .filter((item) => item['author'] === e)
                .map((item) => item.attr10)
                .includes(searchAttr10)
        }

        setVars({
            author: author,
            schema: schema ? searchSchema : '',
            category: null,
            attributes: '',
            rarity: rarity ? searchRarity : '',
            variant: variant ? searchVariant : '',
            color: color ? searchColor : '',
            border: border ? searchBorder : '',
            type: type ? searchType : '',
            attr7: attr7 ? searchAttr7 : '',
            attr8: attr8 ? searchAttr8 : '',
            attr9: attr9 ? searchAttr9 : '',
            attr10: attr10 ? searchAttr10 : '',
            offset: 0,
        })
    }

    const onCheckVerified = (e) => {
        setVars({
            verified: e,
            offset: 0,
        })
    }

    const onSelectRarity = (e) => {
        let variant = true
        let color = true
        let type = true
        let border = true
        let attr7 = true
        let attr8 = true
        let attr9 = true
        let attr10 = true
        if (authorSchemas) {
            color = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['rarity'] === e.target.value,
                )
                .map((item) => item.color)
                .includes(searchColor)
            variant = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['rarity'] === e.target.value,
                )
                .map((item) => item.variant)
                .includes(searchVariant)
            type = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['rarity'] === e.target.value,
                )
                .map((item) => item.type)
                .includes(searchType)
            border = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['rarity'] === e.target.value,
                )
                .map((item) => item.border && item.border.toString())
                .includes(searchBorder)
            attr7 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['rarity'] === e.target.value,
                )
                .map((item) => item.attr7)
                .includes(searchAttr7)
            attr8 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['rarity'] === e.target.value,
                )
                .map((item) => item.attr8)
                .includes(searchAttr8)
            attr9 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['rarity'] === e.target.value,
                )
                .map((item) => item.attr9)
                .includes(searchAttr9)
            attr10 =
                reviveCount ||
                reviveCount === 0 ||
                authorSchemas['schemas']
                    .filter(
                        (item) =>
                            !e.target.value ||
                            item['rarity'] === e.target.value,
                    )
                    .map((item) => item.attr10)
                    .includes(searchAttr10)
        }
        setVars({
            rarity: e.target.value,
            variant: variant ? searchVariant : '',
            color: color ? searchColor : '',
            border: border ? searchBorder : '',
            type: type ? searchType : '',
            attr7: attr7 ? searchAttr7 : '',
            attr8: attr8 ? searchAttr8 : '',
            attr9: attr9 ? searchAttr9 : '',
            attr10: attr10 ? searchAttr10 : '',
            offset: 0,
        })
    }

    const onSelectVariant = (e) => {
        let color = true
        let rarity = true
        let type = true
        let border = true
        let attr7 = true
        let attr8 = true
        let attr9 = true
        let attr10 = true
        if (authorSchemas) {
            rarity = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['variant'] === e.target.value,
                )
                .map((item) => item.rarity)
                .includes(searchRarity)
            color = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['variant'] === e.target.value,
                )
                .map((item) => item.color)
                .includes(searchColor)
            type = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['variant'] === e.target.value,
                )
                .map((item) => item.type)
                .includes(searchType)
            border = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['variant'] === e.target.value,
                )
                .map((item) => item.border && item.border.toString())
                .includes(searchBorder)
            attr7 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['variant'] === e.target.value,
                )
                .map((item) => item.attr7)
                .includes(searchAttr7)
            attr8 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['variant'] === e.target.value,
                )
                .map((item) => item.attr8)
                .includes(searchAttr8)
            attr9 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['variant'] === e.target.value,
                )
                .map((item) => item.attr9)
                .includes(searchAttr9)
            attr10 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['variant'] === e.target.value,
                )
                .map((item) => item.attr10)
                .includes(searchAttr10)
        }
        setVars({
            rarity: rarity ? searchRarity : '',
            variant: e.target.value,
            color: color ? searchColor : '',
            border: border ? searchBorder : '',
            type: type ? searchType : '',
            attr7: attr7 ? searchAttr7 : '',
            attr8: attr8 ? searchAttr8 : '',
            attr9: attr9 ? searchAttr9 : '',
            attr10: attr10 ? searchAttr10 : '',
            offset: 0,
        })
    }

    const onSelectColor = (e) => {
        let variant = true
        let rarity = true
        let type = true
        let border = true
        let attr7 = true
        let attr8 = true
        let attr9 = true
        let attr10 = true
        if (authorSchemas) {
            rarity = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['color'] === e.target.value,
                )
                .map((item) => item.rarity)
                .includes(searchRarity)
            variant = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['color'] === e.target.value,
                )
                .map((item) => item.variant)
                .includes(searchVariant)
            type = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['color'] === e.target.value,
                )
                .map((item) => item.type)
                .includes(searchType)
            border = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['color'] === e.target.value,
                )
                .map((item) => item.border && item.border.toString())
                .includes(searchBorder)
            attr7 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['color'] === e.target.value,
                )
                .map((item) => item.attr7)
                .includes(searchAttr7)
            attr8 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['color'] === e.target.value,
                )
                .map((item) => item.attr8)
                .includes(searchAttr8)
            attr9 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['color'] === e.target.value,
                )
                .map((item) => item.attr9)
                .includes(searchAttr9)
            attr10 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['color'] === e.target.value,
                )
                .map((item) => item.attr10)
                .includes(searchAttr10)
        }
        setVars({
            rarity: rarity ? searchRarity : '',
            variant: variant ? searchVariant : '',
            color: e.target.value,
            border: border ? searchBorder : '',
            type: type ? searchType : '',
            attr7: attr7 ? searchAttr7 : '',
            attr8: attr8 ? searchAttr8 : '',
            attr9: attr9 ? searchAttr9 : '',
            attr10: attr10 ? searchAttr10 : '',
            offset: 0,
        })
    }

    const onSelectAttr7 = (e) => {
        let variant = true
        let rarity = true
        let type = true
        let border = true
        let color = true
        let attr8 = true
        let attr9 = true
        let attr10 = true
        if (authorSchemas) {
            rarity = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr7'] === e.target.value,
                )
                .map((item) => item.rarity)
                .includes(searchRarity)
            variant = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr7'] === e.target.value,
                )
                .map((item) => item.variant)
                .includes(searchVariant)
            type = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr7'] === e.target.value,
                )
                .map((item) => item.type)
                .includes(searchType)
            border = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr7'] === e.target.value,
                )
                .map((item) => item.border && item.border.toString())
                .includes(searchBorder)
            color = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr7'] === e.target.value,
                )
                .map((item) => item.color)
                .includes(searchColor)
            attr8 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr7'] === e.target.value,
                )
                .map((item) => item.attr8)
                .includes(searchAttr8)
            attr9 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr7'] === e.target.value,
                )
                .map((item) => item.attr9)
                .includes(searchAttr9)
            attr10 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr7'] === e.target.value,
                )
                .map((item) => item.attr10)
                .includes(searchAttr10)
        }
        setVars({
            rarity: rarity ? searchRarity : '',
            variant: variant ? searchVariant : '',
            color: color ? searchColor : '',
            border: border ? searchBorder : '',
            type: type ? searchType : '',
            attr7: e.target.value,
            attr8: attr8 ? searchAttr8 : '',
            attr9: attr9 ? searchAttr9 : '',
            attr10: attr10 ? searchAttr10 : '',
            offset: 0,
        })
    }

    const onSelectAttr8 = (e) => {
        let variant = true
        let rarity = true
        let type = true
        let border = true
        let color = true
        let attr7 = true
        let attr9 = true
        let attr10 = true
        if (authorSchemas) {
            rarity = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr8'] === e.target.value,
                )
                .map((item) => item.rarity)
                .includes(searchRarity)
            variant = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr8'] === e.target.value,
                )
                .map((item) => item.variant)
                .includes(searchVariant)
            type = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr8'] === e.target.value,
                )
                .map((item) => item.type)
                .includes(searchType)
            border = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr8'] === e.target.value,
                )
                .map((item) => item.border && item.border.toString())
                .includes(searchBorder)
            color = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr8'] === e.target.value,
                )
                .map((item) => item.color)
                .includes(searchColor)
            attr7 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr8'] === e.target.value,
                )
                .map((item) => item.attr7)
                .includes(searchAttr7)
            attr9 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr8'] === e.target.value,
                )
                .map((item) => item.attr9)
                .includes(searchAttr9)
            attr10 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr8'] === e.target.value,
                )
                .map((item) => item.attr10)
                .includes(searchAttr10)
        }
        setVars({
            rarity: rarity ? searchRarity : '',
            variant: variant ? searchVariant : '',
            color: color ? searchColor : '',
            border: border ? searchBorder : '',
            type: type ? searchType : '',
            attr7: attr7 ? searchAttr7 : '',
            attr8: e.target.value,
            attr9: attr9 ? searchAttr9 : '',
            attr10: attr10 ? searchAttr10 : '',
            offset: 0,
        })
    }

    const onSelectAttr9 = (e) => {
        let variant = true
        let rarity = true
        let type = true
        let border = true
        let color = true
        let attr7 = true
        let attr8 = true
        let attr10 = true
        if (authorSchemas) {
            rarity = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr9'] === e.target.value,
                )
                .map((item) => item.rarity)
                .includes(searchRarity)
            variant = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr9'] === e.target.value,
                )
                .map((item) => item.variant)
                .includes(searchVariant)
            type = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr9'] === e.target.value,
                )
                .map((item) => item.type)
                .includes(searchType)
            border = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr9'] === e.target.value,
                )
                .map((item) => item.border && item.border.toString())
                .includes(searchBorder)
            color = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr9'] === e.target.value,
                )
                .map((item) => item.color)
                .includes(searchColor)
            attr7 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr9'] === e.target.value,
                )
                .map((item) => item.attr7)
                .includes(searchAttr7)
            attr8 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr9'] === e.target.value,
                )
                .map((item) => item.attr8)
                .includes(searchAttr8)
            attr10 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr9'] === e.target.value,
                )
                .map((item) => item.attr10)
                .includes(searchAttr10)
        }
        setVars({
            rarity: rarity ? searchRarity : '',
            variant: variant ? searchVariant : '',
            color: color ? searchColor : '',
            border: border ? searchBorder : '',
            type: type ? searchType : '',
            attr7: attr7 ? searchAttr7 : '',
            attr8: attr8 ? searchAttr8 : '',
            attr9: e.target.value,
            attr10: attr10 ? searchAttr10 : '',
            offset: 0,
        })
    }

    const onSelectAttr10 = (e) => {
        let variant = true
        let rarity = true
        let type = true
        let border = true
        let color = true
        let attr7 = true
        let attr8 = true
        let attr9 = true
        if (authorSchemas && !reviveCount && reviveCount !== 0) {
            rarity = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr10'] === e.target.value,
                )
                .map((item) => item.rarity)
                .includes(searchRarity)
            variant = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr10'] === e.target.value,
                )
                .map((item) => item.variant)
                .includes(searchVariant)
            type = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr10'] === e.target.value,
                )
                .map((item) => item.type)
                .includes(searchType)
            border = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr10'] === e.target.value,
                )
                .map((item) => item.border && item.border.toString())
                .includes(searchBorder)
            color = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr10'] === e.target.value,
                )
                .map((item) => item.color)
                .includes(searchColor)
            attr7 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr10'] === e.target.value,
                )
                .map((item) => item.attr7)
                .includes(searchAttr7)
            attr8 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr10'] === e.target.value,
                )
                .map((item) => item.attr8)
                .includes(searchAttr8)
            attr9 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['attr10'] === e.target.value,
                )
                .map((item) => item.attr9)
                .includes(searchAttr9)
        }
        setVars({
            rarity: rarity ? searchRarity : '',
            variant: variant ? searchVariant : '',
            color: color ? searchColor : '',
            border: border ? searchBorder : '',
            type: type ? searchType : '',
            attr7: attr7 ? searchAttr7 : '',
            attr8: attr8 ? searchAttr8 : '',
            attr9: attr9 ? searchAttr9 : '',
            attr10: e.target.value,
            offset: 0,
        })
    }

    const onSelectBorder = (e) => {
        let variant = true
        let rarity = true
        let color = true
        let type = true
        let attr7 = true
        let attr8 = true
        let attr9 = true
        let attr10 = true
        if (authorSchemas) {
            rarity = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value ||
                        (item['border'] &&
                            item['border'].toString() === e.target.value),
                )
                .map((item) => item.rarity)
                .includes(searchRarity)
            variant = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value ||
                        (item['border'] &&
                            item['border'].toString() === e.target.value),
                )
                .map((item) => item.variant)
                .includes(searchVariant)
            color = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value ||
                        (item['border'] &&
                            item['border'].toString() === e.target.value),
                )
                .map((item) => item.color)
                .includes(searchColor)
            type = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value ||
                        (item['border'] &&
                            item['border'].toString() === e.target.value),
                )
                .map((item) => item.type)
                .includes(searchType)
            attr7 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value ||
                        (item['border'] &&
                            item['border'].toString() === e.target.value),
                )
                .map((item) => item.attr7)
                .includes(searchAttr7)
            attr8 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value ||
                        (item['border'] &&
                            item['border'].toString() === e.target.value),
                )
                .map((item) => item.attr8)
                .includes(searchAttr8)
            attr9 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value ||
                        (item['border'] &&
                            item['border'].toString() === e.target.value),
                )
                .map((item) => item.attr9)
                .includes(searchAttr9)
            attr10 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value ||
                        (item['border'] &&
                            item['border'].toString() === e.target.value),
                )
                .map((item) => item.attr10)
                .includes(searchAttr10)
        }
        setVars({
            rarity: rarity ? searchRarity : '',
            variant: variant ? searchVariant : '',
            color: color ? searchColor : '',
            border: e.target.value,
            type: type ? searchType : '',
            attr7: attr7 ? searchAttr7 : '',
            attr8: attr8 ? searchAttr8 : '',
            attr9: attr9 ? searchAttr9 : '',
            attr10: attr10 ? searchAttr10 : '',
            offset: 0,
        })
    }

    const onSelectType = (e) => {
        let variant = true
        let rarity = true
        let color = true
        let border = true
        let attr7 = true
        let attr8 = true
        let attr9 = true
        let attr10 = true
        if (authorSchemas) {
            rarity = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['type'] === e.target.value,
                )
                .map((item) => item.rarity)
                .includes(searchRarity)
            variant = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['type'] === e.target.value,
                )
                .map((item) => item.variant)
                .includes(searchVariant)
            color = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['type'] === e.target.value,
                )
                .map((item) => item.color)
                .includes(searchColor)
            border = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['type'] === e.target.value,
                )
                .map((item) => item.border && item.border.toString())
                .includes(searchBorder)
            attr7 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['type'] === e.target.value,
                )
                .map((item) => item.attr7)
                .includes(searchAttr7)
            attr8 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['type'] === e.target.value,
                )
                .map((item) => item.attr8)
                .includes(searchAttr8)
            attr9 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['type'] === e.target.value,
                )
                .map((item) => item.attr9)
                .includes(searchAttr9)
            attr10 = authorSchemas['schemas']
                .filter(
                    (item) =>
                        !e.target.value || item['type'] === e.target.value,
                )
                .map((item) => item.attr10)
                .includes(searchAttr10)
        }
        setVars({
            rarity: rarity ? searchRarity : '',
            variant: variant ? searchVariant : '',
            color: color ? searchColor : '',
            border: border ? searchBorder : '',
            type: e.target.value,
            attr7: attr7 ? searchAttr7 : '',
            attr8: attr8 ? searchAttr8 : '',
            attr9: attr9 ? searchAttr9 : '',
            attr10: attr10 ? searchAttr10 : '',
            offset: 0,
        })
    }

    const onCheckFavorites = () => {
        setVars({
            favorites: !searchFavorites,
            offset: 0,
        })
    }

    const onCheckPFPs = () => {
        setVars({
            pfps_only: !searchPFPs,
            offset: 0,
        })
    }

    const onCheckUnlisted = () => {
        setVars({
            unlisted_only: !searchUnlisted,
            offset: 0,
        })
    }

    const onSelectLimit = (e) => {
        setVars({
            limit: e.target.value,
            offset: 0,
        })
    }

    const onCheckBacked = () => {
        setVars({
            backed: !searchBacked,
            offset: 0,
        })
    }

    const onSelectRecentlySold = (e) => {
        setVars({
            recent: e.target.value,
            offset: 0,
        })
    }

    const getSearchOption = (search) => {
        return searchDropDownOptions.map((item) => item.value).indexOf(search)
    }

    const defaultSearchOption = searchDropDownOptions
        ? searchDropDownOptions[getSearchOption(searchSearch)]
        : null

    const initToggleItems = () => {
        const map = new Map()
        // Open Collections per defautlt
        map.set(
            ['assets', 'inventory'].includes(searchSearch)
                ? 'Asset Type'
                : searchSearch.includes('bulk')
                ? 'Bulk Operation'
                : searchSearch === 'auctions'
                ? 'Auction Type'
                : targetTab === 'pfp'
                ? 'PFP Search Type'
                : 'Market Filter',
            true,
        )
        map.set(t('navigation.collections'), true)
        map.set(t('search.schema'), true)
        map.set(t('search.category'), searchCategory)
        map.set(
            attrNames['rarity'],
            searchRarity || searchSearch === 'collection_sales',
        )
        map.set(t('search.last_sold_within'), searchRecentlySold)
        map.set(attrNames['variant'], searchVariant)
        map.set(attrNames['type'], searchType)
        map.set(attrNames['border'], searchBorder || searchBorder === 0)
        map.set(attrNames['color'], searchColor)
        map.set(attrNames['attr7'], searchAttr7)
        map.set(attrNames['attr8'], searchAttr8)
        map.set(attrNames['attr9'], searchAttr9)
        map.set(attrNames['attr10'], searchAttr10)
        map.set(ownerTerm, searchOwner)
        map.set('Price', minPrice || maxPrice)
        map.set('Average', minAverage || maxAverage)
        map.set('Mint', minMint || maxMint)
        map.set('Favorites', searchFavorites)
        map.set('Backed Tokens', searchBacked)
        return map
    }

    const [toggleItem, setToggleItem] = useState(true)
    useEffect(() => {
        setToggleItem(initToggleItems())
    }, [show])

    const onSelectAttribute = async (e, attr) => {
        const attrBreakDown = {}

        const oldAttrStr = unescape(attributes)

        for (const attribute of oldAttrStr.split(',')) {
            if (attribute.includes(':')) {
                attrBreakDown[attribute.split(':')[0]] = escape(
                    attribute.split(':')[1],
                )
            }
        }

        if (e.target.value) {
            attrBreakDown[attr] = escape(e.target.value)
        } else {
            delete attrBreakDown[attr]
        }

        let attrStr = ''
        for (const key of Object.keys(attrBreakDown)) {
            attrStr += key + ':' + attrBreakDown[key] + ','
        }

        if (attrStr.length > 0) {
            attrStr = attrStr.substr(0, attrStr.length - 1)
        }

        setVars({
            attributes: attrStr,
            offset: 0,
        })
    }

    const getAttributeDefaultOption = (attr) => {
        if (attributes.includes(attr)) {
            const attrBreakDown = {}

            const oldAttrStr = unescape(attributes)

            for (const attribute of oldAttrStr.split(',')) {
                if (attribute.includes(':')) {
                    attrBreakDown[attribute.split(':')[0]] = escape(
                        attribute.split(':')[1],
                    )
                }
            }

            if (Object.keys(attrBreakDown).includes(attr)) {
                return unescape(attrBreakDown[attr])
            } else {
                return null
            }
        }

        return null
    }

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [screenWidth])

    const filterButton = (
        <Button
            size="sm"
            className={cn(
                '!w-[56px] h-[56px] border-2 mr-2',
                buttonSearchCommonStyles,
            )}
            variant="bordered"
            onClick={toggleDrawer}
        >
            <FilterListIcon />
        </Button>
    )
    return (
        <>
            <Tooltip
                showArrow={true}
                key="right"
                placement="right"
                content="Show Filters"
                color="foreground"
                delay={0}
                closeDelay={0}
            >
                {filterButton}
            </Tooltip>

            <Drawer
                lockBackgroundScroll={true}
                open={isOpen}
                onClose={toggleDrawer}
                direction="left"
                className="min-w-[330px] dark:!bg-zinc-900 bg-white p-4 overflow-y-auto !h-[calc(100vh-88px)]"
            >
                <div className="flex items-center justify-between">
                    <p className="text-lg font-semibold text-black dark:text-white">
                        Filters
                    </p>

                    <Tooltip
                        showArrow={true}
                        key="right"
                        placement="right"
                        content="Close Filters"
                        color="foreground"
                        delay={0}
                        closeDelay={0}
                    >
                        <Button
                            isIconOnly
                            aria-label="Like"
                            onClick={toggleDrawer}
                            className={cn(
                                'h-[48px] w-[48px] border-2',
                                buttonSearchCommonStyles,
                            )}
                        >
                            <CloseIcon />
                        </Button>
                    </Tooltip>
                </div>

                <div className={'w-full'}>
                    <List dense>
                        <Divider />
                        <div className="mb-4" />
                        {searchDropDownOptions && (
                            /* Market Filter */
                            <div className="mx-1 my-3">
                                <Select
                                    scrollShadowProps={{ hideScrollBar: false }}
                                    variant="faded"
                                    label={
                                        ['assets', 'inventory'].includes(
                                            searchSearch,
                                        )
                                            ? 'Asset Type'
                                            : searchSearch.includes('bulk')
                                            ? 'Bulk Operation'
                                            : searchSearch === 'auctions'
                                            ? 'Auction Type'
                                            : targetTab === 'pfp'
                                            ? 'PFP Search Type'
                                            : 'Market Filter'
                                    }
                                    className="w-full"
                                    classNames={{
                                        ...selectStyles,
                                    }}
                                    disabled={
                                        (searchSettings &&
                                            searchSettings['search_type'] &&
                                            searchSettings['search_type'][
                                                'fixed'
                                            ]) ||
                                        isLoading
                                            ? 'disabled'
                                            : ''
                                    }
                                    onChange={onSelectSearch}
                                    value={
                                        defaultSearchOption &&
                                        defaultSearchOption.value
                                            ? defaultSearchOption.value
                                            : ''
                                    }
                                >
                                    {searchDropDownOptions.map((option) => (
                                        <SelectItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        )}
                        {displayCollectionSelector && (
                            <Accordion
                                isCompact={false}
                                disableAnimation={false}
                                disableIndicatorAnimation={false}
                                hideIndicator={false}
                                isDisabled={false}
                                defaultExpandedKeys={['CollectionItem']}
                            >
                                <AccordionItem
                                    classNames={{
                                        title: 'dark:dark:text-white !text-base !md:text-xs !xl:text-base font-semibold',
                                    }}
                                    key={'CollectionItem'}
                                    title={t('navigation.collections')}
                                    toggleItem={toggleItem}
                                    setToggleItem={setToggleItem}
                                >
                                    <CollectionSelector
                                        searchAuthor={searchAuthor}
                                        searchVerified={searchVerified}
                                        selectAuthor={onSelectAuthor}
                                        onCheckVerified={onCheckVerified}
                                        searchSearch={searchSearch}
                                        searchItem={true}
                                        owner={
                                            bulk &&
                                            userName &&
                                            searchSearch !== 'bulk_buy'
                                                ? userName
                                                : searchOwner
                                        }
                                        searchType={searchSearch}
                                        searchPFPs={searchPFPs}
                                    />
                                </AccordionItem>
                            </Accordion>
                        )}
                        {schemaDropDownOptions && (
                            <div className="mx-1 my-3">
                                <Select
                                    scrollShadowProps={{ hideScrollBar: false }}
                                    id="DropdownField3"
                                    variant="faded"
                                    label={t('search.schema')}
                                    className="w-full"
                                    classNames={{
                                        ...selectStyles,
                                    }}
                                    onChange={onSelectSchema}
                                    disabled={
                                        searchSearch === 'bulk_burn' ||
                                        !schemaDropDownOptions ||
                                        isLoading ||
                                        !searchAuthor
                                    }
                                >
                                    {schemaDropDownOptions.map((option) => (
                                        <SelectItem
                                            value={option.value}
                                            key={option.value}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        )}
                        {pfpAttributes && Object.keys(pfpAttributes).length > 0
                            ? Object.keys(pfpAttributes).map((attr, index) => (
                                  <div className="mx-1 my-3">
                                      <FormControl fullWidth size="small">
                                          {/*  <InputLabel
                                              className={
                                                  'text-base md:text-xs xl:text-base'
                                              }
                                          >
                                              {attr}
                                          </InputLabel> */}
                                          <Select
                                              onChange={(e) =>
                                                  onSelectAttribute(e, attr)
                                              }
                                              value={getAttributeDefaultOption(
                                                  attr,
                                              )}
                                              id={'DropdownFieldAttr' + index}
                                              label={attr}
                                              disabled={
                                                  searchSearch ===
                                                      'bulk_burn' ||
                                                  !schemaDropDownOptions ||
                                                  isLoading ||
                                                  !searchAuthor
                                              }
                                              variant="faded"
                                              classNames={{
                                                  ...selectStyles,
                                              }}
                                          >
                                              {pfpAttributes[attr].map(
                                                  (option) => (
                                                      <SelectItem
                                                          key={option}
                                                          value={option}
                                                      >
                                                          {option
                                                              ? option
                                                              : ' - '}
                                                      </SelectItem>
                                                  ),
                                              )}
                                          </Select>
                                      </FormControl>
                                  </div>
                              ))
                            : ''}
                        {displayCollectionSelector &&
                            categoryDropDownOptions &&
                            (!searchAuthor || searchAuthor === '*') && (
                                <div className="mx-1 my-3">
                                    <Select
                                        scrollShadowProps={{
                                            hideScrollBar: false,
                                        }}
                                        id="DropdownField14"
                                        variant="faded"
                                        disabled={
                                            !categoryDropDownOptions ||
                                            isLoading
                                        }
                                        label={t('search.category')}
                                        className="w-full"
                                        classNames={{
                                            ...selectStyles,
                                        }}
                                        onChange={onSelectCategory}
                                    >
                                        <SelectItem value={0} key={0}>
                                            All
                                        </SelectItem>
                                        {categoryDropDownOptions.map(
                                            (option) => (
                                                <SelectItem
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </SelectItem>
                                            ),
                                        )}
                                    </Select>
                                </div>
                            )}
                        {searchSearch !== 'collection_sales' && (
                            <div className="mx-1 my-3">
                                <Select
                                    scrollShadowProps={{ hideScrollBar: false }}
                                    id="DropdownField9"
                                    variant="faded"
                                    label={t('search.last_sold_within')}
                                    className="w-full"
                                    classNames={{
                                        ...selectStyles,
                                    }}
                                    onChange={onSelectRecentlySold}
                                    disabled={isLoading}
                                >
                                    {recentDropDownOptions.map((option) => (
                                        <SelectItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        )}
                        {rarityDropDownOptions && (
                            <div className="mx-1 my-3">
                                <Select
                                    scrollShadowProps={{ hideScrollBar: false }}
                                    id="DropdownField5"
                                    variant="faded"
                                    label={attrNames['rarity']}
                                    className="w-full"
                                    classNames={{
                                        ...selectStyles,
                                    }}
                                    onChange={onSelectRarity}
                                    disabled={
                                        isPackSearch ||
                                        searchSearch === 'bulk_burn' ||
                                        !rarityDropDownOptions ||
                                        isLoading ||
                                        !searchAuthor
                                    }
                                >
                                    {rarityDropDownOptions.map((option) => (
                                        <SelectItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        )}
                        {variantDropDownOptions && (
                            <div className="mx-1 my-3">
                                <Select
                                    scrollShadowProps={{ hideScrollBar: false }}
                                    id="DropdownField4"
                                    variant="faded"
                                    label={attrNames['variant']}
                                    className="w-full"
                                    classNames={{
                                        ...selectStyles,
                                    }}
                                    onChange={onSelectVariant}
                                    disabled={
                                        isPackSearch ||
                                        !variantDropDownOptions ||
                                        isLoading ||
                                        !searchAuthor
                                    }
                                >
                                    {variantDropDownOptions.map((option) => (
                                        <SelectItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        )}
                        {typeDropDownOptions && (
                            <div className="mx-1 my-3">
                                <Select
                                    scrollShadowProps={{ hideScrollBar: false }}
                                    id="DropdownField7"
                                    variant="faded"
                                    label={attrNames['type']}
                                    className="w-full"
                                    classNames={{
                                        ...selectStyles,
                                    }}
                                    onChange={onSelectType}
                                    disabled={
                                        isPackSearch ||
                                        !typeDropDownOptions ||
                                        isLoading ||
                                        !searchAuthor
                                    }
                                >
                                    {typeDropDownOptions.map((option) => (
                                        <SelectItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        )}
                        {borderDropDownOptions && (
                            <div className="mx-1 my-3">
                                <Select
                                    scrollShadowProps={{ hideScrollBar: false }}
                                    id="DropdownField8"
                                    variant="faded"
                                    label={attrNames['border']}
                                    className="w-full"
                                    classNames={{
                                        ...selectStyles,
                                    }}
                                    onChange={onSelectBorder}
                                    disabled={
                                        isPackSearch ||
                                        !borderDropDownOptions ||
                                        isLoading ||
                                        !searchAuthor
                                    }
                                >
                                    {borderDropDownOptions.map((option) => (
                                        <SelectItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        )}
                        {colorDropDownOptions && (
                            <div className="mx-1 my-3">
                                <Select
                                    scrollShadowProps={{ hideScrollBar: false }}
                                    id="DropdownField9"
                                    variant="faded"
                                    label={attrNames['color']}
                                    className="w-full"
                                    classNames={{
                                        ...selectStyles,
                                    }}
                                    onChange={onSelectColor}
                                    disabled={
                                        isPackSearch ||
                                        !colorDropDownOptions ||
                                        isLoading ||
                                        !searchAuthor
                                    }
                                >
                                    {colorDropDownOptions.map((option) => (
                                        <SelectItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        )}
                        {attr7Options && (
                            <div className="mx-1 my-3">
                                <Select
                                    scrollShadowProps={{ hideScrollBar: false }}
                                    id="DropdownField10"
                                    variant="faded"
                                    label={attrNames['attr7']}
                                    className="w-full"
                                    classNames={{
                                        ...selectStyles,
                                    }}
                                    onChange={onSelectAttr7}
                                    disabled={
                                        isPackSearch ||
                                        !attr7Options ||
                                        isLoading ||
                                        !searchAuthor
                                    }
                                >
                                    {attr7Options.map((option) => (
                                        <SelectItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        )}
                        {attr8Options && (
                            <div className="mx-1 my-3">
                                <Select
                                    scrollShadowProps={{ hideScrollBar: false }}
                                    id="DropdownField11"
                                    variant="faded"
                                    label={attrNames['attr8']}
                                    className="w-full"
                                    classNames={{
                                        ...selectStyles,
                                    }}
                                    onChange={onSelectAttr8}
                                    disabled={
                                        isPackSearch ||
                                        !attr8Options ||
                                        isLoading ||
                                        !searchAuthor
                                    }
                                >
                                    {attr8Options.map((option) => (
                                        <SelectItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        )}
                        {attr9Options && (
                            <div className="mx-1 my-3">
                                <Select
                                    scrollShadowProps={{ hideScrollBar: false }}
                                    id="DropdownField12"
                                    variant="faded"
                                    label={attrNames['attr9']}
                                    className="w-full"
                                    classNames={{
                                        ...selectStyles,
                                    }}
                                    onChange={onSelectAttr9}
                                    disabled={
                                        isPackSearch ||
                                        !attr9Options ||
                                        isLoading ||
                                        !searchAuthor
                                    }
                                >
                                    {attr9Options.map((option) => (
                                        <SelectItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        )}
                        {attr10Options && (
                            <div className="mx-1 my-3">
                                <Select
                                    scrollShadowProps={{ hideScrollBar: false }}
                                    id="DropdownField13"
                                    variant="faded"
                                    label={attrNames['attr10']}
                                    className="w-full"
                                    classNames={{
                                        ...selectStyles,
                                    }}
                                    onChange={onSelectAttr10}
                                    disabled={
                                        isPackSearch ||
                                        !attr10Options ||
                                        isLoading ||
                                        !searchAuthor
                                    }
                                >
                                    {attr10Options.map((option) => (
                                        <SelectItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        )}
                        {searchAuthor === 'cosmoseleven' &&
                        searchSchema === 'players' ? (
                            <div className="mx-1 my-3">
                                <Input
                                    fullWidth
                                    size="md"
                                    variant="outlined"
                                    label={'Revive Count'}
                                    onChange={changeReviveCount}
                                    onWheel={(e) => e.target.blur()}
                                    value={reviveCount}
                                    disabled={isLoading || !searchAuthor}
                                    type="number"
                                    // endContent={
                                    //     <SearchIcon className="mb-2 pointer-events-none text-zinc-400 text-md" />
                                    // }
                                />
                            </div>
                        ) : (
                            ''
                        )}
                        {['staked', 'bulk_unstake'].includes(searchSearch) && (
                            /* Market Filter */
                            <div className="mx-1 my-3">
                                <FormControl fullWidth size="small">
                                    {/*    <InputLabel
                                        className={
                                            'text-base md:text-xs xl:text-base'
                                        }
                                    >
                                        Staking Contract
                                    </InputLabel> */}
                                    <Select
                                        onChange={onSelectContract}
                                        value={
                                            defaultContractOption &&
                                            defaultContractOption.value
                                                ? defaultContractOption.value
                                                : ''
                                        }
                                        id="DropdownField22"
                                        label={'Staking Contract'}
                                        disabled={isLoading ? 'disabled' : ''}
                                        variant="faded"
                                        classNames={{
                                            ...selectStyles,
                                        }}
                                    >
                                        {contractDropdownOptions.map(
                                            (option) => (
                                                <SelectItem
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </SelectItem>
                                            ),
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        {searchSearch.includes('bulk_') && (
                            /* Limit Filter */
                            <div className="mx-1 my-3">
                                <FormControl fullWidth size="small">
                                    {/*   <InputLabel
                                        className={
                                            'text-base md:text-xs xl:text-base'
                                        }
                                    >
                                        {t('search.number_of_results')}
                                    </InputLabel> */}
                                    <Select
                                        variant="faded"
                                        onChange={onSelectLimit}
                                        value={
                                            defaultLimitOption &&
                                            defaultLimitOption.value
                                                ? defaultLimitOption.value
                                                : ''
                                        }
                                        id="DropdownField22"
                                        label={t('search.number_of_results')}
                                        disabled={isLoading ? 'disabled' : ''}
                                        classNames={{
                                            ...selectStyles,
                                        }}
                                    >
                                        {limitDropdownOptions.map((option) => (
                                            <SelectItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        {!ownerFixed &&
                        ![
                            'summaries',
                            'collection_sales',
                            'templates',
                        ].includes(searchSearch) ? (
                            <div className="mx-1 my-3">
                                <InputSearch
                                    applyTerm={applyOwnerChange}
                                    handleTermChange={handleOwnerChange}
                                    handleOwnerClear={handleOwnerClear}
                                    value={
                                        localSearchOwner ? localSearchOwner : ''
                                    }
                                    disabled={
                                        isPackSearch ||
                                        isLoading ||
                                        (searchSettings &&
                                            searchSettings['owner'] &&
                                            searchSettings['owner']['fixed'])
                                    }
                                    userChangeEnabled={
                                        userName &&
                                        userName !== searchOwner &&
                                        !(isPackSearch || isLoading) &&
                                        !(
                                            searchSettings['owner'] &&
                                            searchSettings['owner']['fixed']
                                        )
                                    }
                                    handleUserChange={handleUserChange}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                        {searchSearch !== 'collection_sales' &&
                            searchSearch !== 'summaries' &&
                            [
                                'sales',
                                'missing',
                                'below_last_sold',
                                'owned_lower_mints',
                                'cheapest_missing',
                                'bundles',
                                'trades',
                                'cheapest',
                                'packs',
                                'ft_packs',
                                'bulk_buy',
                            ].includes(searchSearch) && (
                                <RangeField
                                    disabled={isLoading ? 'disabled' : ''}
                                    minVal={localMinPrice}
                                    maxVal={localMaxPrice}
                                    setMin={setLocalMinPrice}
                                    setMax={setLocalMaxPrice}
                                    applyRange={applyPriceRange}
                                    useFloat={true}
                                    name={t('search.price')}
                                />
                            )}
                        {searchSearch !== 'collection_sales' &&
                            searchSearch !== 'summaries' &&
                            false && (
                                <RangeField
                                    disabled={isLoading ? 'disabled' : ''}
                                    minVal={localMinAverage}
                                    maxVal={localMaxAverage}
                                    setMin={setLocalMinAverage}
                                    setMax={setLocalMaxAverage}
                                    applyRange={applyAverageRange}
                                    useFloat={true}
                                    name={t('search.median')}
                                />
                            )}
                        {searchSearch !== 'collection_sales' &&
                            searchSearch !== 'summaries' &&
                            ![
                                'bundles',
                                'ft_packs',
                                'collection_sales',
                                'templates',
                            ].includes(searchSearch) && (
                                <RangeField
                                    disabled={
                                        isPackSearch || isLoading
                                            ? 'disabled'
                                            : ''
                                    }
                                    minVal={localMinMint}
                                    maxVal={localMaxMint}
                                    applyRange={applyMintRange}
                                    setMin={setLocalMinMint}
                                    setMax={setLocalMaxMint}
                                    useFloat={false}
                                    name={t('search.mint')}
                                />
                            )}
                        {searchSearch !== 'collection_sales' &&
                            searchSearch !== 'summaries' &&
                            false && (
                                <div
                                    className={{
                                        hidden: !userName || isPackSearch,
                                    }}
                                >
                                    <div className={cn('flex justify-center')}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={searchFavorites}
                                                    onChange={onCheckFavorites}
                                                />
                                            }
                                            label="Filter to Favorites"
                                            labelPlacement="start"
                                        />
                                    </div>
                                </div>
                            )}
                        {searchSearch === 'inventory' && (
                            <div
                                className={cn('mt-6', {
                                    hidden: !userName || isPackSearch,
                                })}
                            >
                                <div
                                    className={cn(
                                        'flex justify-center overflow-x-hidden',
                                    )}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={searchPFPs}
                                                onChange={onCheckPFPs}
                                            />
                                        }
                                        label="Only PFPs with Traits"
                                        labelPlacement="top"
                                    />
                                </div>
                            </div>
                        )}
                        {searchSearch === 'inventory' && (
                            <div
                                className={cn('mt-6', {
                                    hidden: !userName || isPackSearch,
                                })}
                            >
                                <div
                                    className={cn(
                                        'flex justify-center overflow-x-hidden',
                                    )}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={searchUnlisted}
                                                onChange={onCheckUnlisted}
                                            />
                                        }
                                        label="Only Unlisted Items"
                                        labelPlacement="top"
                                    />
                                </div>
                            </div>
                        )}
                        {searchSearch !== 'collection_sales' &&
                            searchSearch !== 'summaries' &&
                            searchSearch !== 'trades' &&
                            targetTab !== 'buyoffers' && (
                                <div
                                    className={{
                                        hidden: !userName || isPackSearch,
                                    }}
                                >
                                    <div
                                        className={cn(
                                            'flex justify-start mt-6',
                                        )}
                                    >
                                        <Switch
                                            className="font-semibold"
                                            isSelected={searchBacked}
                                            onValueChange={onCheckBacked}
                                        >
                                            Backed Tokens Only
                                        </Switch>
                                    </div>
                                </div>
                            )}
                        <div className={cn('block mt-8')}>
                            <Input2
                                name="ExactSearch"
                                type="hidden"
                                value={
                                    exactSearch === 'true' ||
                                    exactSearch === true
                                }
                                disabled={
                                    isPackSearch || isLoading ? 'disabled' : ''
                                }
                            />
                            <div className="flex justify-center px-2 font-bold">
                                <Button
                                    variant="bordered"
                                    color="warning"
                                    className="w-full font-semibold"
                                    radius="sm"
                                    fullWidth={false}
                                    onClick={() => {
                                        clearFilters()
                                        clearLocal()
                                        setToggleItem(initToggleItems())
                                    }}
                                >
                                    Clear
                                </Button>
                            </div>
                        </div>
                    </List>
                </div>
                {/*  <div
                    className={cn(
                        'text-base lg:text-sm xl:text-base rounded-b px-2',
                        {
                            'sticky inset-x-0 top-0 left-0 w-full md:w-1/10 md:h-filters-md lg:h-filters-lg xl:h-filters-xl overflow-y-auto InsideScroll':
                                !tradeSearch && !show,
                        },
                        {
                            'w-full h-auto hidden md:flex':
                                tradeSearch && !show,
                        },
                        { 'block md:hidden w-full': show },
                        { 'hidden md:block': !show && !tradeSearch },
                    )}
                >
                    <div className={'w-full h-auto'}>
                        <List dense>
                            <Tooltip
                                arrow
                                placement="right"
                                title="HShow filter options"
                            >
                                <ListItemButton onClick={toggleShow}>
                                    <ListItemText>
                                        <div className="font-bold">Filters</div>
                                    </ListItemText>
                                    <ListItemIcon>
                                        <FilterListIcon onClick={toggleShow}>
                                            <img
                                                src={'/filters.svg'}
                                                alt={'Show filter options'}
                                            />
                                        </FilterListIcon>
                                    </ListItemIcon>
                                </ListItemButton>
                            </Tooltip>
                            <Divider />
                        </List>
                    </div>
                </div> */}
            </Drawer>
        </>
    )
}

export default DrawerLeft
