import cn from 'classnames'

const BulkFooter = ({ children, addedAssets }) => {
    return (
        <div
            className={cn(
                'relative flex flex-wrap justify-evenly my-0',
                'rounded-tr-xl rounded-tl-xl h-auto z-50',
            )}
        >
            <div
                className={cn(
                    'w-full flex flex-wrap md:justify-between justify-center items-center',
                )}
            >
                {children}
            </div>
            {addedAssets}
        </div>
    )
}

export default BulkFooter
