import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import SearchIcon from '@mui/icons-material/Search'
import { Button, Input, Tooltip } from '@nextui-org/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { inputStyles, buttonSearchCommonStyles } from '../../common/styles'
import cn from 'classnames'

const InputSearch = ({
    applyTerm,
    handleTermChange,
    handleOwnerClear,
    disabled,
    value,
    userChangeEnabled,
    handleUserChange,
    showLabel = true,
}) => {
    const { t } = useTranslation('common')
    return (
        <div className="flex w-full">
            {userChangeEnabled ? (
                <Tooltip
                    showArrow={true}
                    key="right"
                    placement="right"
                    content="Filter to your assets"
                    color="foreground"
                    delay={0}
                    closeDelay={0}
                >
                    <Button
                        className={cn(
                            'h-[48px] w-[48px] border-2',
                            buttonSearchCommonStyles,
                        )}
                        isIconOnly
                        size="lg"
                        aria-label="Search"
                        disabled={!userChangeEnabled || disabled}
                        onClick={() => userChangeEnabled && handleUserChange()}
                    >
                        <AccountCircleIcon />
                    </Button>
                </Tooltip>
            ) : handleOwnerClear ? (
                <Tooltip
                    showArrow={true}
                    key="right"
                    placement="right"
                    content="Clear"
                    color="foreground"
                    delay={0}
                    closeDelay={0}
                >
                    <Button
                        className={cn(
                            'h-[48px] w-[48px] border-2',
                            buttonSearchCommonStyles,
                        )}
                        isIconOnly
                        size="lg"
                        aria-label="Search"
                        disabled={userChangeEnabled || disabled}
                        onClick={() => {
                            handleOwnerClear()
                            applyTerm('')
                        }}
                    >
                        <HighlightOffIcon />
                    </Button>
                </Tooltip>
            ) : (
                ''
            )}
            <div className="mx-2 w-full">
                <Input
                    fullWidth
                    size="sm"
                    classNames={{
                        ...inputStyles,
                    }}
                    variant="faded"
                    label={showLabel ? t('asset.owner') : ''}
                    onChange={handleTermChange}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            applyTerm(value)
                        }
                    }}
                    value={value}
                    disabled={disabled}
                />
            </div>
            <Tooltip
                showArrow={true}
                key="right"
                placement="right"
                content="Search for this Seller / Market"
                color="foreground"
                delay={0}
                closeDelay={0}
            >
                <Button
                    className={cn(
                        'h-[48px] w-[48px] border-2',
                        buttonSearchCommonStyles,
                    )}
                    isIconOnly
                    size="lg"
                    aria-label="Search"
                    disabled={disabled}
                    onClick={() => applyTerm(value)}
                >
                    <SearchIcon />
                </Button>
            </Tooltip>
        </div>
    )
}

export default InputSearch
