import cn from 'classnames'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useCraftingAuthors, usePFPAuthors } from '../../../hooks'
import { formatNumber, getDefaultAttrNames } from '../../helpers/FormatLinks'
import { SearchBar } from '../../searchbar/SearchBar'
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownSection,
    DropdownTrigger,
    Popover,
    PopoverContent,
    PopoverTrigger,
    ScrollShadow,
    Select,
    SelectItem,
} from '@nextui-org/react'
import { Button } from '@nextui-org/react'
import FilterListIcon from '@mui/icons-material/FilterList'
import { buttonSearchCommonStyles, selectStyles } from '../../common/styles'
const TopFilters = ({
    searchSearch,
    searchTerm,
    setOrder,
    orderBy,
    authorSchemas,
    applyTerm,
    setTerm,
    searchResults,
    isLoading,
    searchOffset,
    pagination = null,
    attrNames = getDefaultAttrNames(),
    children,
}) => {
    const { t } = useTranslation('common')

    const [tempTerm, setTempTerm] = useState(searchTerm)
    const [timer, setTimer] = useState(null)

    const handleTermChange = (e) => {
        setTempTerm(e.target.value)
        if (timer) clearTimeout(timer)
        setTimer(
            setTimeout(() => {
                setTerm(e.target.value)
            }, [500]),
        )
    }

    const totalResults =
        searchResults && searchResults.length > 0
            ? searchResults[0].total_results
            : 0
    const estimatedValue =
        searchResults && searchResults.length > 0
            ? searchResults[0].estimated_usd_value
            : 0
    const totalResultsUnknown =
        searchResults && searchResults.length > 0 && !totalResults

    let dropDownOptions = [
        { value: 'date_desc', label: 'Date (Newest)' },
        { value: 'date_asc', label: 'Date (Oldest)' },
        { value: 'offer_asc', label: 'Price (Lowest)' },
        { value: 'offer_desc', label: 'Price (Highest)' },
        { value: 'floor_asc', label: 'Floor (Lowest)' },
        { value: 'floor_desc', label: 'Floor (Highest)' },
        { value: 'author_asc', label: 'Collection (Ascending)' },
        { value: 'author_desc', label: 'Collection (Descending)' },
        { value: 'mint_asc', label: 'Mint (Lowest)' },
        { value: 'mint_desc', label: 'Mint (Highest)' },
        { value: 'asset_id_asc', label: 'Asset ID (Ascending)' },
        { value: 'asset_id_desc', label: 'Asset ID (Descending)' },
        { value: 'template_id_asc', label: 'Template ID (Ascending)' },
        { value: 'template_id_desc', label: 'Template ID (Descending)' },
        { value: 'rarity_score_desc', label: 'Rarity Score (Highest)' },
        { value: 'rarity_score_asc', label: 'Rarity Score  (Lowest)' },
    ]

    if (
        authorSchemas &&
        authorSchemas.categories &&
        authorSchemas.categories.some(
            (e) => Object.keys(e).includes('attr7') && e['attr7'],
        ) &&
        attrNames['attr7']
    ) {
        dropDownOptions.push({
            value: 'attr7_asc',
            label: `${attrNames['attr7']} (Ascending)`,
        })
        dropDownOptions.push({
            value: 'attr7_desc',
            label: `${attrNames['attr7']} (Descending)`,
        })
    }

    if (
        authorSchemas &&
        authorSchemas.categories &&
        authorSchemas.categories.some(
            (e) => Object.keys(e).includes('attr8') && e['attr8'],
        ) &&
        attrNames['attr8']
    ) {
        dropDownOptions.push({
            value: 'attr8_asc',
            label: `${attrNames['attr8']} (Ascending)`,
        })
        dropDownOptions.push({
            value: 'attr8_desc',
            label: `${attrNames['attr8']} (Descending)`,
        })
    }

    if (
        authorSchemas &&
        authorSchemas.categories &&
        authorSchemas.categories.some(
            (e) => Object.keys(e).includes('attr9') && e['attr9'],
        ) &&
        attrNames['attr9']
    ) {
        dropDownOptions.push({
            value: 'attr9_asc',
            label: `${attrNames['attr9']} (Ascending)`,
        })
        dropDownOptions.push({
            value: 'attr9_desc',
            label: `${attrNames['attr9']} (Descending)`,
        })
    }

    if (
        authorSchemas &&
        authorSchemas.categories &&
        authorSchemas.categories.some(
            (e) => Object.keys(e).includes('attr10') && e['attr10'],
        ) &&
        attrNames['attr10']
    ) {
        dropDownOptions.push({
            value: 'attr10_asc',
            label: `${attrNames['attr10']} (Ascending)`,
        })
        dropDownOptions.push({
            value: 'attr10_desc',
            label: `${attrNames['attr10']} (Descending)`,
        })
    }

    if (
        authorSchemas &&
        authorSchemas.categories &&
        authorSchemas.categories.some(
            (e) => Object.keys(e).includes('color') && e['color'],
        ) &&
        attrNames['color']
    ) {
        dropDownOptions.push({
            value: 'color_asc',
            label: `${attrNames['color']} (Ascending)`,
        })
        dropDownOptions.push({
            value: 'color_desc',
            label: `${attrNames['color']} (Descending)`,
        })
    }

    if (
        authorSchemas &&
        authorSchemas.categories &&
        authorSchemas.categories.some(
            (e) => Object.keys(e).includes('border') && e['border'],
        ) &&
        attrNames['border']
    ) {
        dropDownOptions.push({
            value: 'border_asc',
            label: `${attrNames['border']} (Ascending)`,
        })
        dropDownOptions.push({
            value: 'border_desc',
            label: `${attrNames['border']} (Descending)`,
        })
    }

    if (
        authorSchemas &&
        authorSchemas.categories &&
        authorSchemas.categories.some(
            (e) => Object.keys(e).includes('type') && e['type'],
        ) &&
        attrNames['type']
    ) {
        dropDownOptions.push({
            value: 'type_asc',
            label: `${attrNames['type']} (Ascending)`,
        })
        dropDownOptions.push({
            value: 'type_desc',
            label: `${attrNames['type']} (Descending)`,
        })
    }

    if (['summaries'].includes(searchSearch)) {
        dropDownOptions = [
            { value: 'volume_desc', label: 'Volume (Highest)' },
            { value: 'volume_asc', label: 'Volume (Lowest)' },
            { value: 'date_desc', label: 'Date (Newest)' },
            { value: 'date_asc', label: 'Date (Oldest)' },
        ]
    }

    if (['collection_sales'].includes(searchSearch)) {
        dropDownOptions = [
            { value: 'volume_desc', label: 'Volume (Highest)' },
            { value: 'volume_asc', label: 'Volume (Lowest)' },
            { value: 'average_asc', label: 'Median (Lowest)' },
            { value: 'average_desc', label: 'Median (Highest)' },
            { value: 'floor_asc', label: 'Floor (Lowest)' },
            { value: 'floor_desc', label: 'Floor (Highest)' },
        ]
    }

    if (
        [
            'buyoffers',
            'my_buyoffers',
            'buyoffers_owned',
            'best_offers',
        ].includes(searchSearch)
    ) {
        dropDownOptions = [
            { value: 'date_desc', label: 'Date (Newest)' },
            { value: 'date_asc', label: 'Date (Oldest)' },
            { value: 'offer_asc', label: 'Price (Lowest)' },
            { value: 'offer_desc', label: 'Price (Highest)' },
        ]
    }

    if ('bulk_multi_sell' === searchSearch) {
        dropDownOptions = [
            { value: 'owned_desc', label: 'Owned (Most)' },
            { value: 'owned_asc', label: 'Owned (Least)' },
            { value: 'date_desc', label: 'Date (Newest)' },
            { value: 'date_asc', label: 'Date (Oldest)' },
            { value: 'average_asc', label: 'Median (Lowest)' },
            { value: 'average_desc', label: 'Median (Highest)' },
            { value: 'floor_asc', label: 'Floor (Lowest)' },
            { value: 'floor_desc', label: 'Floor (Highest)' },
            { value: 'asset_id_asc', label: 'Asset ID (Ascending)' },
            { value: 'asset_id_desc', label: 'Asset ID (Descending)' },
            { value: 'template_id_asc', label: 'Template ID (Ascending)' },
            { value: 'template_id_desc', label: 'Template ID (Descending)' },
        ]
    }

    if ('auctions' === searchSearch) {
        dropDownOptions = [
            { value: 'end_asc', label: 'Ending (Soonest)' },
            { value: 'end_desc', label: 'Ending (Latest)' },
            { value: 'offer_asc', label: 'Price (Lowest)' },
            { value: 'offer_desc', label: 'Price (Highest)' },
            { value: 'date_desc', label: 'Date (Newest)' },
            { value: 'date_asc', label: 'Date (Oldest)' },
            { value: 'average_asc', label: 'Median (Lowest)' },
            { value: 'average_desc', label: 'Median (Highest)' },
            { value: 'floor_asc', label: 'Floor (Lowest)' },
            { value: 'floor_desc', label: 'Floor (Highest)' },
            { value: 'asset_id_asc', label: 'Asset ID (Ascending)' },
            { value: 'asset_id_desc', label: 'Asset ID (Descending)' },
            { value: 'template_id_asc', label: 'Template ID (Ascending)' },
            { value: 'template_id_desc', label: 'Template ID (Descending)' },
        ]
    }

    if ('templates' === searchSearch) {
        dropDownOptions = [
            { value: 'template_id_asc', label: 'Template ID (Ascending)' },
            { value: 'template_id_desc', label: 'Template ID (Descending)' },
            { value: 'date_desc', label: 'Date (Newest)' },
            { value: 'date_asc', label: 'Date (Oldest)' },
        ]
    }

    if ('pfps' === searchSearch) {
        dropDownOptions = [
            { value: 'rarity_score_desc', label: 'Rarity Score (Highest)' },
            { value: 'rarity_score_asc', label: 'Rarity Score  (Lowest)' },
            { value: 'date_desc', label: 'Date (Newest)' },
            { value: 'date_asc', label: 'Date (Oldest)' },
            { value: 'floor_asc', label: 'Floor (Lowest)' },
            { value: 'floor_desc', label: 'Floor (Highest)' },
            { value: 'asset_id_asc', label: 'Asset ID (Ascending)' },
            { value: 'asset_id_desc', label: 'Asset ID (Descending)' },
        ]
    }

    const getOption = (order) => {
        const option = Math.max(
            dropDownOptions.map((item) => item.value).indexOf(order),
            0,
        )

        return dropDownOptions[option]
    }

    const onSubmit = (e) => {
        e.preventDefault()
        applyTerm(tempTerm)
    }

    const sortInput = (
        <div className={'md:pl-2 pl-2 pr-0 md:pr-0 justify-end flex'}>
            <Dropdown
                offset={5}
                placement="bottom-end"
                classNames={{
                    base: 'h-[300px] overflow-y-scroll',
                    trigger: 'p-0 w-[200px]',
                }}
            >
                <DropdownTrigger className="w-[56px]">
                    <Button
                        size="sm"
                        className={cn(
                            '!w-[56px] h-[56px] border-2',
                            buttonSearchCommonStyles,
                        )}
                        variant="bordered"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5"
                            />
                        </svg>
                    </Button>
                </DropdownTrigger>
                <DropdownMenu
                    variant="faded"
                    aria-label="Dropdown menu with description"
                    selectionMode="single"
                    selectedKeys={new Set([getOption(orderBy).value])}
                    onSelectionChange={(d) => {
                        setOrder(getOption(Array.from(d)[0]).value)
                    }}
                >
                    {dropDownOptions.map((option) => (
                        <DropdownItem key={option.value} value={option.value}>
                            {option.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    )

    const resultOutput = (
        <div
            className={cn(
                'flex justify-center text-black dark:text-white text-base md:text-xs text-left',
                'xl:text-base my-auto w-full',
                { 'sm:col-span-2': !pagination },
            )}
        >
            <div
                className={
                    'my-auto text-sm flex flex-wrap sm:justify-end justify-start'
                }
            >
                {searchResults && searchResults.length > 0
                    ? totalResultsUnknown
                        ? t('search.showing_x_of_unknown_number_of_results', {
                              number: searchResults.length,
                          })
                        : t('search.showing_x_of_y_results', {
                              x: `${parseInt(searchOffset) + 1} - ${
                                  searchOffset + searchResults.length
                              }`,
                              y: totalResults,
                          })
                    : !isLoading && t('search.0_results')}
                {searchResults && estimatedValue ? (
                    <div className={'ml-1'}>{` (${t(
                        'profile.estimated_value',
                    )}: $${formatNumber(estimatedValue)})`}</div>
                ) : null}
            </div>
        </div>
    )

    return (
        <div className="grid justify-between flex-1 grid-cols-1 mx-3 mb-2 sm:grid-cols-2">
            <div className="flex justify-between w-full mt-2 sm:justify-start">
                <div>{children}</div>
                <div className="flex justify-center">
                    <SearchBar
                        placeHolder={'Name, ID'}
                        value={tempTerm ? tempTerm : ''}
                        onChange={handleTermChange}
                        onSubmit={onSubmit}
                        size="md"
                        onClickSearch={() => applyTerm(tempTerm)}
                    />
                </div>
                <div className="block sm:hidden">{sortInput}</div>
            </div>

            <div className="flex flex-row items-center justify-between flex-1 mt-2 sm:justify-end">
                <div className="flex items-center">
                    <p className="mt-4 sm:mt-0">
                        <b>{resultOutput}</b>
                    </p>
                    <div className="hidden sm:block">{sortInput}</div>
                </div>
                <div className="">{pagination}</div>
            </div>
        </div>
    )
}

export default React.memo(TopFilters)
