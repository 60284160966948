import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import AssetPreview from '../assetpreview/AssetPreview'
import { formatWAX } from '../helpers/FormatLinks'
import { getAtomicBalance, getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator'
import { useSharedState } from '../waxplorer/Waxplorer'
import { useTranslation } from 'react-i18next'
import { LinearProgress } from '@mui/material'
import MainButton from '../common/util/input/MainButton'

export const BuyOfferList = (props) => {
    const assets = props['assets']
    const listType = props['listType']
    const search = props['search']
    const clickAsset = props['clickAsset']
    const selectedAsset = props['selectedAsset']
    const id = props['id']
    const selectedAssets = props['selectedAssets']
    const bundleView = props['bundleView']
    const loadMoreButton = props['loadMoreButton']
    const isLoading = props['isLoading']
    const limit = props['limit']
    const page = props['page']
    const searchAuthor = props['searchAuthor']
    const selectable = props['selectable']
    const searchOwner = props['searchOwner']

    const [userBalance, setUserBalance] = useState(0)
    const [isLoadingBuyOfferBalance, setIsLoadingBuyOfferBalance] =
        useState(true)
    const [isWithdrawing, setIsWithdrawing] = useState(false)

    const [state, dispatch] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName

    const parseBuyOfferBalance = (res) => {
        if (res && res.status === 200) {
            let atomic = 0
            const data = res.data

            if (data && Object.keys(data).includes('rows'))
                data['rows'].map((row) => {
                    if (Object.keys(row).includes('quantities'))
                        row['quantities'].map((quantity) => {
                            if (quantity.includes(' WAX')) {
                                atomic += parseFloat(
                                    quantity.replace(' WAX', ''),
                                )
                            }
                        })
                })

            setUserBalance(atomic)
        }
        setIsLoadingBuyOfferBalance(false)
    }

    const withdrawBalace = async () => {
        setIsWithdrawing(true)
        await activeUser.session.transact(
            {
                actions: [
                    getBoostAction(activeUser),
                    {
                        account: 'atomicmarket',
                        name: 'withdraw',
                        authorization: [
                            {
                                actor: userName,
                                permission: activeUser.requestPermission
                                    ? activeUser.requestPermission
                                    : 'active',
                            },
                        ],
                        data: {
                            owner: userName,
                            token_to_withdraw: `${userBalance.toFixed(8)} WAX`,
                        },
                    },
                ],
            },
            {
                expireSeconds: 300,
                blocksBehind: 0,
            },
        )
        setIsWithdrawing(false)
    }

    useEffect(() => {
        if (
            (search === 'buyoffers' ||
                search === 'my_buyoffers' ||
                page === 'newbuyoffer') &&
            userName &&
            !isWithdrawing
        ) {
            setIsLoadingBuyOfferBalance(true)
            getAtomicBalance(userName, state).then(parseBuyOfferBalance)
        }
    }, [search, userName, isWithdrawing])

    useEffect(() => {}, [isLoading])

    const { t } = useTranslation('common')

    return (
        <div className={'w-full h-auto'}>
            <div className="flex justify-between py-4 mx-4 lg:py-8">
                <Link
                    href={
                        searchAuthor
                            ? `/newoffer?collection=${searchAuthor}`
                            : '/newoffer'
                    }
                >
                    <MainButton>+ Create Offer</MainButton>
                </Link>
                {userName ? (
                    <>
                        {!isLoadingBuyOfferBalance && !isWithdrawing
                            ? userBalance > 0 && (
                                  <div
                                      className={cn(
                                          'flex md:flex-row flex-col justify-end items-end gap-4 text-end',
                                      )}
                                  >
                                      <MainButton onClick={withdrawBalace}>
                                          {t('buyoffers.withdraw_now')} -{' '}
                                          {formatWAX(userBalance)}
                                      </MainButton>
                                  </div>
                              )
                            : ''}
                    </>
                ) : (
                    ''
                )}
            </div>
            {isWithdrawing ? (
                <div className={'w-full'}>
                    <LinearProgress />
                </div>
            ) : (
                ''
            )}
            <div
                className={cn(
                    'grid sm:gap-4 gap-2 grid-cols-1 xsse:grid-cols-2 sm1:grid-cols-3 md:grid-cols-3 md1:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-7 4xl:grid-cols-7 text-center sm:mx-4 mx-2',
                )}
                id={id ? id : ''}
            >
                {assets &&
                    assets
                        .filter(
                            (asset) =>
                                !selectedAssets ||
                                !selectedAssets.includes(
                                    asset.assetId.toString(),
                                ),
                        )
                        .map((asset, index) => (
                            <AssetPreview
                                bundleView={bundleView}
                                index={index}
                                assetType={listType}
                                key={`${
                                    asset.assetId || asset.aAssetId
                                }_${index}`}
                                asset={asset}
                                page={page}
                                selectable={selectable}
                                searchOwner={searchOwner}
                                clickAsset={clickAsset}
                                selectedAsset={selectedAsset}
                                state={state}
                                dispatch={dispatch}
                                selectedAssets={state.selectedAssets}
                                isSelected={
                                    state.selectedAssets &&
                                    state.selectedAssets
                                        .map(
                                            (a) =>
                                                (a.assets?.length > 0
                                                    ? a.assets[0]
                                                    : a
                                                ).assetId,
                                        )
                                        .includes(
                                            (asset.assets?.length > 0
                                                ? asset.assets[0]
                                                : asset
                                            ).assetId,
                                        )
                                        ? (asset.assets?.length > 0
                                              ? asset.assets[0]
                                              : asset
                                          ).assetId
                                        : null
                                }
                                errorAssets={state.errorAssets}
                                transferredAssets={state.transferredAssets}
                            />
                        ))}
            </div>
            <div className={'w-full flex justify-center'}>
                {isLoading ? <LoadingIndicator /> : null}
                {!isLoading &&
                assets &&
                assets.length > 0 &&
                assets.length % limit === 0 &&
                loadMoreButton
                    ? loadMoreButton
                    : ''}
            </div>
        </div>
    )
}
